<!-- 登录弹窗 -->
<template>
  <div class="loginMask">
    <div class="login-close" @click="toChat('mask')"></div>
    <div class="login-container">
      <div class="login-content">
        <p v-if="freeTips" class="tips">
          注册会员后，您将额外获得<span>免费对话5次</span>的体验赠送，并有机会进行新产品免费试用!
        </p>
        <!-- 账号密码登录 -->
        <el-form
          v-if="pwdShow"
          ref="loginFormRef"
          :model="loginForm"
          :rules="loginFormRules"
          class="login-form"
        >
          <div class="title-container">
            <h3 class="title"></h3>
          </div>
          <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              clearable
              placeholder="用户名/手机号"
              size="large"
            >
              <template #prepend>
                <el-icon>
                  <UserFilled/>
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              :type="passwordType"
              clearable
              placeholder="密码"
              size="large"
              @keyup.enter="submitForm"
            >
              <template #prepend>
                <el-icon v-if="passwordLock" @click="switchPass"
                >
                  <Lock
                  />
                </el-icon>
                <el-icon v-else @click="switchPass">
                  <Unlock/>
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              v-model="loginForm.code"
              maxlength="4"
              placeholder="验证码"
              size="large"
              @keyup="submitFormEnter"
            >
              <template #prepend>
                <el-icon>
                  <PictureFilled/>
                </el-icon>
              </template>
            </el-input>
            <canvas
              id="canvas"
              class="code_img"
              height="44px"
              width="96px"
              @click="handleCanvas"
            ></canvas>
          </el-form-item>
          <div class="login-btn">
            <el-button
              :loading="btnLoading"
              type="primary"
              @click="submitForm"
              @keyup="submitFormEnter"
            >
              登录
            </el-button>
          </div>
          <div class="psd-btn">
            <el-button link type="primary" @click="phoneClick">
              短信验证码登录
            </el-button>
            <el-button link type="primary" @click="register"> 注册</el-button>
            <el-button link type="primary" @click="forgetpsd">
              忘记密码
            </el-button>
          </div>
          <div :class="classNameShow ? 'classNameShow' : ''" class="privacyBox">
            <el-checkbox v-model="privacyCheck" @change="privacyCheckChange">
              <p>
                我已阅读并同意<span @click="agreement">《用户协议》 </span
              >与<span @click="privacy">《隐私协议》</span>相关内容
              </p>
            </el-checkbox>
          </div>
          <div class="guest_mode"><span @click="toChat">游客模式</span></div>
        </el-form>
        <!-- 手机号登录 -->
        <el-form
          v-if="phoneShow"
          ref="loginFormRef"
          :model="loginForm"
          :rules="loginFormRules"
          class="login-form"
        >
          <div class="title-container">
            <h3 class="title"></h3>
          </div>
          <el-form-item prop="phone">
            <el-input
              v-model="loginForm.phone"
              :maxlength="11"
              clearable
              placeholder="手机号"
              size="large"
            >
              <template #prepend>
                <el-icon>
                  <UserFilled/>
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              v-model="loginForm.code"
              maxlength="4"
              placeholder="验证码"
              size="large"
            >
              <template #prepend>
                <el-icon>
                  <PictureFilled/>
                </el-icon>
              </template>
            </el-input>
            <canvas
              id="canvas"
              class="code_img"
              height="44px"
              width="96px"
              @click="handleCanvas"
            ></canvas>
          </el-form-item>
          <el-form-item prop="yamcode">
            <el-input
              v-model="loginForm.yamcode"
              maxlength="6"
              placeholder="短信验证码"
              size="large"
              @keyup="submitFormEnterPhone"
            >
              <template #prepend>
                <el-icon>
                  <PictureFilled/>
                </el-icon>
              </template>
            </el-input>
            <el-button
              :disabled="!codeDisabled || yzmCodeLabel != '获取验证码'"
              class="code_img_yzn"
              type="primary"
              @click="getCode"
            >
              {{ yzmCodeLabel }}
            </el-button>
          </el-form-item>
          <div class="login-btn">
            <el-button
              :loading="btnLoading"
              type="primary"
              @click="submitFormPhone"
              @keyup="submitFormEnterPhone"
            >
              登录
            </el-button>
          </div>
          <div class="psd-btn">
            <el-button link type="primary" @click="pwdClick">
              账号密码登录
            </el-button>
            <el-button link type="primary" @click="register"> 注册</el-button>
            <el-button link type="primary" @click="forgetpsd">
              忘记密码
            </el-button>
          </div>
          <div :class="classNameShow ? 'classNameShow' : ''" class="privacyBox">
            <el-checkbox v-model="privacyCheck" @change="privacyCheckChange">
              <p>
                我已阅读并同意<span @click="agreement">《用户协议》 </span
              >与<span @click="privacy">《隐私协议》</span>相关内容
              </p>
            </el-checkbox>
          </div>
          <div class="guest_mode"><span @click="toChat">游客模式</span></div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import md5 from 'js-md5'
import {draw} from '@/utils/code'
import {getcumulativesignin, loginToken, sendloginmsg, syncchat, updateChatName, verifyloginmsg} from '@/api/common'
import {initTime, validatePhoneNumber} from '@/utils/validate'
import {computed, onMounted, reactive, ref, toRefs} from 'vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import router from '@/router'
import {useStore} from 'vuex'

const store = useStore()
const btnLoading = ref(false)
const passwordLock = ref(true)
const passwordType = ref('password')
const chatId = computed(() => store.state.chat.chatId)
const freeTips = computed(() => store.state.common.freeTips)
const loginFormRef = ref()
const initData = reactive({
  yzmCodeLabel: '获取验证码',
  loginForm: {
    username: '',
    password: '',
    code: '',
    phone: '',
    yamcode: ''
  },
  yanzheng_arr: [],
  true_code: '',
  pwdShow: true,
  phoneShow: false,
  codeFlag: false,
  privacyCheck: false,
  classNameShow: false
})
const {
  loginForm,
  yanzheng_arr,
  true_code,
  pwdShow,
  phoneShow,
  yzmCodeLabel,
  codeFlag,
  privacyCheck,
  classNameShow
} = toRefs(initData)
onMounted(() => {
  draw(yanzheng_arr.value, true_code)
})

let timer = null
const phoneClick = () => {
  phoneShow.value = true
  pwdShow.value = false
  loginForm.value = {
    username: '',
    password: '',
    code: ''
  }
  setTimeout(() => {
    handleCanvas()
  }, 1)
}
const routerJump = url => {
  // resolve新窗口打开
  const newpage = router.resolve({
    path: url // 跳转的页面路由
  })
  window.open(newpage.href, '_blank') // 打开新的窗口(跳转路径，跳转类型)
}
// 用户协议
const agreement = () => {
  routerJump('agreement')
}
// 隐私政策
const privacy = () => {
  routerJump('privacy')
}

const privacyCheckChange = val => {
  if (val) {
    classNameShow.value = false
  }
}

const pwdClick = () => {
  phoneShow.value = false
  pwdShow.value = true
  loginForm.value = {
    phone: '',
    yamcode: '',
    code: ''
  }
  setTimeout(() => {
    handleCanvas()
  }, 1)
}
// 图形验证码
const validateCode = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入图形验证码'))
  } else if (loginForm.value.code.toLowerCase() != true_code.value) {
    callback(new Error('请输入正确图形验证码'))
    setTimeout(() => {
      handleCanvas()
    }, 500)
  } else {
    callback()
  }
}
// 获取验证码按钮是否禁用
const codeDisabled = computed(() => {
  return (
    loginForm.value.phone != '' &&
    validatePhoneNumber(loginForm.value.phone) &&
    loginForm.value.code.toLowerCase() == true_code.value
  )
})

// 手机号验证
const validatePhone = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else if (!validatePhoneNumber(value)) {
    callback(new Error('请输入正确手机号'))
  } else {
    callback()
  }
}
const loginFormRules = reactive({
  username: [{required: true, message: '请输入用户名', trigger: 'blur'}],
  code: [{required: true, validator: validateCode, trigger: 'blur'}],
  password: [{required: true, message: '请输入密码', trigger: 'blur'}],
  phone: [{required: true, validator: validatePhone, trigger: 'blur'}],
  yamcode: [{required: true, message: '请输入短信验证码', trigger: 'blur'}]
})

// 游客模式
const toChat = val => {
  loginForm.value = {
    username: '',
    password: '',
    code: ''
  }
  store.commit('common/setFreeTips', false)
  store.commit('common/setLoginMask', false)
  if (val != 'mask') {
    store.dispatch('chat/getListData', 'login')
  }
  store.commit('common/setUserName', localStorage.getItem('uname') || '')
}

const register = () => {
  store.commit('common/setLoginMask', false)
  router.push('/register')
}

// 忘记密码
const forgetpsd = () => {
  store.commit('common/setLoginMask', false)
  router.push('/forgetPsd')
}

//canvas点击刷新
const handleCanvas = () => {
  loginForm.value.code = ''
  draw(yanzheng_arr.value, true_code)
}

// 切换查看密码
const switchPass = () => {
  if (passwordLock.value) {
    passwordType.value = 'text'
  } else {
    passwordType.value = 'password'
  }
  passwordLock.value = !passwordLock.value
}

// 获取短信验证码
const getCode = () => {
  if (codeFlag.value) {
    return false
  }
  countDown()
  codeFlag.value = true
  if (yzmCodeLabel.value == '获取验证码') {
    sendloginmsg({
      phone: loginForm.value.phone
    })
      .then(({data: res, status}) => {
        setTimeout(() => {
          codeFlag.value = false
        }, 1000)
        if (status == 200) {
          if (res.code == 0) {
            ElMessage.success(res.msg)
          } else {
            ElMessage.warning(res.msg)
          }
        }
        if (status == 202) {
          ElMessage.warning(res.msg)
          clearInterval(timer)
          yzmCodeLabel.value = '获取验证码'
          if (res.code == 1) {
            setTimeout(() => {
              router.push('/register')
              store.commit('common/setLoginMask', false)
            }, 1000)
          }
        }
      })
      .catch(() => {
        clearInterval(timer)
        yzmCodeLabel.value = '获取验证码'
      })
  }
}

// 验证码倒计时
const countDown = () => {
  let second = 60
  timer = setInterval(() => {
    second--
    if (second > 0) {
      yzmCodeLabel.value = `${second}s后重新发送`
    } else {
      clearInterval(timer)
      yzmCodeLabel.value = '获取验证码'
    }
  }, 1000)
}

// 按下回车发送聊天
const submitFormEnter = e => {
  if (e.keyCode == 13) {
    submitForm()
  }
}

const signTotal = () => {
  getcumulativesignin().then(response => {
    const {data: res, status} = response
    if (status == 200) {
      if (res.code == 0) {
        store.commit('common/setSignTotal', res.data.total)
        store.commit('common/setSignTokens', res.data.tokens)
        const date =
          initTime() != res.data.signInDate[res.data.signInDate.length - 1]
        if (res.data.total == 7) {
          store.commit('common/setSignMask', false)
          return false
        }
        if (date && !sessionStorage.getItem('sign')) {
          store.commit('common/setSignMask', true)
        }
      }
    }
  })
}

// 登录
const submitForm = () => {
  loginFormRef.value.validate(valid => {
    if (valid) {
      if (!privacyCheck.value) {
        classNameShow.value = true
        ElMessage.warning('您尚未同意用户协议与隐私协议的内容')
        return false
      }
      btnLoading.value = true
      loginToken({
        userName: loginForm.value.username,
        pwd: md5(loginForm.value.password)
      })
        .then(({data: res, status}) => {
          if (res.code == 0 || res.code == 5) {
            if (res.data) {
              ElMessage.success('欢迎回来！好久不见，有点想你了呢~')
              localStorage.setItem('uname', res.data.userName)
              localStorage.setItem('token', res.data.accessToken)
              store.commit('common/setDiamond', res.data.diamond)
              localStorage.setItem('diamond', JSON.stringify(res.data.diamond))
              store.commit('common/setRemainingPaper', res.data.remainingPaper)
              signTotal()
              if (res.data.orderData) {
                localStorage.setItem(
                  'useToken',
                  JSON.stringify(res.data.orderData)
                )
                store.commit('common/setUseToken', res.data.orderData)
              }
              if (
                res.data.diamond.includes('blue') ||
                res.data.diamond.includes('orange')
              ) {
                store.commit(
                  'common/setPlaceholder',
                  '请直接在此输入对话内容。您也可以点击左侧开启新聊天，通过我们为您提供的提示词分类辅助进行对话'
                )
              } else {
                if (res.data.diamond.indexOf('purple') == -1) {
                  store.commit(
                    'common/setPlaceholder',
                    `会员 | 剩余提问次数：${
                      res.data.remainingQuestioningTimes || 0
                    }`
                  )
                }
              }
              if (res.data.diamond.indexOf('purple') != -1) {
                if (res.data.remainingPaper) {
                  if (res.data.remainingPaper == '无限') {
                    store.commit(
                      'common/setDrawPlaceholder',
                      `请直接在此描绘你心中画面的样子，尽量详细，可以使用一些风格修饰词。您也可以点击“使用提示词”分类铺助进行绘制。`
                    )
                  } else {
                    store.commit(
                      'common/setDrawPlaceholder',
                      `会员 | 剩余绘图张数：${res.data.remainingPaper || 0}`
                    )
                  }
                } else {
                  store.commit(
                    'common/setDrawPlaceholder',
                    `请直接在此描绘你心中画面的样子，尽量详细，可以使用一些风格修饰词。您也可以点击“使用提示词”分类铺助进行绘制。`
                  )
                }
              } else {
                store.commit(
                  'common/setDrawPlaceholder',
                  `会员 | 剩余绘图张数：${res.data.remainingPaper || 0}`
                )
              }
              store.commit(
                'common/setRemainingPaper',
                res.data.remainingPaper || 0
              )
              toChat()
              if (res.code == 5) {
                setTimeout(() => {
                  MessageBoxTips(
                    res.data.needSyncChatName,
                    res.data.needSyncChatType
                  )
                }, 500)
              }
            } else {
              ElMessage.warning(res.msg)
            }
          }
          if (status == 202) {
            handleCanvas()
          }
          btnLoading.value = false
        })
        .catch(() => {
          btnLoading.value = false
        })
    }
  })
}

// 手机号登录按下回车发送聊天
const submitFormEnterPhone = e => {
  if (e.keyCode == 13) {
    submitFormPhone()
  }
}

// 手机号登录
const submitFormPhone = () => {
  loginFormRef.value.validate(valid => {
    if (valid) {
      if (!privacyCheck.value) {
        classNameShow.value = true
        ElMessage.warning('您尚未同意用户协议与隐私协议的内容')
        return false
      }
      btnLoading.value = true
      verifyloginmsg({
        phone: loginForm.value.phone,
        code: loginForm.value.yamcode
      })
        .then(({data: res, status}) => {
          btnLoading.value = false
          if (status == 200) {
            if (res.code == 0 || res.code == 5) {
              if (res.data) {
                ElMessage.success('欢迎回来！好久不见，有点想你了呢~')
                localStorage.setItem('uname', res.data.userName)
                localStorage.setItem('token', res.data.accessToken)
                store.commit('common/setDiamond', res.data.diamond)
                localStorage.setItem(
                  'diamond',
                  JSON.stringify(res.data.diamond)
                )
                store.commit(
                  'common/setRemainingPaper',
                  res.data.remainingPaper
                )
                signTotal()
                if (res.data.orderData) {
                  localStorage.setItem(
                    'useToken',
                    JSON.stringify(res.data.orderData)
                  )
                  store.commit('common/setUseToken', res.data.orderData)
                }
                if (
                  !res.data.diamond.includes('blue') &&
                  !res.data.diamond.includes('orange')
                ) {
                  store.commit(
                    'common/setPlaceholder',
                    `会员 | 剩余提问次数：${res.data.remainingQuestioningTimes}`
                  )
                } else {
                  store.commit(
                    'common/setPlaceholder',
                    '请直接在此输入对话内容。您也可以点击左侧开启新聊天，通过我们为您提供的提示词分类辅助进行对话'
                  )
                }

                if (res.data.diamond.indexOf('purple') != -1) {
                  if (res.data.remainingPaper) {
                    if (res.data.remainingPaper == '无限') {
                      store.commit(
                        'common/setDrawPlaceholder',
                        `请直接在此描绘你心中画面的样子，尽量详细，可以使用一些风格修饰词。您也可以点击“使用提示词”分类铺助进行绘制。`
                      )
                    } else {
                      store.commit(
                        'common/setDrawPlaceholder',
                        `会员 | 剩余绘图张数：${res.data.remainingPaper || 0}`
                      )
                    }
                  } else {
                    store.commit(
                      'common/setDrawPlaceholder',
                      `请直接在此描绘你心中画面的样子，尽量详细，可以使用一些风格修饰词。您也可以点击“使用提示词”分类铺助进行绘制。`
                    )
                  }
                } else {
                  store.commit(
                    'common/setDrawPlaceholder',
                    `会员 | 剩余绘图张数：${res.data.remainingPaper || 0}`
                  )
                }
                store.commit(
                  'common/setRemainingPaper',
                  res.data.remainingPaper || 0
                )
                toChat()
                if (res.code == 5) {
                  setTimeout(() => {
                    MessageBoxTips(
                      res.data.needSyncChatName,
                      res.data.needSyncChatType
                    )
                  }, 500)
                }
              } else {
                ElMessage.warning(res.msg)
              }
            }
          }
          if (status == 202) {
            if (res.code == 2) {
              ElMessage.warning(res.msg)
              localStorage.setItem(
                'forgetpsd',
                JSON.stringify({
                  step: 1,
                  id: res.data.userId
                })
              )
              setTimeout(() => {
                router.push('/register')
                store.commit('common/setLoginMask', false)
              }, 1000)
            } else {
              ElMessage.warning(res.msg)
            }
          }
        })
        .catch(() => {
          btnLoading.value = false
        })
    }
  })
}

const MessageBoxTips = (name, type) => {
  ElMessageBox.confirm(`是否同步游客身份产生的新内容？`, '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      getUpdateChatName(name, type)
    })
    .catch(() => {
    })
}

const getUpdateChatName = (name, type) => {
  const datas = {
    chatName: name,
    type: type,
    chatId: chatId.value + 1
  }
  updateChatName(datas)
    .then(async response => {
      const {data: res, status} = response
      if (status == 200) {
        if (res.code == 0) {
          syncchat(datas)
            .then(async response => {
              const {data: res, status} = response
              if (status == 200) {
                if (res.code == 0) {
                  store.dispatch('chat/getListData', 0)
                }
              }
            })
            .catch(() => {
            })
        }
      }
    })
    .catch(() => {
    })
}
</script>
<style lang="scss">
$bg: #212123;
$dark_gray: #889aa4;
$light_gray: #eee;
$cursor: #fff;
.loginMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  .login-close {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .login-container {
    .el-input {
      display: inline-block;
      height: 47px;
      width: 100%;

      .el-input__inner {
        color: #fff !important;
      }

      .el-input__wrapper {
        width: 85%;
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: $light_gray;
        height: 100%;
        caret-color: $cursor;
        box-shadow: none;

        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px $bg inset !important;
          -webkit-text-fill-color: $cursor !important;
        }
      }

      .el-input__inner {
        &:-webkit-autofill {
          -webkit-text-fill-color: #ededed !important;
          -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
          background-color: transparent;
          background-image: none;
          transition: background-color 900000000s ease-in-out 0s;
        }
      }

      .el-input-group__prepend {
        color: #fff;
        background: transparent;
        box-shadow: none;
        cursor: pointer;
      }
    }

    .el-form-item__error {
      margin-top: 5px;
    }

    .el-form-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: #454545;
      margin-bottom: 25px;
    }

    .login-btn {
      .el-button {
        width: 100%;
        height: 47px;
        background: #ff7900;
        border-color: #ff7900;
      }
    }

    .guest_mode {
      text-align: center;
      color: #999;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 1px;
      margin-top: 30px;

      span {
        cursor: pointer;
      }
    }

    .code_img {
      width: 100px;
      height: 40px;
      position: absolute;
      right: 0;
      top: 1px;
    }

    .code_img_yzn {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: #ff7900;
      border-color: #ff7900;
    }
  }

  .login-container {
    position: absolute;
    z-index: 10;

    .login-content {
      background-color: $bg;
      width: 520px;
      overflow: hidden;
      padding: 30px 20px 20px;
      border-radius: 5px;
      position: relative;

      .tips {
        width: 78%;
        font-size: 16px;
        margin: 20px auto;
        margin-top: 0;

        span {
          color: #ff7a04;
        }
      }
    }

    .login-form {
      width: 100%;

      .psd-btn {
        position: absolute;
        bottom: 50px;
        left: 20px;
        right: 20px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.privacyBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  cursor: pointer;

  p {
    font-size: 14px;
    color: #409eff;
    margin-left: 5px;

    span {
      &:hover {
        color: #ff7900;
      }
    }
  }
}

.classNameShow {
  .el-checkbox__inner {
    border-color: red !important;
  }
}
</style>
