<template>
  <div class="success">
    <img src="../assets/images/successimg.png" alt="" />
    <p class="tips">您已注册成功，请在pc端登录以下网址<br />开启简单方便的AI体验之旅</p>
    <p class="url">{{ url }}</p>
  </div>
</template>

<script setup>
import { reactive, toRefs, onMounted } from 'vue'
const datas = reactive({
  url: []
})

onMounted(() => {
  const href = window.location.href.split('#')[0]
  let script = document.createElement('script')
  script.src = `${href}/static/js/flexible.js`
  document.body.appendChild(script)
  url.value = window.location.href.split('#')[0]
})

const { url } = toRefs(datas)
</script>
<style lang="scss" scoped>
.success {
  overflow: hidden;
  img {
    width: 134px;
    height: 129px;
    margin: 90px auto 32px;
    display: block;
  }
  .tips {
    color: #333333;
    font-size: .4rem;
    line-height: .64rem;
    text-align: center;
  }
  .url {
    font-size: .4rem;
    color: #A5A5A5;
    text-align: center;
    margin-top: .24rem;
  }
}
</style>
