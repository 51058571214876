<!-- 提示词轮播图 -->
<template>
  <swiper-container
    ref="swiperContainerRef"
    :grabCursor="true"
    :pagination="{show: true}"
    :space-between="24"
    class="swiper_container"
    navigation="true"
  >
    <swiper-slide
      v-for="(swiper, index) in swiperListsData"
      :key="index"
    >
      <div v-for="(item, i) in swiper" :key="i" class="swiper_slide">
        <div class="top">
          <div class="title">
          <span>{{ item.title }}
            <img
              :src="item.isFavorites === 1 ? require('../../assets/images/collectactive.svg') : require('../../assets/images/collect.svg')"
              @click="collectActive(item)"/>
          </span>
          </div>
          <div class="copy" @click="copyText(item)">复制</div>
        </div>
        <div class="desc">
          {{ item.description }}
        </div>
        <div v-if="!item.enShow" class="question" @click="changeEn(item)">
          {{ item.prompt }}
        </div>
        <div v-else class="question" @click="changeEn(item)">
          {{ item.prompt_en }}
        </div>
        <div class="labels">
          <swiper-container :slides-per-view="3" freeMode>
            <swiper-slide v-for="(el, i) in item.labels" :key="i" class="el">
              {{ el.name }}<i :style="{ backgroundColor: el.color }"></i>
            </swiper-slide>
          </swiper-container>
        </div>
        <div class="btn" @click="startTalk(item)">开始会话</div>
      </div>
    </swiper-slide>
  </swiper-container>
</template>

<script setup>
import useClipboard from 'vue-clipboard3'
import {register} from 'swiper/swiper-element-bundle'
import {addpromptsfavorites, droppromptsfavorites} from '@/api/expert'
import {ElMessage} from 'element-plus'
import {computed, nextTick, onMounted, ref, watch} from 'vue'
import {useStore} from 'vuex'
import {clearStorage} from '@/utils/common'

const {toClipboard} = useClipboard()
register()

const store = useStore()
const swiperListsData = computed(() => store.getters['swiper/swiperListsData'])
const swiperContainerRef = ref(null)
onMounted(() => {
  initializeSwiper()
})

const initializeSwiper = () => {
  const swiperContainer = swiperContainerRef.value
  const w = (window.innerWidth - 1108) / 2
  swiperContainer.swiper.navigation.nextEl.style.right = w + 15 + 'px'
  swiperContainer.swiper.navigation.prevEl.style.left = w + 15 + 'px'
  swiperContainer.swiper.navigation.nextEl.children[0].style.width = '50%'
  swiperContainer.swiper.navigation.nextEl.children[0].style.height = '50%'
  swiperContainer.swiper.navigation.prevEl.children[0].style.width = '50%'
  swiperContainer.swiper.navigation.prevEl.children[0].style.height = '50%'
  swiperContainer.swiper.navigation.prevEl.style.display = 'none'

  const nextArrow = swiperContainer.swiper.navigation.nextEl
  const prevArrow = swiperContainer.swiper.navigation.prevEl
  nextArrow.addEventListener('click', onNextArrowClick)
  prevArrow.addEventListener('click', onPrevArrowClick)

  swiperContainer.addEventListener('slidechange', event => {
    if (swiperContainerRef.value.swiper.activeIndex === 0) {
      swiperContainerRef.value.swiper.navigation.prevEl.style.display = 'none'
      swiperContainerRef.value.swiper.navigation.nextEl.style.display = 'flex'
    }
    if (swiperContainerRef.value.swiper.activeIndex === swiperListsData.value.length - 1) {
      swiperContainerRef.value.swiper.navigation.nextEl.style.display = 'none'
      swiperContainerRef.value.swiper.navigation.prevEl.style.display = 'flex'
    }else if (swiperContainerRef.value.swiper.activeIndex > 0) {
      swiperContainerRef.value.swiper.navigation.nextEl.style.display = 'flex'
      swiperContainerRef.value.swiper.navigation.prevEl.style.display = 'flex'
    }
  })
}
// 下一个
const onNextArrowClick = () => {
  if (swiperContainerRef.value.swiper.activeIndex === swiperListsData.value.length - 1) {
    swiperContainerRef.value.swiper.navigation.nextEl.style.display = 'none'
    swiperContainerRef.value.swiper.navigation.prevEl.style.display = 'flex'
  } else {
    swiperContainerRef.value.swiper.navigation.nextEl.style.display = 'flex'
    swiperContainerRef.value.swiper.navigation.prevEl.style.display = 'flex'
  }
}
// 上一个
const onPrevArrowClick = () => {
  if (swiperContainerRef.value.swiper.activeIndex === 0) {
    swiperContainerRef.value.swiper.navigation.prevEl.style.display = 'none'
    swiperContainerRef.value.swiper.navigation.nextEl.style.display = 'flex'
  }else {
    swiperContainerRef.value.swiper.navigation.prevEl.style.display = 'flex'
    swiperContainerRef.value.swiper.navigation.nextEl.style.display = 'flex'
  }
}
// swiperListsData
// swiperBrs
watch(swiperListsData, newv => {
  nextTick(() => {
    if (swiperContainerRef.value.swiper.activeIndex === 0) {
      swiperContainerRef.value.swiper.navigation.prevEl.style.display = 'none'
      swiperContainerRef.value.swiper.navigation.nextEl.style.display = 'flex'
    }
    if (swiperContainerRef.value.swiper.activeIndex === swiperListsData.value.length - 1) {
      swiperContainerRef.value.swiper.navigation.nextEl.style.display = 'none'
      swiperContainerRef.value.swiper.navigation.prevEl.style.display = 'flex'
    }else if (swiperContainerRef.value.swiper.activeIndex > 0) {
      swiperContainerRef.value.swiper.navigation.nextEl.style.display = 'flex'
      swiperContainerRef.value.swiper.navigation.prevEl.style.display = 'flex'
    }
    swiperContainerRef.value.swiper.update()
  })
})
// eslint-disable-next-line no-undef
const emit = defineEmits(['startTalk'])

// 开始会话
const startTalk = item => {
  emit('startTalk', item)
}

// 是否收藏
const collectActive = item => {
  if (item.isFavorites === 1) {
    droppromptsfavorites({
      id: item.id
    }).then(response => {
      const {data: res, status} = response
      if (status === 200) {
        if (res.code === 0) {
          item.isFavorites = 0
          store.dispatch('swiper/getSwiperLists')
          ElMessage.success(res.msg)
        }
      }
      if (status === 202) {
        if (res.code === 1) {
          ElMessage.warning(res.msg)
          init()
        } else {
          ElMessage.warning(res.msg)
        }
      }
    })
  } else {
    addpromptsfavorites({
      id: item.id
    }).then(response => {
      const {data: res, status} = response
      if (status === 200) {
        if (res.code === 0) {
          item.isFavorites = 1
          store.dispatch('swiper/getSwiperLists')
          ElMessage.success(res.msg)
        }
      }
      if (status === 202) {
        if (res.code === 1) {
          ElMessage.warning(res.msg)
          init()
        } else {
          ElMessage.warning(res.msg)
        }
      }
    })
  }
}

const init = () => {
  clearStorage()
  store.commit('common/setUserName', '')
  setTimeout(() => {
    store.commit('common/setExpertMask', false)
    store.commit('common/setLoginMask', true)
  }, 3000)
}

// 中英文切换
const changeEn = item => {
  item.enShow = !item.enShow
}

// 复制
const copyText = async item => {
  let val = ''
  if (!item.enShow) {
    val = item.prompt
  } else {
    val = item.prompt_en
  }
  try {
    await toClipboard(val)
    ElMessage.success('复制成功')
  } catch (e) {
    console.error('失败')
  }
}
</script>
<style lang="scss" scoped>
.swiper_container {
  width: 100%;
  height: 100%;

  .swiper_slide {
    display: inline-block;
    background: #333;
    width: 317px !important;
    height: 469px;
    background: url(../../assets/images/tipbg.svg);
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    margin-right: 24px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      z-index: 11;

      .title {
        display: flex;
        align-items: center;
        position: relative;

        span {
          width: 130px;
          font-size: 20px;
          color: #fff;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }

        &:hover {
          span {
            width: 220px;
            position: absolute;
            top: 8px;
            z-index: 11;
            overflow: auto;
            white-space: pre-wrap;
            margin-top: -8px;
          }
        }
      }

      .copy {
        width: 54px;
        height: 28px;
        background: rgba(255, 226, 201, 0.3);
        border-radius: 2px;
        text-align: center;
        line-height: 28px;
        color: #fa7905;
        font-size: 13px;
        cursor: pointer;
      }
    }

    .desc {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);
      line-height: 22px;
      // margin-top: 18px;
      height: 44px;
      overflow: hidden;
      position: absolute;
      z-index: 10;
      top: 66px;
      left: 20px;
      right: 20px;
      padding: 5px;

      &:hover {
        height: auto;
        max-height: 340px;
        background: url(../../assets/images/descimg.jpg) no-repeat top center;
        background-size: 100% auto;
        overflow-y: auto;
        // border-radius: 5px;
      }
    }

    .question {
      padding: 15px;
      background: rgba(237, 238, 244, 0.18);
      font-size: 12px;
      line-height: 24px;
      // margin: 27px 0 10px;
      margin: 89px 0 10px;
      color: #ffefe7;
      height: 213px;
      overflow-y: auto;
      cursor: pointer;
      border-radius: 6px;
    }

    .labels {
      cursor: grab;

      .el {
        width: auto !important;
        padding: 0 14px;
        background: transparent;
        border: 1px solid #ff7900;
        font-size: 10px;
        color: #ff7900;
        display: inline-block;
        margin-right: 10px;
        border-radius: 14px;
        height: 28px;
        line-height: 28px;

        i {
          display: inline-block;
          width: 10px;
          height: 10px;
          background: #ccc;
          border-radius: 100%;
          margin-left: 5px;
        }
      }
    }

    .btn {
      width: 272px;
      height: 39px;
      background: #f27809;
      border-radius: 2px;
      line-height: 39px;
      text-align: center;
      font-size: 13px;
      color: #fff;
      margin-top: 18px;
      cursor: pointer;
    }
  }
}

swiper-container::part(bullet) {
  background-color: #d9d9d9;
  border: 1px solid #454545;
}

swiper-container::part(bullet-active) {
  background-color: #ff7a04;
  border: 1px solid #ff7a04;
}

swiper-container::part(button-next),
swiper-container::part(button-prev) {
  color: #ff7a04;
  position: fixed;
  top: 57%;
  background: #fff;
  border-radius: 5px;
  opacity: 0.7;
  box-shadow: 0 0 10px #ccc;
  width: 40px;
  height: 40px;
}
</style>
