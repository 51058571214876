import {ElMessage} from "element-plus";
import store from '@/store'
import {clearStorage} from '@/utils/common'
import {getCanvasTouch} from "@/utils/canvasTouch";
import {nextTick} from "vue";

const baseUrl = process.env.VUE_APP_API

export const getChatSSE = (trigger_id, message) => {
  let token = localStorage.getItem('webTouch') + '+' + getCanvasTouch()
  if (localStorage.getItem('token')) {
    token = localStorage.getItem('token')
  }
  let chatList = store.state.chat.chatList
  let chatKey = store.state.chat.chatKey
  let chatListCurrent = store.state.chat.chatListCurrent
  let sseState = store.state.common.sseState
  let msg = ''
  const eventSource = new EventSource(`${baseUrl}/requestdata/getdata/${store.state.chat.chatId}/${trigger_id}?token=${token}`, {withCredentials: true})
  eventSource.onmessage = event => {
    const data = JSON.parse(event.data)
    if (!sseState) {
      return false
    }
    if (data.type === 'Validation failed') {
      ElMessage.warning('登录过期，请重新登录')
      clearStorage()
      setTimeout(() => {
        store.commit('common/setLoginMask', true)
      }, 2000)
      return false
    }
    if (data.type === 'error') {
      store.commit('common/setSseError', false)
      localStorage.setItem('chatValue', message)
      return false
    }
    if (JSON.stringify(chatList) !== '{}') {
      if (data.type === 'generating') {
        localStorage.removeItem('chatValue')
        msg += data.contect
      }
      if (data.type === 'close') {
        chatList[chatKey][chatListCurrent].list.push({
          content: msg,
          role: 'A'
        })
        nextTick(() => {
          sureSendWay(msg, chatList[chatKey][chatListCurrent].list.length - 1)
        })
        store.commit('common/setSureSendWayState', true)
        setTimeout(()=>{
          store.commit('common/setSureSendWayState', false)
        },1000)
        eventSource.close()
        // store.dispatch('chat/getListData')
        store.commit('chat/setChatValue', '')
        store.commit('common/setLoading', false)
        store.commit('common/setTimeoOut', false)
      }
    } else {
      eventSource.close()
      store.dispatch('chat/getListData')
      store.commit('chat/setChatValue', '')
      store.commit('common/setLoading', false)
      store.commit('common/setTimeoOut', false)
    }
  }
  eventSource.onerror = error => {
    console.error(error)
    eventSource.close()
    store.commit('common/setLoading', false)
    store.commit('common/setDrawTrue', false)
    store.commit('common/setSseState', false)
    // store.dispatch('chat/getListData')
  }
}


let timer = null
// 开始
const sureSendWay = (str, index) => {
  // showText.value = false
  // ask.value = keyword.value
  let divTyping = document.getElementById(`content${index}`)
  let i = 0
  // 插入回答数据的打字效果
  function typing() {
    if (i <= str.length) {
      divTyping.innerHTML = '<span>' + str.slice(0, i++) + '</span>'
      timer = setTimeout(() => {
        typing()
      }, 50)
      // main.value.scrollTop = main.value.scrollHeight
      document.getElementById('main').scrollTop = document.getElementById('main').scrollHeight
      if (i > str.length) {
        clearTimeout(timer)
        timer = null
        store.commit('common/setSureSendWayState', false)
      }
    }
  }

  typing()
}
