<!-- 联系客服 -->
<template>
  <div class="service" @click="hideAboutMask">
    <div class="serviceMain" @click.stop>
      <img
        src="../../assets/images/serviceclose.png"
        class="close"
        @click="hideAboutMask"
      />
      <h6>关于我们</h6>
      <div class="desc">
        北京智言物语科技有限公司专业从事基于生成式语言模型的自主研发，为用户提供国内AI智能聊天产品聚合、AI生成式图像的聚合服务。目前公司基于自研神算子GPT产品已经具有初步智能聊天功能，我们将持续用户提供免费服务以及用户增值服务，价格低廉，欢迎您进行产品试用及咨询。
      </div>
      <div class="unit">北京智言物语科技有限公司 客服部</div>
      <div class="info">
        <div class="item">
          <img src="../../assets/images/servicephone.png" />
          <span>18513810151</span>
        </div>
        <!-- <div class="item">
          <img src="../../assets/images/serviceemail.png" />
          <span>service@bpae.com</span>
        </div> -->
        <div class="item">
          <img src="../../assets/images/servicewx.png" />
          <img src="../../assets/images/servicecode.png" class="code" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
const store = useStore()
const hideAboutMask = () => {
  store.commit('common/setAboutMask', false)
}
</script>
<style lang="scss" scoped>
.service {
  position: fixed;
  top: 0;
  left: $width;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background: transparent;
  .serviceMain {
    width: 850px;
    height: 492px;
    border-radius: 10px;
    background: url(../../assets/images/servicebg.png) center center no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 25px;
    padding-left: 72px;
    padding-top: 55px;
    margin: auto;
    position: absolute;
    top: 0;
    left: calc((-#{$width} - 60px) / 2);
    right: 0;
    bottom: 0;
    z-index: 9999999;
    .close {
      position: absolute;
      top: 55px;
      right: 55px;
      cursor: pointer;
    }
    h6 {
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
      line-height: 33px;
      margin-bottom: 20px;
    }
    .desc {
      width: 717px;
      font-size: 16px;
      font-weight: 400;
      color: #ffefe7;
      line-height: 26px;
      margin-bottom: 40px;
    }
    .unit {
      font-size: 16px;
      font-weight: 400;
      color: #ffefe7;
      line-height: 19px;
      margin-bottom: 17px;
    }
    .info {
      .item {
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        span {
          font-size: 16px;
          font-weight: 400;
          color: #ffefe7;
          line-height: 19px;
        }
        .code {
          width: 106px;
          height: 106px;
        }
        &:last-child {
          align-items: flex-start;
        }
      }
    }
  }
}
</style>
