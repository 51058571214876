<!-- 提示弹窗中分类标签 -->
<template>
  <div class="label_main" @mouseenter="expand" @mouseleave="collapse">
    <div
      class="box"
      :class="isExpanded ? 'boxHeight' : ''"
      :style="contentStyle"
      :key="isExpanded"
    >
      <div
        class="label_item"
        @click="changeItem(item)"
        v-for="(item, i) in labelList"
        :key="i"
        :class="item.active ? 'active' : ''"
      >
        {{ item.categoryName }}
        <i :style="{ backgroundColor: item.color }"></i>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const isExpanded = ref(false)
const labelList = computed(() => store.state.label.labelList)
const labelSwitchval = computed(() => store.state.label.labelSwitchval)
// 点击标签
const changeItem = item => {
  if (labelSwitchval.value) {
    labelList.value.forEach(item1 => {
      item1.active = false
    })
    item.active = true
    store.commit('label/setLabelId', item.id)
  } else {
    item.active = !item.active
    const list = labelList.value
      .filter(item => item.active)
      .map(item => item.id)
    if (list.length == 0) {
      labelList.value.forEach(item => {
        if (item.id == 36) {
          item.active = true
        }
      })
    }
    store.commit('label/setLabelId', list.length > 0 ? list.join() : 36)
  }
  store.dispatch('swiper/getSwiperLists')
}

const contentStyle = computed(() => {
  return {
    height: isExpanded.value ? 'auto' : '30px'
  }
})

watch(labelSwitchval, newv => {
  if (newv) {
    labelList.value.forEach(item => {
      item.active = false
      if (item.id == -2) {
        item.active = true
      }
    })
    store.commit('label/setLabelId', -2)
    store.dispatch('swiper/getSwiperLists')
  }
})

const expand = () => {
  isExpanded.value = true
}

const collapse = () => {
  isExpanded.value = false
}
</script>
<style lang="scss" scoped>
.label_main {
  width: 100%;
  .box {
    height: 30px;
    overflow: hidden;
    padding: 16px 14px;
    padding-bottom: 0;
    box-sizing: content-box;
  }
  .boxHeight {
    background: #454253;
    border-radius: 5px;
    box-shadow: 0px 0px 20px 5px rgba(14, 38, 100, 0.22);
  }
  .label_item {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0 16px;
    border: 1px solid #ff7900;
    font-size: 14px;
    color: #ff7900;
    margin-right: 14px;
    cursor: pointer;
    border-radius: 3px;
    margin-bottom: 14px;
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #ccc;
      border-radius: 100%;
      margin-left: 5px;
    }
  }
  .active {
    background: #ff7900;
    color: #fff;
  }
}
</style>
