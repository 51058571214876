<template>
  <div class="sign">
    <div class="signMask" @click.stop>
      <img
        src="../../assets/images/signclose.png"
        class="signclose"
        @click="closeSign"
      />
      <img src="../../assets/images/signt.png" class="imgt" />
      <div class="sign_box">
        <div class="days">
          <div class="item" v-for="(item, i) in 7" :key="i">
            <span class="date">第{{ days[i] }}天</span>
            <div
              class="box"
              v-if="i < 6"
              :class="[
                date && signTotal + 1 == item ? 'current' : '',
                signTotal >= item ? 'active' : ''
              ]"
              @click="sign(item)"
            >
              <img src="../../assets/images/sign1.png" alt="" />
              <span class="s1">+{{ signTokens }}</span>
              <span class="s2">tokens</span>
              <span class="s3" v-if="signTotal >= item">已领取</span>
              <span class="s3" v-else>待领取</span>
            </div>
            <div
              class="box zuan"
              v-if="i > 5"
              :class="[
                date && signTotal + 1 == item ? 'current' : '',
                signTotal >= item ? 'active' : ''
              ]"
              @click="sign(item)"
            >
              <img src="../../assets/images/sign2.png" />
              <span class="s2">橙钻VIP</span>
              <span class="s3" v-if="signTotal >= item">已激活</span>
              <span class="s3" v-else>待激活</span>
            </div>
          </div>
        </div>
      </div>
      <div class="sign_tip">
        活动说明:每日签到领取橙钻对话额度，累计7日签到即可激活橙钻VIP会员权益!本活动长期有效
      </div>
      <!-- <div class="sign_btn" @click="sign">签到</div> -->
    </div>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { onMounted, reactive, toRefs, computed } from 'vue'
import { getSignin, getcumulativesignin } from '@/api/common'
import { useStore } from 'vuex'
import { initTime } from '@/utils/validate'
import { getpersonalused } from '@/api/order'
const store = useStore()
const datas = reactive({
  days: ['一', '二', '三', '四', '五', '六', '七'],
  date: false
})
const { days, date } = toRefs(datas)
const signTotal = computed(() => store.state.common.signTotal)
const signTokens = computed(() => store.state.common.signTokens)
onMounted(() => {
  if (localStorage.getItem('token')) {
    getSignTotal()
  }
})
// 关闭弹窗
const closeSign = () => {
  store.commit('common/setSignMask', false)
  sessionStorage.setItem('sign', false)
}

// 签到
const sign = index => {
  if (localStorage.getItem('token')) {
    if (index < signTotal.value + 1) {
      return false
    }
    if (index > signTotal.value + 1) {
      return false
    }
    if (!date.value) {
      return false
    }
    getSignin().then(response => {
      const { data: res, status } = response
      if (status == 200) {
        if (res.code == 0) {
          ElMessage.success('签到成功')
          getSignTotal()
        }
      }
      if (status == 202) {
        ElMessage.warning(res.msg)
      }
    })
  } else {
    ElMessage.warning('请登录后签到')
    store.commit('common/setSignMask', false)
    setTimeout(() => {
      store.commit('common/setLoginMask', true)
    }, 1500)
  }
}

const getSignTotal = () => {
  getcumulativesignin().then(response => {
    const { data: res, status } = response
    if (status == 200) {
      if (res.code == 0) {
        date.value =
          initTime() != res.data.signInDate[res.data.signInDate.length - 1]
        store.commit('common/setSignTotal', res.data.total)
        store.commit('common/setSignTokens', res.data.tokens)
        if (res.data.total == 7) {
          getpersonalusedlist()
        }
      }
    }
  })
}

const getpersonalusedlist = () => {
  getpersonalused().then(({ data: res }) => {
    if (res.code == 0) {
      if (res.data.orderData) {
        localStorage.setItem('useToken', JSON.stringify(res.data.orderData))
        store.commit('common/setUseToken', res.data.orderData)
      }
    }
  })
}
</script>
<style lang="scss" scoped>
.sign {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .signMask {
    width: 1000px;
    height: 400px;
    background: #ffffff;
    border-radius: 16px;
    position: relative;
    .signclose {
      width: 28px;
      height: 28px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .imgt {
      width: 100%;
      height: 77px;
      vertical-align: top;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .sign_box {
      margin: 97px 56px 41px;
      user-select: none;
      .days {
        display: flex;
        .item {
          position: relative;
          width: 96px;
          margin-right: 36px;
          &:last-child {
            margin-right: 0;
          }
          .date {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: -30px;
            color: #85858f;
            font-size: 14px;
            text-align: center;
          }
          .box {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 130px;
            background: #f7f7f7;
            border-radius: 16px;
            font-size: 0;
            cursor: pointer;
            img {
              width: 40px;
              height: 42px;
              margin-bottom: 7px;
              margin-top: 20px;
            }
            span {
              color: #85858f;
              line-height: 16px;
            }
            .s1 {
              font-size: 16px;
            }
            .s2 {
              font-size: 13px;
            }
            .s3 {
              font-size: 12px;
            }
          }
          .active {
            background: linear-gradient(180deg, #ff9900 0%, #ffa841 100%);
            span {
              color: #fff;
            }
          }
          .current {
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            background: linear-gradient(180deg, #ff9900 0%, #ffa841 100%);
            border: 2px solid #ff5d08;
            span {
              color: #85858f;
            }
          }
          .zuan {
            img {
              width: 39px;
              height: 39px;
              margin-bottom: 11px;
            }
            .s2 {
              font-size: 16px;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
    .sign_tip {
      color: #85858f;
      font-size: 14px;
      text-align: center;
      margin: 41px 0 46px 0;
    }
    .sign_btn {
      width: 760px;
      height: 39px;
      background: #ffa84a;
      border-radius: 2px;
      text-align: center;
      line-height: 39px;
      font-size: 16px;
      color: #fff;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
</style>
