<!-- 其他操作 -->
<template>
  <div class="otherServices">
    <ul>
      <li v-for="(item, i) in list" :key="i" @click="handleClickTo(item)">
        <img :src="item.url" alt="" />{{ item.title }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { reactive, toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
import { useStore } from 'vuex'
const store = useStore()
const initData = reactive({
  list: [
    {
      code: 'help',
      title: '使用帮助',
      url: require('@/assets/images/help.png')
    },
    {
      code: 'sign',
      title: '签到',
      url: require('@/assets/images/sign.png')
    },
    {
      code: 'vip',
      title: '升级VIP',
      url: require('@/assets/images/vip.png')
    },
    {
      code: 'service',
      title: '联系客服',
      url: require('@/assets/images/service.png')
    },
    {
      code: 'order',
      title: '订单记录',
      url: require('@/assets/images/order.png')
    },
    {
      code: 'about',
      title: '关于我们',
      url: require('@/assets/images/about.png')
    }
  ]
})
const { list } = toRefs(initData)
const loading = computed(() => store.state.common.loading)
const handleClickTo = item => {
  if (item.code == 'order' && !loading.value) {
    if (localStorage.getItem('token')) {
      store.commit('common/setVipsMask', false)
      store.commit('common/setServiceMask', false)
      store.commit('common/setAboutMask', false)
      router.push({
        path: '/order'
      })
    } else {
      store.commit('common/setServiceMask', false)
      store.commit('common/setAboutMask', false)
      store.commit('common/setLoginMask', true)
    }
  }
  if (item.code == 'vip') {
    store.commit('common/setVipsMask', true)
    store.commit('common/setServiceMask', false)
    store.commit('common/setSignMask', false)
    store.commit('common/setAboutMask', false)
  }
  if (item.code == 'service') {
    store.commit('common/setServiceMask', true)
    store.commit('common/setAboutMask', false)
    store.commit('common/setSignMask', false)
    store.commit('common/setServiceContent', true)
  }
  if (item.code == 'about') {
    store.commit('common/setServiceMask', false)
    store.commit('common/setVipsMask', false)
    store.commit('common/setSignMask', false)
    store.commit('common/setAboutMask', true)
  }
  if (item.code == 'sign') {
    store.commit('common/setServiceMask', false)
    store.commit('common/setVipsMask', false)
    store.commit('common/setAboutMask', false)
    store.commit('common/setSignMask', true)
  }
  if (item.code == 'help') {
    store.commit('common/setServiceMask', false)
    store.commit('common/setVipsMask', false)
    store.commit('common/setAboutMask', false)
    store.commit('common/setSignMask', false)
    store.commit('common/setHelpMask', true)
  }
}
</script>
<style lang="scss" scoped>
.otherServices {
  width: 100%;
  // height: 178px;
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  ul {
    width: 100%;
  }
  li {
    line-height: 24px;
    cursor: pointer;
    margin-left: 52px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
