<template>
  <div class="session" @click="closeSession">
    <div class="sessionMask" @click.stop>
      <div class="title">会话设置</div>
      <div class="sessionform">
        <div class="form_item">
          <span class="form_item_label">模型</span>
          <el-select v-if="model.length == 0" placeholder="请选择"> </el-select>
          <el-select
            v-else
            v-model="modelVal"
            @change="modelChange"
            placeholder="请选择"
          >
            <el-option
              v-for="item in model"
              :key="item.modelCode"
              :label="item.modelName"
              :value="item.modelCode"
              :disabled="
                (diamond == '' && item.modelCode == 'gpt3.5') ||
                (!diamond.includes('orange') && item.modelCode == 'gpt4.0')
              "
            />
          </el-select>
        </div>
        <div class="form_item">
          <span class="form_item_label">答案最大字数</span>
          <el-input v-model="num" class="maxinput" @keyup="numChange">
            <template #append>
              <el-icon @click="jian"><Minus /></el-icon>
              <el-icon @click="jia"><Plus /></el-icon>
            </template>
          </el-input>
          <span class="tips">
            1汉字≈2tokens，最大{{ maxNum / 2 }}汉字即约{{ maxNum }}tokens
          </span>
        </div>
        <div class="form_item">
          <span class="form_item_label">上下文</span>
          <el-radio-group v-model="context" :disabled="modelVal == 'gpt3.5'">
            <el-radio-button :label="1">连续</el-radio-button>
            <el-radio-button :label="0">断开</el-radio-button>
          </el-radio-group>
          <span class="tips contexttips">
            上下文连续模式相当于对话交谈，可对一个问题进行连续讨论并完善优化答案，但同时也会消耗大量tokens
          </span>
        </div>
        <el-button type="primary" class="submitBtn" @click="submitBtn">
          保存
        </el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { onMounted, reactive, toRefs, computed } from 'vue'
import { getrequestconfig, updaterequestconfig } from '@/api/expert'
import { useStore } from 'vuex'
const store = useStore()
const datas = reactive({
  model: [],
  modelVal: '',
  num: 0,
  maxNum: 0,
  context: -1,
  diamond: ''
})
const { model, modelVal, maxNum, context, num, diamond } = toRefs(datas)
const modelCode = computed(() => store.state.common.modelCode)
onMounted(() => {
  modelVal.value = modelCode.value
  if (localStorage.getItem('diamond')) {
    diamond.value = JSON.parse(localStorage.getItem('diamond'))
  }
  if (
    modelVal.value == 'gpt4.0' &&
    diamond.value != '' &&
    !diamond.value.includes('orange')
  ) {
    modelVal.value = 'yan'
  }
  getrequestconfigs()
})

// 获取数据
const getrequestconfigs = code => {
  let id = modelVal.value
  if (code) {
    id = code
  }
  getrequestconfig({ id: id }).then(({ data: res }) => {
    if (res.code == 0) {
      model.value = res.data.modelDic
      num.value = Number(res.data.token)
      maxNum.value = Number(res.data.max)
      context.value = Number(res.data.contexts)
    }
  })
}

// 数字减
const jian = () => {
  if (num.value > 0) {
    num.value -= 200
    if (num.value < 0) {
      num.value = 0
    }
  }
}
// 数字加
const jia = () => {
  if (num.value < maxNum.value) {
    num.value = Number(num.value) + 200
    if (num.value > maxNum.value) {
      num.value = maxNum.value
    }
  }
}
// 输入框输入限制
const numChange = e => {
  e.target.value = e.target.value.replace(/[^\d]/g, '')
  if (Number(e.target.value) > maxNum.value) {
    num.value = maxNum.value
  }
}

// 关闭弹窗
const closeSession = () => {
  store.commit('common/setSessionMask', false)
}

// 模型切换
const modelChange = () => {
  getrequestconfigs(modelVal.value)
}

// 保存
const submitBtn = () => {
  if (num.value == 0) {
    ElMessage.warning('请输入答案最大字数')
    return false
  }
  const data = {
    token: num.value,
    contexts: context.value,
    model: modelVal.value
  }
  updaterequestconfig(data).then(({ data: res }) => {
    if (res.code == 0) {
      ElMessage.success(res.data.msg)
      store.commit('common/setSessionMask', false)
    } else {
      ElMessage.warning(res.msg)
    }
  })
}
</script>
<style lang="scss" scoped>
.session {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .sessionMask {
    width: 600px;
    height: 400px;
    background: #fff;
    border-radius: 8px;
    user-select: none;
    .title {
      font-size: 18px;
      color: #3e3f4f;
      margin: 20px 30px;
    }
    .sessionform {
      margin: 30px 38px;
      .form_item {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        .form_item_label {
          width: 60px;
          color: #3e3f4f;
          font-size: 14px;
          margin-right: 5px;
        }
        :deep(.el-icon) {
          color: #3e3f4f;
        }
        :deep(.maxinput) {
          width: 190px;
          margin-right: 5px;
          .el-input-group__append {
            padding: 0;
            background: #fff;
            .el-icon {
              margin: 0 8px;
              cursor: pointer;
              color: #3e3f4f;
            }
          }
        }
        .tips {
          color: #85858f;
          font-size: 12px;
        }
        .contexttips {
          width: 325px;
          margin-left: 10px;
        }
      }
      :deep(.submitBtn) {
        margin-left: 65px;
      }
    }
  }
}
</style>
