<!-- 提示弹窗 -->
<template>
  <div class="draw">
    <div class="draw_main">
      <img
        src="../../assets/images/close.svg"
        class="close"
        @click="drawMaskChange"
      />
      <h6>绘图风格</h6>
      <div class="draw_label" v-if="labelList.length > 0">
        <div class="draw_label_left">
          <div
            class="draw_label_item"
            v-for="(item, i) in labelList[0]"
            :key="i"
            @click="changeItem(item)"
            :class="labelActive == item.id ? 'active' : ''"
          >
            {{ item.categoryName }}
          </div>
        </div>
        <div class="draw_label_right">
          <div
            class="draw_label_item"
            v-for="(item, i) in labelList[1]"
            :key="i"
            @click="changeItem(item)"
            :class="labelActive == item.id ? 'active' : ''"
          >
            {{ item.categoryName }}
          </div>
        </div>
      </div>
      <div class="draw_list">
        <div
          class="draw_list_item"
          v-for="(item, index) in waterList"
          :key="index"
          :style="{
            width: width + 'px',
            height: item.height + 'px',
            left: item.left + 'px',
            top: item.top + 'px'
          }"
          @click="drawPreview(item)"
        >
          <img :src="item.img_url_preview" class="bg" />
          <div class="draw_list_hover">
            <div class="draw_list_label">
              <div v-for="(el, i) in item.category_name" :key="i">{{ el }}</div>
            </div>
            <img
              class="draw_list_collect"
              :src="
                item.isFavorites == 1
                  ? require('../../assets/images/collectactive1.svg')
                  : require('../../assets/images/collect.svg')
              "
              @click.stop="collectActive(item)"
            />
            <p class="draw_list_prompt">{{ item.prompt }}</p>
            <div class="btns">
              <div @click.stop="multiplex(item)">复用该创意</div>
              <div @click.stop="copy(item)">复制提示词</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="draw_mask" v-if="JSON.stringify(item) != '{}'">
      <div class="draw_content">
        <img
          src="../../assets/images/close.svg"
          class="close"
          @click="drawMaskChangePreview"
        />
        <!-- 图片 -->
        <img
          :src="item.img_url"
          class="pic"
          :class="
            item.img_size == '1:1'
              ? 'pic1'
              : item.img_size == '9:16'
              ? 'pic9'
              : 'pic16'
          "
          :style="`transform:scale(${scale})`"
          @wheel="handleWheel"
          @mousedown.prevent="moveImg"
        />
        <div
          class="draw_content_info"
          :style="{
            width: showInfo ? '300px' : '0',
            opacity: showInfo ? 1 : 0
          }"
        >
          <div class="draw_content_item">
            <span>生成方式</span>
            <p>文本图像生成</p>
          </div>
          <div class="draw_content_item">
            <span>创意输入</span>
            <p>{{ item.prompt }}</p>
          </div>
          <div class="draw_content_item">
            <span>画面风格</span>
            <p v-for="(item, i) in item.category_name" :key="i">{{ item }}</p>
          </div>
          <div class="draw_content_item">
            <span>图像比例</span>
            <p>{{ item.img_size }}</p>
          </div>
          <div class="draw_content_item">
            <span>模型</span>
            <p>神算绘图</p>
          </div>
        </div>
      </div>
      <div class="operate">
        <div class="operate_left">
          <span
            class="iconfont icon-fangda"
            @click="zoomIn"
            :style="{ cursor: scale >= 20 ? 'default' : 'pointer' }"
            :class="scale >= 20 ? 'disabled' : ''"
          ></span>
          <span
            class="iconfont icon-suoxiao"
            :class="scale == 1 ? 'disabled' : ''"
            :style="{ cursor: scale == 1 ? 'default' : 'pointer' }"
            @click="zoomOut"
          ></span>
          <span class="iconfont icon-huanyuan" @click="reduction"></span>
        </div>
        <div class="operate_middle" @click.stop="multiplex(item)">
          复用该创意
        </div>
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="!showInfo ? '查看详情' : '隐藏详情'"
          placement="top"
        >
          <div class="operate_right" :class="showInfo ? 'operate_bg' : ''">
            <span
              class="iconfont icon-gantanhaozhong"
              @click="hanldeShowInfo"
            ></span>
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import useClipboard from 'vue-clipboard3'
import { onMounted, reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { clearStorage } from '@/utils/common'
import { ElMessage } from 'element-plus'
const { toClipboard } = useClipboard()
import {
  getdrawcategory,
  getdrawpromptsbycategory,
  adddrawpromptsfavorites,
  dropdrawpromptsfavorites
} from '@/api/expert'
import { getChatList, updateChatName } from '@/api/common'
const store = useStore()

const chatList = computed(() => store.state.chat.chatList)
// const chatId = computed(() => store.state.chat.chatId)
const chatListCurrent = computed(() => store.state.chat.chatListCurrent)
const insertBtn = computed(() => store.state.common.insertBtn)
const loading = computed(() => store.state.common.loading)
const modelCode = computed(() => store.state.common.modelCode)
const chatId = computed({
  get() {
    return store.state.chat.chatId
  },
  set(val) {
    store.commit('chat/setChatId', val)
  }
})

const initData = reactive({
  sizeList: [
    {
      scale: '1:1',
      size: '1024*1024'
    },
    {
      scale: '16:9',
      size: '1280*720'
    },
    {
      scale: '9:16',
      size: '720*1280'
    }
  ],
  picList: [
    {
      title: '自动',
      value: '<auto>',
      src: require('../../assets/images/draw7.jpeg')
    },
    {
      title: '二次元',
      value: '<anime>',
      src: require('../../assets/images/draw8.jpeg')
    },
    {
      title: '3D卡通',
      value: '<3d cartoon>',
      src: require('../../assets/images/draw6.jpeg')
    },
    {
      title: '扁平插画',
      value: '<flat illustration>',
      src: require('../../assets/images/draw5.jpeg')
    },
    {
      title: '素描',
      value: '<sketch>',
      src: require('../../assets/images/draw4.jpeg')
    },
    {
      title: '油画',
      value: '<oil painting>',
      src: require('../../assets/images/draw1.jpeg')
    },
    {
      title: '中国画',
      value: '<chinese painting>',
      src: require('../../assets/images/draw3.jpeg')
    },
    {
      title: '水彩画',
      value: '<watercolor>',
      src: require('../../assets/images/draw2.jpeg')
    }
  ],
  loading: false,
  labelList: [],
  labelActive: 0,
  images: [],
  waterList: [],
  heightList: [],
  width: 0,
  gap: 20,
  cloumn: 5,
  item: {},
  scale: 1,
  showInfo: true
})
const {
  sizeList,
  picList,
  labelList,
  images,
  waterList,
  heightList,
  gap,
  width,
  cloumn,
  labelActive,
  item,
  scale,
  showInfo
} = toRefs(initData)

onMounted(() => {
  width.value = Math.floor(
    (document.getElementsByClassName('draw_list')[0].clientWidth -
      gap.value * cloumn.value) /
      cloumn.value
  )
  getData()
})

// 瀑布流数据格化
const waterFallInit = () => {
  // 核心内容就是维护每个图片的 left、top
  for (let i = 0; i < images.value.length; i++) {
    // 先铺上第一行（i < column 则表示是第一行）
    if (i < cloumn.value) {
      images.value[i].top = 0
      images.value[i].left = (width.value + gap.value) * i
      // 塞进瀑布流
      // waterList.value.push(images.value[i])
      // 高度数据更新
      heightList.value[i] = images.value[i].height
    }
    // 后面的就要一张张塞进去，每次找出最低的列往里塞
    else {
      // 最低的高度，先默认为第一列高度
      let current = heightList.value[0]
      // 最低的列，先默认为第一个
      let col = 0
      // 循环每一列进行比较
      heightList.value.forEach((h, i) => {
        if (h < current) {
          current = h
          col = i
        }
      })
      // 由此计算出该图片的 left、top
      images.value[i].left = col * (width.value + gap.value)
      images.value[i].top = current + gap.value
      // 塞进瀑布流
      // waterList.value.push(images.value[i])
      // 更新列高度数组
      heightList.value[col] = current + gap.value + images.value[i].height
    }
    waterList.value = images.value
  }
}

// 复用该创意
const multiplex = item => {
  const size = sizeList.value.filter(el => el.scale == item.img_size)[0].size
  const title = picList.value.filter(el => el.title == item.category_name[0])[0].value
  store.commit('common/setSizeActive', size)
  store.commit('common/setPicActive', title)
  store.commit('common/setPicNum', 1)
  store.commit('chat/setChatValue', item.prompt)
  store.commit('draw/setDrawExpert', false)
  store.commit('common/setPromptsIdChange', item.id)
  item.value = {}
  if (!insertBtn.value) {
    drawList()
  }
}

// 新增绘画对话
const drawList = () => {
  maxChatId()
  if (!chatList.value['today']) {
    chatList.value['today'] = []
  }
  chatList.value['today'].unshift({
    title: 'New Chat',
    edit: false,
    type: 'draw',
    list: []
  })
  store.commit('chat/setChatListCurrent', 0)
  store.commit('chat/setChatKey', 'today')
  const data = {
    chatId: Number(chatId.value) + 1,
    chatName: chatList.value['today'][chatListCurrent.value].title,
    type: 'draw'
  }
  updateChatName(data)
    .then(async response => {
      const { data: res, status } = response
      if (status == 200) {
        await store.dispatch('chat/getListData', 'drawAdd')
        handleClickChatItem({ id: data.chatId, type: 'draw' }, 0, 'today')
      }
      if (status == 202) {
        chatList.value['today'].shift()
        store.commit('chat/setChatValue', '')
        store.commit('chat/setChatListCurrent', 0)
        if (res.code == 1) {
          ElMessage.warning(res.msg)
          clearStorage()
          store.commit('common/setFreeTips', true)
          setTimeout(() => {
            store.commit('common/setLoginMask', true)
          }, 3000)
        } else {
          if (res.code == 3) {
            ElMessage.warning(res.msg)
            setTimeout(() => {
              store.commit('common/setVipsMask', true)
            }, 3000)
          }
          if (res.code == 0) {
            ElMessage.warning(res.msg)
          }
          if (res.code == 4) {
            ElMessage.warning(res.msg)
            store.commit('common/setVipType', 'purple')
            setTimeout(() => {
              store.commit('common/setVipsMask', true)
            }, 3000)
            setTimeout(() => {
              getModelDrawInfo()
            }, 1600)
          }
        }
      }
    })
    .catch(() => {
      setTimeout(() => {
        store.dispatch('chat/getListData', 'drawAdd')
        store.commit('chat/setChatValue', '')
      }, 1500)
    })
}

const maxChatId = () => {
  let idList = []
  for (const key1 in chatList.value) {
    if (chatList.value.hasOwnProperty.call(chatList.value, key1)) {
      const element = chatList.value[key1]
      for (const key in element) {
        if (element.hasOwnProperty.call(element, key)) {
          const item = element[key]
          idList.push(item)
        }
      }
    }
  }
  let maxId = -1
  if (idList.length > 0) {
    // 遍历JSON数据，找到最大的id
    for (let i = 0; i < idList.length; i++) {
      if (idList[i].id > maxId) {
        maxId = idList[i].id
      }
    }
    store.commit('chat/setChatId', maxId)
  } else {
    store.commit('chat/setChatId', 0)
  }
}

// 点击切换标题
const handleClickChatItem = (item, i, key2) => {
  let chatlist = chatList.value
  if (loading.value) {
    return false
  }
  const datas = {
    id: item.id
  }
  if (item.type == 'draw') {
    datas.limit = 2
    datas.page = 1
  }
  store.commit('common/setVipsMask', false)
  store.commit('common/setServiceMask', false)
  getChatList(datas)
    .then(response => {
      const { data: res, status } = response
      if (status == 200) {
        if (res.code == 0) {
          if (res.msg.firstChatContent) {
            if (modelCode.value == 'sszdraw-v1' || modelCode.value == 'mj') {
              chatlist[key2][0].list = res.msg.firstChatContent.lasted
              // store.commit(
              //   'common/setCurrentModel',
              //   res.msg.firstChatContent.lasted[0].model
              // )
            }
          } else {
            chatlist[key2][0].list = []
            store.commit('chat/setModelAndSilde', true)
            store.commit('common/setHistoryLogin', [])
            store.commit('common/setHistoryLoginTotal', 0)
            setTimeout(() => {
              store.commit('chat/setModelAndSilde', false)
            }, 200)
          }
          store.commit('common/setChatChange', true)
          setTimeout(() => {
            store.commit('common/setChatChange', false)
          }, 200)
        }
      }
      if (status == 202) {
        if (res.code == 1) {
          ElMessage.warning('验证失败，请重新登录或完成注册')
          store.commit('chat/setChatValue', '')
          clearStorage()
          setTimeout(() => {
            store.commit('common/setLoginMask', true)
          }, 3000)
        } else {
          if (res.code == 3) {
            store.commit('common/setVipsMask', true)
          }
          if (res.code == 0) {
            ElMessage.warning(res.msg)
          }
        }
      }
    })
    .catch(() => {})
}

// 获取当前模型绘图信息
const getModelDrawInfo = () => {
  for (const key in chatList.value) {
    // eslint-disable-next-line no-prototype-builtins
    if (chatList.value.hasOwnProperty(key)) {
      const items = chatList.value[key]
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (item.type === 'draw' && item.id) {
          handleClickChatItem(item, i, key)
        }
      }
    }
  }
}

// 复制提示词
const copy = async item => {
  try {
    //复制
    await toClipboard(item.prompt)
    store.commit('common/setPromptsIdChange', item.id)
    ElMessage.success('复制成功')
  } catch (e) {
    //复制失败
    console.error(e)
  }
}
// 预览图片
const drawPreview = i => {
  item.value = i
  showInfo.value = true
  scale.value = 1
}

// 关闭弹窗
const drawMaskChange = () => {
  store.commit('draw/setDrawExpert', false)
}

const drawMaskChangePreview = () => {
  item.value = {}
}

// 获取数据
const getData = () => {
  getdrawcategory().then(async response => {
    const { data: res, status } = response
    if (status == 200) {
      if (res.code == 0) {
        labelList.value = res.msg
        getDrawData(0)
      }
    }
  })
}

// 获取绘图数据
const getDrawData = id => {
  getdrawpromptsbycategory({ id: id }).then(async response => {
    const { data: res, status } = response
    if (status == 200) {
      if (res.code == 0) {
        waterList.value = []
        res.msg.forEach(item => {
          const img_size = item.img_size.split(':')
          item.height = (width.value / img_size[0]) * img_size[1]
        })
        images.value = res.msg
        waterFallInit()
      }
    }
  })
}

// 点击标签
const changeItem = item => {
  labelActive.value = item.id
  getDrawData(item.id)
}

// 是否收藏
const collectActive = item => {
  if (item.isFavorites == 1) {
    dropdrawpromptsfavorites({
      id: item.id
    }).then(response => {
      const { data: res, status } = response
      if (status == 200) {
        if (res.code == 0) {
          item.isFavorites = 0
          getDrawData(labelActive.value)
          ElMessage.success(res.msg)
        }
      }
      if (status == 202) {
        if (res.code == 1) {
          ElMessage.warning(res.msg)
          init()
        } else {
          ElMessage.warning(res.msg)
        }
      }
    })
  } else {
    adddrawpromptsfavorites({
      id: item.id
    }).then(response => {
      const { data: res, status } = response
      if (status == 200) {
        if (res.code == 0) {
          item.isFavorites = 1
          getDrawData(labelActive.value)
          ElMessage.success(res.msg)
        }
      }
      if (status == 202) {
        if (res.code == 1) {
          ElMessage.warning(res.msg)
          init()
        } else {
          ElMessage.warning(res.msg)
        }
      }
    })
  }
}

// 放大
const zoomIn = () => {
  showInfo.value = false
  if (scale.value < 20) {
    const randomValue = Math.random() * (1.5 - 0.5) + 0.5
    scale.value += randomValue
  } else {
    scale.value = 20
  }
}

// 缩小
const zoomOut = () => {
  if (scale.value > 1) {
    const randomValue = Math.random() * (1.5 - 0.5) + 0.5
    scale.value -= randomValue
    if (scale.value < 1) {
      scale.value = 1
    }
  }
}
// 还原
const reduction = () => {
  scale.value = 1
}

// 信息展示切换
const hanldeShowInfo = () => {
  showInfo.value = !showInfo.value
  if (scale.value > 1) {
    scale.value = 1
  }
  if (showInfo.value) {
    document.getElementsByClassName('pic')[0].style.position = 'relative'
    document.getElementsByClassName('pic')[0].style.left = 'inherit'
    document.getElementsByClassName('pic')[0].style.top = 'inherit'
    document.getElementsByClassName('pic')[0].style.cursor = 'default'
  }
}

// 滚轮放大缩小
const handleWheel = event => {
  showInfo.value = false
  const delta = event.deltaY > 0 ? -0.1 : 0.1
  scale.value = Math.max(1, Math.min(20, scale.value + delta))
}

// 图片拖拽
const moveImg = e => {
  if (showInfo.value) {
    return false
  }
  let x = e.clientX
  let y = e.clientY
  let left = x - e.target.offsetLeft
  let top = y - e.target.offsetTop
  document.onmousemove = ev => {
    e.target.style.cursor = 'move'
    e.target.style.position = 'absolute'
    e.target.style.left = ev.clientX - left + 'px'
    e.target.style.top = ev.clientY - top + 'px'
  }
  document.onmouseup = () => {
    document.onmousemove = null
  }
}

// 初始化
const init = () => {
  clearStorage()
  store.commit('common/setUserName', '')
  setTimeout(() => {
    store.commit('common/setExpertMask', false)
    store.commit('common/setLoginMask', true)
  }, 3000)
}
</script>
<style lang="scss" scoped>
$height: 100px;
.draw {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .draw_main {
    width: 1108px;
    height: 680px;
    background: #454253;
    box-shadow: 0px 0px 20px 5px rgba(14, 38, 100, 0.22);
    border-radius: 10px;
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    h6 {
      font-size: 28px;
      color: #fff;
    }
    .draw_label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0;
      &_left,
      &_right {
        display: flex;
        align-items: center;
      }
      &_item {
        height: 30px;
        line-height: 30px;
        text-align: center;
        padding: 0 16px;
        border: 1px solid #ff7900;
        font-size: 14px;
        color: #ff7900;
        margin-right: 14px;
        cursor: pointer;
        border-radius: 3px;
      }
      .active {
        background: #ff7900;
        color: #fff;
      }
    }
    .draw_list {
      height: calc(100% - 110px);
      position: relative;
      overflow: hidden;
      overflow-y: auto;
      &_item {
        position: absolute;
        cursor: pointer;
        .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
        &:hover {
          .draw_list_hover {
            display: block;
          }
        }
      }
      &_hover {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        display: none;
        transition: all 2s ease-in-out;
      }
      &_label {
        display: flex;
        align-items: center;
        div {
          font-size: 12px;
          background: #3e3f4f;
          color: #ff7900;
          padding: 3px 10px;
          border-radius: 5px;
          margin-right: 20px;
        }
      }
      &_collect {
        width: 16px;
        height: 16px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
      }
      &_prompt {
        width: 100%;
        font-size: 12px;
        margin: 5px 0;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 超出几行省略 */
        overflow: hidden;
      }
      .btns {
        font-size: 0;
        div {
          display: inline-block;
          padding: 3px 10px;
          background: #f27809;
          border-radius: 2px;
          font-size: 12px;
          cursor: pointer;
          &:first-child {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 33px;
    right: 52px;
    cursor: pointer;
    z-index: 999;
  }
  .draw_mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    backdrop-filter: blur(10px);
    background-color: rgba(7, 12, 20, 0.85) !important;
    display: flex;
    justify-content: center;
    .draw_content {
      display: flex;
      justify-content: center;
      margin-top: 10vh;

      .pic {
        border-radius: 8px;
        transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
      }
      .pic1 {
        width: 490px;
        height: 490px;
      }
      .pic16 {
        width: 871px;
        height: 490px;
      }
      .pic9 {
        width: 276px;
        height: 490px;
      }
      &_info {
        background-color: transparent;
        margin-left: 48px;
        opacity: 1;
        pointer-events: all;
        transition: all 0.3s ease-in-out;
        width: 300px;
      }
      &_item {
        display: flex;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;
        width: 300px;
        span {
          color: #878aab;
          display: inline-block;
          margin-right: 20px;
          text-align: left;
          width: 56px;
        }

        p {
          word-wrap: break-word;
          color: #fff;
          margin-bottom: 0;
          text-align: left;
          width: calc(100% - 81px);
        }
      }
    }
    .operate {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 20px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 103;
      .operate_left {
        background-color: #2d3240;
        border-radius: 60px;
        height: 40px;
        padding: 10px 18px;
        width: 144px;
        backdrop-filter: blur(5px);
        display: flex;
        justify-content: space-between;
        position: relative;
        &::after {
          background: hsla(0, 0%, 100%, 0.1);
          content: '';
          height: 12px;
          right: 34%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
        }
        &::before {
          background: hsla(0, 0%, 100%, 0.1);
          content: '';
          height: 12px;
          left: 34%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
        }
        span {
          font-size: 16px;
          cursor: pointer;
          color: hsla(0, 0%, 100%, 0.7);
          &:hover {
            color: #fff;
          }
        }
      }
      .operate_middle {
        background-color: #fc750d;
        border-radius: 60px;
        height: 40px;
        padding: 10px 18px;
        color: #fff;
        cursor: pointer;
        margin: 0 20px;
      }
      .operate_right {
        background-color: #2d3240;
        backdrop-filter: blur(5px);
        border-radius: 100%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          cursor: pointer;
          font-size: 24px;
          color: hsla(0, 0%, 100%, 0.7);
          &:hover {
            color: #fff;
          }
        }
      }
      .operate_bg {
        background: #fff;
        span {
          color: #3f3f3f !important;
        }
      }
      .disabled {
        color: rgba(242, 246, 255, 0.3) !important;
      }
    }
  }
}
</style>
