<template>
  <div class="userInfo">
    <div class="userInfomain">
      <div class="title">基本信息</div>
      <img
        src="../../assets/images/userclose.png"
        class="close"
        @click="close"
      />
      <div class="infobox">
        <img src="../../assets/images/avatar.png" class="avatar" />
        <div class="info">
          <p class="username">
            <span>{{ userInfos.userName }}</span>
            <img src="../../assets/images/blueicon.png" v-if="userInfos.blue" />
            <img
              src="../../assets/images/orangeicon.png"
              v-if="userInfos.orange"
            />
            <img src="../../assets/images/hyicon.png" v-if="userInfos.member" />
            <img
              class="purpleimg"
              src="../../assets/images/purpleicon.png"
              v-if="userInfos.purple"
            />
          </p>
          <p class="other">
            <img src="../../assets/images/phone.png" />
            <span>{{ userInfos.phonenum }}</span>
            <img src="../../assets/images/time.png" class="last" />
            <span>{{ userInfos.createdAt }}注册</span>
          </p>
        </div>
      </div>
      <div class="title">VIP资源信息</div>
      <div class="blue" v-if="userInfos.blue || userInfos.member">
        <img v-if="userInfos.blue" src="../../assets/images/bluebg.png" />
        <img v-if="userInfos.member" src="../../assets/images/huiyuanbg.png" />
        <div class="blueinfo">
          <div class="item" v-if="userInfos.common">
            <span>
              <i>{{ userInfos.common.chatMax }}</i>
            </span>
            <span>总会话个数</span>
          </div>
          <div class="item">
            <span>
              <i>{{ userInfos.common.requestTimesMax }} </i>
            </span>
            <span>总提问个数</span>
          </div>
          <div class="item">
            <span>
              <i>{{ userInfos.common.chatTtl }}</i>
            </span>
            <span>会话有效期</span>
          </div>
          <div class="item">
            <span>
              <i>{{ userInfos.common.vipExpiratedTime }}</i>
            </span>
            <span>订阅到期时间</span>
          </div>
        </div>
      </div>
      <div class="orange" v-if="userInfos.orange">
        <img src="../../assets/images/orangebg.png" />
        <div class="blueinfo">
          <div
            class="orangebox"
            :style="{
              'border-right':
                userInfos.orangeInfo.rewardTotal > 0
                  ? '1px solid rgba(162, 162, 169, 0.51)'
                  : 'none'
            }"
          >
            <div class="item">
              <span>
                <i>{{ userInfos.orangeInfo.total }}</i>
              </span>
              <span>总tokens</span>
            </div>
            <div class="item">
              <span>
                <i>{{ userInfos.orangeInfo.remainder }}</i>
              </span>
              <span>剩余tokens</span>
            </div>
          </div>
          <div
            class="orangeInfobox"
            v-if="userInfos.orangeInfo.rewardTotal > 0"
          >
            <div class="item">
              <span>
                <i>{{ userInfos.orangeInfo.rewardTotal }}</i>
              </span>
              <span>额外赠送tokens</span>
            </div>
            <div class="item">
              <span>
                <i>{{ userInfos.orangeInfo.rewardRemainder }}</i>
              </span>
              <span>额外赠送剩余tokens</span>
            </div>
            <div class="item">
              <span>
                <i>{{ userInfos.orangeInfo.rewardTimes }}</i>
              </span>
              <span>分享成功注册并登录用户</span>
            </div>
          </div>
        </div>
      </div>
      <div class="blue purple" v-if="userInfos.purple">
        <img src="../../assets/images/purplebg.png" />
        <div class="blueinfo">
          <div class="item">
            <span>
              <i>{{ userInfos.purpleInfo.drawPaperMax }}</i>
            </span>
            <span>总绘图张数</span>
          </div>
          <div class="item">
            <span>
              <i>{{ userInfos.purpleInfo.drawMax }} </i>
            </span>
            <span>总对话个数</span>
          </div>
          <div class="item">
            <span>
              <i>{{ userInfos.purpleInfo.expiratedTime }}</i>
            </span>
            <span v-if="userInfos.purpleInfo.isMonthly == 1"
              >包月绘图有效期</span
            >
            <span v-if="userInfos.purpleInfo.isMonthly == 0">绘图有效期</span>
          </div>
          <div class="item">
            <span>
              <i>{{ userInfos.purpleInfo.vipExpiratedTime }}</i>
            </span>
            <span v-if="userInfos.purpleInfo.isMonthly == 1"
              >包月订阅到期时间</span
            >
            <span v-if="userInfos.purpleInfo.isMonthly == 0">订阅到期时间</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, toRefs } from 'vue'
import { getpersonaldata } from '@/api/order'
import { useStore } from 'vuex'
import { convertTimestampToDate } from '@/utils/validate'
const store = useStore()
const datas = reactive({
  userInfos: {}
})
const { userInfos } = toRefs(datas)

onMounted(() => {
  getUserInfo()
})

const getUserInfo = () => {
  getpersonaldata().then(response => {
    const { data: res, status } = response
    if (status == 200) {
      if (res.code == 0) {
        initdata(res.data)
        // userInfo.value = res.data.basicInfo
      }
    }
  })
}

const initdata = data => {
  let userInfo = {
    phonenum: data.basicInfo.phonenum,
    userName: data.basicInfo.userName,
    member: false,
    blue: false,
    orange: false,
    purple: false,
    common: {}
  }
  let createdAt = data.basicInfo.createdAt.split(' ')[0].split('-')
  userInfo.createdAt = `${createdAt[0]}年${createdAt[1]}月${createdAt[2]}日`
  if (data.basicInfo.diamond.includes('member')) {
    userInfo.member = true
    if (data.vipInfo.member) {
      userInfo.common = data.vipInfo.member
    }
  }
  if (data.basicInfo.diamond.includes('blue')) {
    userInfo.blue = true
    if (data.vipInfo.blue) {
      userInfo.common = data.vipInfo.blue
    }
  }
  if (data.basicInfo.diamond.includes('orange')) {
    userInfo.orange = true
    if (data.vipInfo.orange) {
      userInfo.orangeInfo = data.vipInfo.orange
    }
  }
  if (data.basicInfo.diamond.includes('purple')) {
    userInfo.purple = true
    userInfo.purpleInfo = data.vipInfo.memberPurple
  }
  if (userInfo.common.vipExpiratedTime != '无') {
    let time = convertTimestampToDate(userInfo.common.vipExpiratedTime)
    let time1 = time.split(' ')[0].split('-')
    userInfo.common.vipExpiratedTime = `${time1[0].substring(2, 4)}年${
      time1[1]
    }月${time1[2]}日`
  }
  userInfos.value = userInfo
}

// 关闭
const close = () => {
  store.commit('common/setUserInfoMask', false)
}
</script>
<style lang="scss" scoped>
.userInfo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .userInfomain {
    width: 930px;
    height: 715px;
    background: #ffffff;
    box-shadow: 0px 0px 48px 0px rgba(50, 50, 50, 0.03),
      0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 31px 30px 33px 31px;
    box-sizing: border-box;
    position: relative;
    .title {
      color: #3e3f4f;
      font-size: 20px;
      line-height: 23px;
    }
    .close {
      position: absolute;
      top: 31px;
      right: 35px;
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
    .infobox {
      display: flex;
      align-items: center;
      margin: 20px 0 21px 18px;
      .avatar {
        width: 70px;
        height: 70px;
        margin-right: 24px;
      }
      .info {
        .username {
          display: flex;
          align-items: center;
          span {
            font-size: 18px;
            color: #3e3f4f;
          }
          img {
            margin-left: 14px;
          }
          .purpleimg {
            width: 80px;
            height: 26px;
          }
        }
        .other {
          display: flex;
          align-items: center;
          margin-top: 15px;
          img {
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }
          .last {
            margin-left: 25px;
          }
          span {
            color: #3e3f4f;
            font-size: 16px;
          }
        }
      }
    }
    .blue,
    .orange {
      width: 869px;
      height: 144px;
      background: #f9f9f9;
      border-radius: 8px;
      font-size: 0;
      img {
        width: 100%;
        height: 40px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .blueinfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: calc(100% - 40px);
        .item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            color: #3e3f4f;
            font-size: 16px;
            i {
              font-size: 20px;
              font-style: normal;
            }
            &:last-child {
              color: rgba(62, 63, 79, 0.5);
            }
          }
        }
      }
    }
    .blue {
      margin-top: 15px;
    }
    .orange {
      margin-top: 20px;
      .blueinfo {
        .orangebox {
          height: 80%;
          display: flex;
          align-items: center;
          &:first-child {
            flex: 1.2;
          }
          &:last-child {
            flex: 2;
          }
        }
        .orangeInfobox {
          display: flex;
          align-items: center;
          flex: 2;
        }
      }
    }
  }
}
</style>
