<template>
  <div class="share" @click="closeShare">
    <div class="sharemain" @click.stop>
      <div class="desc">
        将此链接分享给朋友，每成功
        <span>注册并登录1人后，</span>
        您与朋友即可<br />同时获得2000tokens额度的<span>橙钻VIP会员</span>体验，直享<span>神算子GPT4.0</span>服务！
        <el-tooltip
          class="box-item"
          effect="customized"
          popper-class="box-item-tip"
          content="如果您当前身份是会员，本福利将为您永久保留，升级VIP身份后自动激活此福利。如果您当前身份已是VIP，可直享此福利。"
          placement="right"
        >
          <el-icon><WarningFilled /></el-icon>
        </el-tooltip>
      </div>
      <div class="sharebox">
        <div class="item">
          <div
            class="content"
            v-html="invitationUrl.replace(' ', '<br />')"
          ></div>
          <el-button type="warning" @click="copy(invitationUrl)">
            复制
          </el-button>
        </div>
        <p class="tip">或者发送<span>邀请码</span>注册时填写</p>
        <div class="item">
          <div class="content code">{{ invitationCode }}</div>
          <el-button type="warning" @click="copy(invitationCode)">
            复制
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { onMounted, reactive, toRefs } from 'vue'
import { shareMsg } from '@/api/common'
import useClipboard from 'vue-clipboard3'
import { useStore } from 'vuex'
const store = useStore()
const datas = reactive({
  invitationUrl: '',
  invitationCode: ''
})
const { invitationCode, invitationUrl } = toRefs(datas)
const { toClipboard } = useClipboard()

onMounted(() => {
  getListsData()
})

// 获取vip数据
const getListsData = () => {
  shareMsg().then(response => {
    const { data: res, status } = response
    if (status == 200) {
      if (res.code == 0) {
        invitationCode.value = res.data[0].invitationCode
        invitationUrl.value = res.data[0].invitationUrl
      } else {
        ElMessage.warning(res.msg)
      }
    }
  })
}

// 关闭弹窗
const closeShare = () => {
  store.commit('common/setShareMask', false)
}
// 复制链接
const copy = async val => {
  try {
    //复制
    if (val.length > 8) {
      let str = val.split(' ')
      await toClipboard(`${str[0]}\n${str[1]}`)
    } else {
      await toClipboard(val)
    }
    ElMessage.success('复制成功')
  } catch (e) {
    //复制失败
    console.error(e)
  }
}
</script>
<style lang="scss" scoped>
.share {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .sharemain {
    width: 600px;
    height: 320px;
    background: #fff;
    padding: 30px 20px;
    border-radius: 8px;
    box-sizing: border-box;
    .desc {
      color: #3e3f4f;
      text-align: center;
      font-size: 16px;
      position: relative;
      span {
        color: #ff7900;
      }
      .el-icon {
        font-size: 20px;
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
      }
    }
    .sharebox {
      margin-top: 40px;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        .content {
          width: 340px;
          height: 40px;
          border: 1px solid #bebebe;
          overflow-y: auto;
          font-size: 14px;
          color: #3e3f4f;
          text-align: center;
          margin-right: 10px;
        }
        .code {
          font-size: 16px;
          font-weight: bold;
          line-height: 34px;
        }
      }
      .tip {
        margin: 20px 0;
        font-size: 16px;
        color: #3e3f4f;
        text-align: center;
        span {
          font-weight: bold;
        }
      }
      :deep(.el-button) {
        height: 40px;
        background: #f87c05;
      }
    }
  }
}
</style>
<style>
.box-item-tip.el-popper.is-customized {
  padding: 6px 12px;
  background-color: #fff0e1;
  color: #f27809;
  width: 290px !important;
}
.box-item-tip.el-popper.is-customized .el-popper__arrow::before {
  background-color: #fff0e1;
  color: #f27809;
  right: 0;
}
</style>
