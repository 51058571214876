<template>
  <div class="help">
    <div class="helpMask" @click.stop>
      <img
        src="../../assets/images/helpclose.png"
        @click="closeSign"
        class="helpclose"
      />
      <div class="help_tab">
        <div :class="active == 2 ? 'active' : ''" @click="tabClick(2)">
          绘图与提示词
        </div>
        <div :class="active == 0 ? 'active' : ''" @click="tabClick(0)">
          聊天与提示词
        </div>
        <div :class="active == 1 ? 'active' : ''" @click="tabClick(1)">
          会员VIP权益对比
        </div>
      </div>
      <div class="help_box">
        <div v-show="active == 0">
          <div class="box">
            <div class="box_title">模型</div>
            <img src="../../assets/images/help1.png" />
            <div class="box_text">
              当您是蓝钻会员时，您可以同时使用3.5与神算一言两种模型<br />
              当您是橙钻会员时，您可以使用所有对话模型。<br />
              当您是紫钻会员时，您将拥有绘图功能。<br />
              您在对话过程中可以随时切换对话模型，让您的问题同时获得多样性的答案！
            </div>
          </div>
          <div class="box">
            <div class="box_title">对话连续模式与断开模式</div>
            <img src="../../assets/images/help2.png" />
            <div class="box_text">在对话框前点击齿轮，可以进行对话设置。</div>
            <img src="../../assets/images/help3.png" class="img3" />
            <div class="box_text">
              上下文：选择“连续”，代表每次提问时我们的神算子AI会结合你们对话上下文进行思考后再回答，更贴近人们平时聊天时的真正情景。选择“断开”则代表每次提问时我们的神算子AI都会当做是第一次对话，仅对当前问题做答。<br />
              连续模式会自动将您与神算子的全部对话重复输入并发送进行整体分析，因此也会消耗更多的时间进行上下文连贯性思考，并附带消耗大量的tokens，断开模式则是一问一答式，因为响应速度更快更节约tokens。<br />
              根据我们的经验，在一些需要AI反复修改雕琢的场合，例如文本润色、程序修改、专业咨询、哲学思辨等适合使用连续模式，而知识性的提问，例如问“人工智能是什么？”、“提供一些急救方法”等使用断开会更合适。
            </div>
          </div>
          <div class="box">
            <div class="box_title">提示语</div>
            <img src="../../assets/images/help4.png" />
            <div class="box_text">
              提示语是获取准确答案的关键要素，我们为您准备了不同类别的提示语模板，都是大量用户经过反复提问和对话，最终得到的最合理、效果最好的提问句精华，当您使用提示语后，直接发送或稍作修改后发送，即可更大概率得到领您满意的答复。您可以在开启新对话或者当前对话过程中随时插入提示语。我们也会不断扩充提示语库，为您和其他用户提供便利。<br /><br />
              如有需寻求更多帮助欢迎与我们及时联系，我们期待您的宝贵意见，祝大家使用愉快！
            </div>
          </div>
        </div>
        <div v-show="active == 1">
          <div class="box table">
            <ul>
              <li v-for="(item, i) in table" :key="i">
                <div>{{ item.name1 }}</div>
                <div>
                  <img
                    v-if="item.name2 == 'd'"
                    src="../../assets/images/duiicon.png"
                  />
                  <template v-else>
                    {{ item.name2 }}
                  </template>
                </div>
                <div>
                  <img
                    v-if="item.name3 == 'd'"
                    src="../../assets/images/duiicon.png"
                  />
                  <template v-else>
                    {{ item.name3 }}
                  </template>
                </div>
                <div>
                  <img
                    v-if="item.name4 == 'd'"
                    src="../../assets/images/duiicon.png"
                  />
                  <template v-else>
                    {{ item.name4 }}
                  </template>
                </div>
                <div>
                  <img
                    v-if="item.name5 == 'd'"
                    src="../../assets/images/duiicon.png"
                  />
                  <template v-else>
                    {{ item.name5 }}
                  </template>
                </div>
                <div>
                  <img
                    v-if="item.name6 == 'd'"
                    src="../../assets/images/duiicon.png"
                  />
                  <template v-else>
                    {{ item.name6 }}
                  </template>
                </div>
              </li>
            </ul>
          </div>
          <div class="box table1">
            <p>除了充值获得VIP权益外，我们还为您提供了多种免费使用的途径：</p>
            <div class="box_title">分享</div>
            <div class="box_text">
              您可以点击模型列表右侧的分享按钮进行分享，只要您的朋友使用您的链接获邀请码，注册并成功登陆一次后，你们都将获得免费的橙钻体验次数！
            </div>
            <div class="share" @click="shareClick">
              <img src="../../assets/images/share.png" />
              <span>点击分享获得免费次数</span>
            </div>
            <div class="box_title">签到</div>
            <div class="box_text">
              您还可以通过每日签到，领取包括4.0 PLUS免费使用在内的各种惊喜奖励!
            </div>
          </div>
        </div>
        <div v-show="active == 2">
          <div class="box drawBox">
            <img src="../../assets/images/drawBg.jpg" />
            <div class="box_text">
              1.一副精美的图像是从完整的绘图提示语开始的，一般提示语应包含主体、风格、特效等描述。<br />
              2.第一次尝试绘图建议每次只绘制一张，确定方向和示例符合预期时再一次绘制多张。<br />
              <!-- 3.基于Midjourney的绘图功能具有绘制高清大图、绘制同类图像、扩大图像范围等高级功能，如遇使用问题可联系我们获取使用指导。 -->
            </div>
            <div class="tipbox tipbox1">
              <p>绘图风格选择</p>
              <i class="line"></i>
              <i class="circle"></i>
            </div>
            <div class="tipbox tipbox2">
              <p>绘图比例与数<br />量选择</p>
              <i class="line"></i>
              <i class="circle"></i>
            </div>
            <div class="tipbox tipbox3">
              <p>绘图历史记录<br />（保存30天）</p>
              <i class="line"></i>
              <i class="circle"></i>
            </div>
            <div class="tipbox tipbox4">
              <p>主绘图区</p>
              <i class="line"></i>
              <i class="circle"></i>
            </div>
            <div class="tipbox tipbox5">
              <p>绘图案例参考</p>
              <i class="line"></i>
              <i class="circle"></i>
            </div>
            <div class="tipbox tipbox6">
              <p>绘图提示语输入</p>
              <i class="line"></i>
              <i class="circle"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
const store = useStore()
const datas = reactive({
  active: 2,
  table: [
    {
      name1: '权益',
      name2: '会员',
      name3: '蓝钻VIP',
      name4: '橙钻VIP',
      name5: '紫钻VIP',
      name6: '游客'
    },
    {
      name1: '对话次数',
      name2: '5',
      name3: '无限',
      name4: '无限',
      name5: '无限',
      name6: '3'
    },
    {
      name1: '绘画保留时长',
      name2: '永久',
      name3: '永久',
      name4: '永久',
      name5: '永久',
      name6: '30天'
    },
    {
      name1: '神算一言',
      name2: 'd',
      name3: 'd',
      name4: 'd',
      name5: '-',
      name6: 'd'
    },
    {
      name1: '3.5 PLUS',
      name2: 'd',
      name3: 'd',
      name4: 'd',
      name5: '-',
      name6: 'd'
    },
    {
      name1: '4.0 PLUS',
      name2: '-',
      name3: '-',
      name4: 'd',
      name5: '-',
      name6: '-'
    },
    {
      name1: '神算绘图',
      name2: '-',
      name3: '-',
      name4: '-',
      name5: 'd',
      name6: '-'
    },
    {
      name1: '对话中绘图',
      name2: '-',
      name3: '-',
      name4: '即将支持',
      name5: '-',
      name6: '-'
    }
  ]
})
const { active, table } = toRefs(datas)
const tabClick = v => {
  active.value = v
}
// 关闭弹窗
const closeSign = () => {
  store.commit('common/setHelpMask', false)
}

// 分享
const shareClick = () => {
  if (localStorage.getItem('token')) {
    store.commit('common/setShareMask', true)
  } else {
    ElMessage.warning('请登录后进行分享')
  }
}
</script>
<style lang="scss" scoped>
.help {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .helpMask {
    width: 1000px;
    // height: 867px;
    background: #fafafa;
    border-radius: 16px;
    position: absolute;
    top: 70px;
    left: 0;
    bottom: 30px;
    right: 0;
    margin: auto;
    box-sizing: border-box;
    .helpclose {
      width: 28px;
      height: 28px;
      position: absolute;
      top: 20px;
      right: 24px;
      z-index: 99;
      cursor: pointer;
    }
    .help_tab {
      width: calc(100% - 58px);
      height: 48px;
      line-height: 48px;
      border-bottom: 1px solid #e7e7e7;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      position: absolute;
      top: 10px;
      left: 24px;
      & > div {
        height: 100%;
        color: #85858f;
        font-size: 16px;
        padding: 0 16px;
        cursor: pointer;
      }
      .active {
        color: #f17807;
        border-bottom: 1px solid #f17807;
      }
    }
    .help_box {
      position: absolute;
      top: 68px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      overflow-y: auto;
      padding: 0 24px 29px;
      .box {
        background: #ffffff;
        border-radius: 16px;
        padding: 23px;
        box-sizing: border-box;
        margin-bottom: 21px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .box_title {
        font-size: 16px;
        color: #3e3f4f;
        margin-bottom: 13px;
        font-weight: bold;
      }
      img {
        margin-bottom: 13px;
      }
      .box_text {
        font-size: 14px;
        color: #85858f;
        line-height: 20px;
      }
      .img3 {
        margin-top: 25px;
        margin-bottom: 5px;
      }
      .table {
        padding: 10px 22px 23px 25px;
        box-sizing: border-box;
        ul {
          li {
            display: flex;
            &:nth-child(2),
            &:nth-child(3) {
              div {
                &:nth-child(2) {
                  color: #664539;
                }
                &:nth-child(3) {
                  color: #0676e8;
                }
                &:nth-child(4) {
                  color: #ea7619;
                }
                &:nth-child(5) {
                  color: #9d50ff;
                }
              }
            }
            &:last-child {
              div {
                &:nth-child(4) {
                  color: #ea7619;
                }
              }
            }
            &:nth-child(even) {
              background: #fbfbfb;
            }
            &:first-child {
              border-bottom: 1px solid #e8e8e9;
              div {
                font-size: 16px;
                &:nth-child(1) {
                  color: rgba(24, 28, 34, 0.43);
                }
                &:nth-child(2) {
                  background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    #fefbf1 100%
                  );
                }
                &:nth-child(3) {
                  color: #218fff;
                  background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(33, 143, 255, 0.08) 100%
                  );
                }
                &:nth-child(4) {
                  color: #ea7619;
                  background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(234, 118, 25, 0.1) 100%
                  );
                }
                &:nth-child(5) {
                  color: #9d50ff;
                  background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(157, 80, 255, 0.15) 100%
                  );
                }
                &:nth-child(6) {
                  background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    #f0f0f0 100%
                  );
                }
              }
            }
            div {
              width: 151px;
              height: 60px;
              text-align: center;
              line-height: 60px;
              color: #181c22;
              font-size: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .table1 {
        padding-right: 10px;
        p {
          color: #85858f;
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 16px;
        }
        .box_text {
          color: #3e3f4f;
        }
      }
      .share {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 20px;
        margin-top: 8px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          margin-bottom: 0;
        }
        span {
          font-size: 14px;
          color: #f87c05;
          text-decoration: underline;
        }
      }
    }
  }
  .drawBox {
    position: relative;
    img {
      width: 80%;
      display: block;
      margin: 0 auto;
      transform: translateX(20px);
    }
    .tipbox {
      width: 100px;
      height: 60px;
      position: absolute;
      background: #ffffff
        linear-gradient(
          180deg,
          rgba(255, 121, 0, 0.56) 0%,
          rgba(242, 120, 9, 0) 29%
        );
      border-radius: 5px;
      border: 1px solid #feddbf;
      p {
        font-size: 12px;
        color: #333;
        padding-top: 10px;
        padding-left: 5px;
      }
      .line {
        position: absolute;
        top: 10px;
        right: -20px;
        width: 20px;
        height: 2px;
        background: #ffa754;
      }
      .circle {
        width: 6px;
        height: 6px;
        background: #ffa754;
        border-radius: 100%;
        position: absolute;
        top: 8px;
        right: -21px;
      }
    }
    .tipbox1 {
      top: 74px;
      left: 20px;
      .line {
        top: 10px;
        right: -20px;
      }
      .circle {
        top: 8px;
        right: -21px;
      }
    }
    .tipbox2 {
      top: 244px;
      left: 20px;
      .line {
        top: 10px;
        right: -20px;
      }
      .circle {
        top: 8px;
        right: -21px;
      }
    }
    .tipbox3 {
      top: 324px;
      left: 20px;
      .line {
        top: 10px;
        right: -20px;
      }
      .circle {
        top: 8px;
        right: -21px;
      }
    }

    .tipbox4 {
      top: 120px;
      right: -20px;
      .line {
        width: 200px;
        top: 10px;
        left: -200px;
      }
      .circle {
        top: 8px;
        left: -200px;
      }
    }
    .tipbox5 {
      top: 348px;
      right: -20px;
      .line {
        width: 30px;
        top: 10px;
        left: -30px;
      }
      .circle {
        top: 8px;
        left: -30px;
      }
    }
    .tipbox6 {
      top: 420px;
      right: 0;
      left: 420px;
      margin: auto;
      .line {
        width: 2px;
        height: 55px;
        top: -55px;
        left: 0;
        right: 0;
        margin: auto;
        // transform: rotate(90deg);
      }
      .circle {
        top: -60px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}
</style>
