<!-- eslint-disable no-undef -->
<!-- 会话列表 -->
<template>
  <div class="silder">
    <div class="chat_item" @click="handleClickAddChatItem()">
      <el-icon class="chat_item_icon">
        <Plus/>
      </el-icon>
      <span class="chat_item_title">开启聊天</span>
    </div>
    <div class="silderHeight">
      <div>
        <div v-for="(item, index) in filteredChatGroups" :key="index">
          <div
            v-for="(value, key, index) in filteredChatGroups[index]"
            :key="index"
          >
            <span v-if="key === 'today'" class="silderLabel">今天</span>
            <span v-if="key === 'before7'" class="silderLabel">近七天</span>
            <span v-if="key === 'before30'" class="silderLabel">近三十天</span>
            <span v-if="key.length === 2" class="silderLabel">{{ month(key) }}</span>
            <span v-if="key.indexOf('-') !== -1" class="silderLabel">{{ year(key) }}</span>
            <div class="chat_item_all">
              <div
                v-for="(item, i) in chatList[key]"
                :key="i"
                :class="item.id === chatId&&!(i === 0 && key === 'today') ? 'active' : ''"
                class="chat_item"
                @click="handleClickChatItem(item, i, key)"
              >
                <span v-if="!item.edit" class="chat_item_title" style="margin-left: 24px">
                  {{ item.title }}
                </span>
                <el-input
                  v-if="item.edit"
                  ref="titleInput"
                  v-model="item.title"
                  style="margin-left: 24px"
                  @keyup.stop="handleKeyupEditTitle($event, i, key)"
                  @blur.stop="handleBlurEditTitle(item, i, key)"
                ></el-input>
                <span v-if="i === 0 && key === 'today'" class="tips"> 最近 </span>
                <div v-if="routeDefault" :class="item.id === chatId&&!(i === 0 && key === 'today') ? 'active' : ''"
                     class="icons">
                  <el-icon class="edit" @click.stop="handleClickEdit(item, key)">
                    <Edit/>
                  </el-icon>
                  <el-icon class="delete">
                    <Delete @click.stop="handleClickDelete(item, i)"/>
                  </el-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref} from 'vue'
import {ElMessage} from 'element-plus'
import {clearStorage} from '@/utils/common'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {deleteChat, updateChatName} from '@/api/common'

const store = useStore()

const router = useRouter()

const routeDefault = computed(() => store.state.common.routeDefault)
const titleInput = ref()

const chatList = computed(() => store.state.chat.chatList)
const chatId = computed(() => store.state.chat.chatId)
const loading = computed(() => store.state.common.loading)
const modelCode = computed(() => store.state.common.modelCode)
onMounted(() => {
  if (localStorage.getItem('uname') && localStorage.getItem('token')) {
    store.commit('common/setUserName', localStorage.getItem('uname'))
  }
})

const filteredChatGroups = computed(() => {
  const allChats = []
  // const filters = [
  //   'before7',
  //   'before30',
  //   'today',
  //   '09',
  //   '10',
  //   '2021-10',
  //   '2022-09',
  //   '2021-08'
  // ]
  const filters = []
  for (const key in chatList.value) {
    if (chatList.value.hasOwnProperty.call(chatList.value, key)) {
      filters.push(key)
    }
  }
  for (const key of ['today', 'before7', 'before30']) {
    if (filters.includes(key)) {
      allChats.push({
        [key]: chatList.value[key]
      })
    }
  }

  for (const key of filters
    .filter(filter => filter.match(/^\d{2}$/))
    .sort()
    .reverse()) {
    allChats.push({
      [key]: chatList.value[key]
    })
  }
  // for (const key of filters
  //   .filter(filter => filter.match(/^\d{2}$/) || filter.match(/^\d{4}-\d{2}$/))
  //   .sort()
  //   .reverse()) {
  //   allChats.push({
  //     [key]: chatList.value[key]
  //   })
  // }
  for (const key of filters
    .filter(filter => filter.match(/^\d{4}-\d{2}$/))
    .sort()
    .reverse()) {
    allChats.push({
      [key]: chatList.value[key]
    })
  }
  return allChats
})
// 初始化月份
const month = key => {
  const month = key.split('')
  if (month[0] == 0) {
    return `${month[1]}月`
  } else {
    return `${key}月`
  }
}

// 初始化年份
const year = key => {
  const year = key.split('-')
  return `${year[0]}年${year[1]}月`
}

// 登录显示弹窗
const loginSubmit = () => {
  store.commit('common/setLoginMask', true)
}

// 点击新增newchat
const handleClickAddChatItem = () => {
  if (loading.value) {
    return false
  }
  store.commit('common/setVipsMask', false)
  store.commit('common/setServiceMask', false)
  if (modelCode.value === 'sszdraw-v1' || modelCode.value === 'mj') {
    router.push('/draw')
    store.commit('draw/setDrawExpert', true)
    store.commit('common/setInsertBtn', false)
    return false
  } else {
    router.push('/chat')
  }
  store.commit('common/setClickTrue', true)
  store.commit('common/setExpertMask', true)
  store.commit('common/setInsertBtn', false)
}

// 点击切换标题
const handleClickChatItem = (item, index, key) => {
  if (loading.value) {
    return false
  }
  let path = router.currentRoute.value.fullPath
  if (item.type === 'chat') {
    store.commit('chat/setChatId', item.id)
    store.commit('chat/setChatListCurrent', index)
    store.commit('chat/setChatKey', key)
    if (path === '/chat') {
      store.dispatch('chat/getListDataChat')
    } else {
      sessionStorage.setItem('slider', 'chat')
      router.push('/chat')
    }
  }
  if (item.type === 'draw') {
    store.commit('chat/setChatId', item.id)
    store.commit('chat/setChatListCurrent', index)
    store.commit('chat/setChatKey', key)
    store.commit('common/setMjHistoryList', [])
    store.commit('common/setHistoryLogin', [])
    store.commit('common/setHistoryLoginTotal', [])
    store.commit('chat/setModelAndSilde', true)
    setTimeout(() => {
      store.commit('chat/setModelAndSilde', false)
    }, 50)
    if (path === '/draw') {
      store.dispatch('chat/getListDataDraw')
    } else {
      sessionStorage.setItem('slider', 'draw')
      router.push('/draw')
    }
  }
}

// 删除标题
const handleClickDelete = (el, index) => {
  if (loading.value) {
    return false
  }
  const data = {
    id: el.id,
    type: el.type
  }
  deleteChat(data).then(response => {
    const {data: res} = response
    if (res.code === 0) {
      store.dispatch('chat/getListData', 0)
      store.commit('chat/setChatListCurrent', 0)
      store.commit('chat/setReloadWeb', false)
      store.commit('chat/setChatValue', '')
    }
  })
}
// 编辑标题
const handleClickEdit = item => {
  if (loading.value) {
    return false
  }
  item.edit = true
  nextTick(() => {
    titleInput.value[0].focus()
  })
}

// 标题编辑状态下 光标消失编辑完成
const handleBlurEditTitle = (item, i, key) => {
  if (loading.value) {
    return false
  }
  item.edit = false
  const data = {
    chatId: item.id,
    chatName: item.title,
    type: item.type
  }
  updateChatName(data)
    .then(response => {
      const {data: res, status} = response
      if (status == 200) {
        if (res.code == 0) {
          store.commit('chat/setChatListCurrent', i)
        }
        handleClickChatItem(item, i, key)
      }
      if (status == 202) {
        if (res.code == 1) {
          ElMessage.warning('验证失败，请重新登录或完成注册')
          store.commit('chat/setChatValue', '')
          clearStorage()
          setTimeout(() => {
            loginSubmit()
          }, 3000)
        } else {
          if (res.code == 3) {
            store.commit('common/setVipsMask', true)
          }
          if (res.code == 0) {
            ElMessage.warning(res.msg)
          }
        }
      }
    })
    .catch(() => {
    })
}

// 按下回车编辑标题
const handleKeyupEditTitle = (e, i, key) => {
  if (e.keyCode == 13) {
    chatList.value[key][i].edit = false
  }
}
</script>
<style lang="scss" scoped>
.silder {
  padding: 12px 0 0 26px;
  width: 100%;
  height: calc(100% - 315px);
  overflow: hidden;
  box-sizing: border-box;
  top: 80px;
  left: 0;
  bottom: 150px;
  z-index: 10;

  .silderbox {
    overflow: hidden;
    overflow-y: auto;
    height: calc(100% - 50px);
  }
}

.silderHeight {
  overflow: hidden;
  height: calc(100% - 50px);

  & > div {
    width: 104%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
}

.silderLabel {
  width: 147px;
  display: block;
  line-height: 40px;
  color: rgba(142, 142, 160);
  text-align: center;
  font-size: 14px;
}

:deep(.chat_item) {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  margin-bottom: 10px;
  width: 147px;
  height: 36px;
  border-radius: 3px;
  border: 1px solid #ff7900;
  box-sizing: border-box;


  &:last-child {
    margin-bottom: 0;
  }

  .el-icon {
    margin-right: 10px;
  }

  .chat_item_icon {
    margin-left: 24px;
    color: #ff7900;
  }

  .tips {
    position: absolute;
    right: 10px;
    font-size: 10px;
    padding: 0 5px;
    border: 1px solid #ff4a4a;
    color: #ff4a4a;
    border-radius: 5px;
  }

  .icons {
    position: absolute;
    background: #272732;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;
  }

  // .edit {
  //   position: absolute;
  //   right: 30px;
  //   top: 0;
  //   bottom: 0;
  // }
  // .delete {
  //   position: absolute;
  //   right: 10px;
  //   top: 0;
  //   bottom: 0;
  //   margin: auto;
  // }
  &:hover {
    // background-color: rgba(141, 141, 160, 0.1);
    .icons {
      display: flex;
    }
  }

  .chat_item_title {
    width: 110px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #ff7a04;
  }

  .el-input {
    width: 110px;

    .el-input__wrapper {
      background: transparent !important;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
    }

    .el-input__inner {
      height: 20px;
      line-height: 20px;
      border: none;
      color: #fff;
    }
  }

  .active, &.active {
    background: #FF7900;

    span {
      color: #282833;
    }
  }
}
</style>
