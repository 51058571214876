<template>
  <div class="forgetPsd">
    <h6>忘记密码</h6>
    <el-steps :active="active" finish-status="success" align-center>
      <el-step title="第一步" />
      <el-step title="第二步" />
    </el-steps>
    <el-form
      label-width="110px"
      ref="firstFormRef"
      :model="firstForm"
      :rules="firstRules"
      v-if="active == 0"
    >
      <el-form-item label="手机号：" prop="phone">
        <el-input maxlength="11" v-model="firstForm.phone" />
      </el-form-item>
      <el-form-item label="图形验证码：" prop="code">
        <el-input v-model="firstForm.code" maxlength="4" />
        <canvas
          id="canvas"
          class="code_img"
          width="96px"
          height="44px"
          @click="handleCanvas"
        ></canvas>
      </el-form-item>
      <el-form-item label="短信验证码：" prop="noteCode">
        <el-input
          v-model="firstForm.noteCode"
          @keyup="numberInput"
          maxlength="6"
        />
        <el-button
          :disabled="!codeDisabled || yzmCodeLabel != '获取验证码'"
          type="primary"
          class="getCode"
          @click="getCode"
        >
          {{ yzmCodeLabel }}
        </el-button>
      </el-form-item>
      <el-button class="nextbtn" @click="submitForm"> 下一步 </el-button>
    </el-form>
    <el-form
      label-width="100px"
      ref="secondFormRef"
      :model="secondForm"
      :rules="secondRules"
      v-if="active == 1"
    >
      <el-form-item label="用户名：" prop="username">
        <el-input v-model="secondForm.username" clearable />
      </el-form-item>
      <el-form-item label="密码：" prop="psd">
        <el-input
          :type="!psdShow ? 'password' : 'text'"
          v-model="secondForm.psd"
          clearable
          placeholder="密码8-16位，至少需包含英文、数字"
        />
        <el-icon class="input_icon" v-if="!psdShow" @click="handlePsdShow">
          <View />
        </el-icon>
        <el-icon class="input_icon" v-else @click="handlePsdShow">
          <Hide />
        </el-icon>
      </el-form-item>
      <el-form-item label="确认密码：" prop="psdok">
        <el-input
          :type="!psdShow1 ? 'password' : 'text'"
          v-model="secondForm.psdok"
          clearable
          placeholder="密码8-16位，至少需包含英文、数字"
        />
        <el-icon class="input_icon" v-if="!psdShow1" @click="handlePsdShow1">
          <View />
        </el-icon>
        <el-icon class="input_icon" v-else @click="handlePsdShow1">
          <Hide />
        </el-icon>
      </el-form-item>
      <el-button type="primary" class="nextbtn" @click="submit">
        确认提交
      </el-button>
    </el-form>
    <Filings />
  </div>
</template>

<script setup>
import md5 from 'js-md5'
import { reactive, toRefs, ref, onMounted, computed } from 'vue'
import { validatePhoneNumber } from '@/utils/validate'
import { ElMessage } from 'element-plus'
import { sendForgetMsg, verifyForgetMsg, saveUserInfo } from '@/api/common'
import { useRouter } from 'vue-router'
import Filings from '@/components/Filings'
import { draw } from '@/utils/code'
const firstFormRef = ref()
const secondFormRef = ref()
const router = useRouter()
const initData = reactive({
  yzmCodeLabel: '获取验证码',
  active: 0,
  psdShow: false,
  psdShow1: false,
  firstForm: {
    phone: '',
    code: '',
    noteCode: ''
  },
  secondForm: {
    username: '',
    psd: '',
    psdok: ''
  },
  userId: '',
  true_code: '',
  yanzheng_arr: [],
  codeFlag: false
})

const {
  active,
  firstForm,
  secondForm,
  psdShow,
  psdShow1,
  yzmCodeLabel,
  true_code,
  yanzheng_arr,
  userId,
  codeFlag
} = toRefs(initData)

onMounted(() => {
  draw(yanzheng_arr.value, true_code)
})
let timer = null
// 只能输入数字
const numberInput = () => {
  firstForm.value.noteCode = firstForm.value.noteCode.replace(/[^\d]/g, '')
}

// 手机号验证
const validatePhone = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else if (!validatePhoneNumber(value)) {
    callback(new Error('请输入正确手机号'))
  } else {
    callback()
  }
}

// 图形验证码
const validateCode = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入图形验证码'))
  } else if (firstForm.value.code.toLowerCase() != true_code.value) {
    callback(new Error('请输入正确图形验证码'))
    setTimeout(() => {
      handleCanvas()
    }, 500)
  } else {
    callback()
  }
}
// const reg =
//   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*,'.])[\da-zA-Z~!@#$%^&*,'.]{8,16}$/
const reg = /^(?=.*[a-zA-Z])(?=.*\d).{8,16}$/
// 密码
const validatePsd = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入密码'))
  } else if (!reg.test(value)) {
    callback(new Error('密码8-16位，至少需包含英文、数字'))
  } else {
    callback()
  }
}

// 确认密码
const validatePsdok = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value != secondForm.value.psd) {
    callback(new Error('两次密码不一致'))
  } else {
    callback()
  }
}

const firstRules = reactive({
  phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
  code: [{ required: true, validator: validateCode, trigger: 'blur' }],
  noteCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }]
})

const secondRules = reactive({
  username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
  psd: [{ required: true, validator: validatePsd, trigger: 'blur' }],
  psdok: [{ required: true, validator: validatePsdok, trigger: 'blur' }]
})

// 下一步
const submitForm = () => {
  if (!firstFormRef.value) return
  firstFormRef.value.validate(valid => {
    if (valid) {
      verifyForgetMsg({
        phone: firstForm.value.phone,
        code: firstForm.value.noteCode
      })
        .then(({ data: res, status }) => {
          if (status == 200) {
            if (res.code == 1) {
              if (active.value++ > 1) {
                active.value = 0
              }
              userId.value = res.data.userId
            } else {
              ElMessage.warning(res.msg)
            }
          }
          if (status == 202) {
            ElMessage.warning(res.msg)
            if (res.code == 2) {
              localStorage.setItem(
                'forgetpsd',
                JSON.stringify({
                  step: 1,
                  id: res.data.userId
                })
              )
              setTimeout(() => {
                router.push('/register')
              }, 1000)
            }
          }
        })
        .catch(() => {})
    } else {
      return false
    }
  })
}

// 提交
const submit = () => {
  if (!secondFormRef.value) return
  secondFormRef.value.validate(valid => {
    if (valid) {
      saveUserInfo({
        userName: secondForm.value.username,
        pwd: md5(secondForm.value.psd),
        userId: userId.value
      })
        .then(({ data: res }) => {
          if (res.code == 1) {
            ElMessage.success(res.msg)
            setTimeout(() => {
              router.push({
                path: '/'
              })
              localStorage.setItem('login', true)
            }, 1500)
          } else {
            ElMessage.warning(res.msg)
          }
        })
        .catch(() => {})
    } else {
      return false
    }
  })
}

// 查看密码
const handlePsdShow = () => {
  psdShow.value = !psdShow.value
}

// 查看确认密码
const handlePsdShow1 = () => {
  psdShow1.value = !psdShow1.value
}

// 获取验证码按钮是否禁用
const codeDisabled = computed(() => {
  return (
    firstForm.value.phone != '' &&
    validatePhoneNumber(firstForm.value.phone) &&
    firstForm.value.code.toLowerCase() == true_code.value
  )
})

// 获取短信验证码
const getCode = () => {
  if (codeFlag.value) {
    return false
  }
  codeFlag.value = true
  if (yzmCodeLabel.value == '获取验证码') {
    countDown()
    sendForgetMsg({
      phone: firstForm.value.phone
    })
      .then(response => {
        const { data: res, status } = response
        setTimeout(() => {
          codeFlag.value = false
        }, 1000)
        if (status == 200) {
          if (res.code == 0) {
            ElMessage.success(res.msg)
            userId.value = res.data.userId
          }
        }
        if (status == 202) {
          if (res.code == 2) {
            ElMessage.warning(res.msg)
            setTimeout(() => {
              router.push({
                path: '/Register'
              })
              localStorage.setItem(
                'forgetpsd',
                JSON.stringify({
                  step: 1,
                  id: res.data.userId
                })
              )
            }, 1500)
          } else {
            clearInterval(timer)
            yzmCodeLabel.value = '获取验证码'
            ElMessage.warning(res.msg)
          }
        }
      })
      .catch(() => {
        clearInterval(timer)
        yzmCodeLabel.value = '获取验证码'
      })
  }
}

// 验证码倒计时
const countDown = () => {
  let second = 60
  timer = setInterval(() => {
    second--
    if (second > 0) {
      yzmCodeLabel.value = `${second}s后重新发送`
    } else {
      clearInterval(timer)
      yzmCodeLabel.value = '获取验证码'
    }
  }, 1000)
}

//canvas点击刷新
const handleCanvas = () => {
  firstForm.code = ''
  draw(yanzheng_arr.value, true_code)
}
</script>
<style lang="scss" scoped>
.forgetPsd {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h6 {
    font-size: 30px;
    color: #4db2ff;
    margin-bottom: 50px;
  }
  .el-form {
    margin-top: 50px;
    margin-left: -50px;
  }
  .el-steps,
  .el-form {
    width: 500px;
  }
  :deep(.el-form-item) {
    align-items: center;
    .el-input {
      height: 40px;
      position: relative;
    }
    .input_icon {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 10;
      color: #333;
      font-size: 16px;
      cursor: pointer;
    }
    .getCode,
    .code_img {
      height: 38px;
      position: absolute;
      right: 0;
      top: 1px;
    }
    .code_img {
      right: 1px;
    }
  }
  .code_img {
    width: 100px;
    height: 40px;
  }
  .nextbtn {
    display: flex;
    margin: 20px auto;
    transform: translateX(40px);
  }
}
</style>
