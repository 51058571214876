<template>
  <div class="chat_main">
    <div class="tabbox">
      <div id="driver1" class="driver1"></div>
      <div id="driver2" class="driver2"></div>
      <div id="tabbox" class="tab">
        <img class="tabicon" src="../assets/images/tab_icon1.jpg"/>
        <div v-for="(item, i) in tabList" :key="i"
             :class="[modelCode === item.value ? 'active' : '',userName === '' &&(item.value === 'gpt3.5' ||item.value === 'gpt4.0' ||item.value === 'mj')? 'notoken': '']"
             :style="{ 'margin-left': !item.tip && i === 3 ? '60px' : '' }"
             class="tabItem"
             @click="handleClickTabItem(item)"
        >
          <template v-if="item.value !=='mj'">
            <el-tooltip
              v-if="item.value !== 'sszdraw-v1' && item.value !== 'mj'"
              :content="userName !== '' ? item.tip : item.notoken"
              class="box-item"
              effect="customized"
              placement="bottom"
            >
              <span>{{ item.title }}</span>
            </el-tooltip>
            <span v-else>{{ item.title }}</span>
          </template>
          <template v-if="item.value ==='mj'">
            <el-tooltip
              v-if="userName === ''"
              :content="item.notoken"
              class="box-item"
              effect="customized"
              placement="bottom"
            >
              <span>{{ item.title }}</span>
            </el-tooltip>
            <span v-else>{{ item.title }}</span>
          </template>
        </div>
        <img class="tabicon tabicon1 tabicon2" src="../assets/images/tab_icon2.jpg"/>
      </div>
      <div v-if="userName !== ''" class="share" @click="shareClick">
        <img src="../assets/images/share.png"/>
        <span>点击分享获得免费次数</span>
      </div>
    </div>
    <div ref="main" class="main" id="main">
      <!-- 聊天内容 -->
      <Main/>
      <!-- 提示词 -->
      <Dialogue
        v-if="chatList[chatKey] && chatList[chatKey].length > 0 && chatList[chatKey][chatListCurrent] && chatList[chatKey][chatListCurrent].dialogueShow"
        @tipmsg="tipmsg"/>
    </div>
    <div class="operate">
      <img class="setting" src="../assets/images/setting.png" @click="setting"/>
      <el-input
        ref="chatValueRef"
        v-model="chatValue"
        :disabled="btn"
        :placeholder="placeholder"
        clearable
        @keyup="handleKeyupSend"
      />
      <el-button :disabled="btn" type="primary" @click="handleClickSend">
        开始聊天
      </el-button>
      <div :disabled="btn" class="inserttips" @click="insertTips">插入提示语</div>
      <div id="driver3" class="driver3"></div>
    </div>
    <!-- 会话提示信息 -->
    <TipsMask v-if="tipsMask"/>
    <div class="tokenuse">
      <div v-for="(value, key, index) in useToken" :key="index" class="item">
        <span v-if="key === 'blue'" class="type blue">蓝钻VIP</span>
        <span v-if="key === 'orange'" class="type orange">橙钻VIP</span>
        <span v-if="key === 'purple'" class="type purple">紫钻VIP</span>
        <span v-if="key === 'purple'" class="token">绘图用量</span>
        <span v-if="key !== 'purple'" class="token">tokens用量</span>
        <div class="speed">
          <div v-for="(item, i) in useToken[key]" :key="i" class="el">
            <span v-if="item.token" class="tips">{{ item.token }}</span>
            <span v-if="!item.token" class="tips">∞</span>
            <div :style="{ width: 100 - item.remainderPercent + '%' }" class="use"></div>
            <img v-if="key === 'blue' && item.remainderPercent !== 100 && item.remainderPercent !== 0"
                 :style="{ left: initleft(item.remainderPercent) }" class="hot" src="../assets/images/hot.gif"/>
            <img v-if="key === 'orange'" :style="{ left: initleft(item.remainderPercent) }" class="hot"
                 src="../assets/images/hot.gif"/>
            <img v-if="key === 'purple' && typeof item.remainderPercent === Number"
                 :style="{ left: initleft(item.remainderPercent) }" class="hot" src="../assets/images/hot.gif"/>
            <div class="info">
              <div class="productName">{{ item.productName }}</div>
              <div class="lifespan">有效期：{{ item.lifespan }}</div>
              <div v-if="key !== 'purple'" class="tokens">
                已用tokens：{{ item.token }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {driver} from 'driver.js'
import 'driver.js/dist/driver.css'
import {computed, nextTick, onMounted, reactive, ref, toRefs, watch} from 'vue'
import {ElMessage} from 'element-plus'
import Main from '@/components/Main'
import Dialogue from '@/components/Dialogue'
import TipsMask from '@/components/TipsMask'
import {getpersonalused} from '@/api/order'
import {clearStorage} from '@/utils/common'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {initiatetask, updateChatName} from '@/api/common'
import {getChatSSE} from "@/utils/chatSse";

const store = useStore()

const router = useRouter()
const main = ref()
const chatValueRef = ref()
const initData = reactive({
  btn: false,
  showText: false,
  promptShow: true,
  ask: '',
  keyword: '',
  timer: null,
  promptVal: '',
  tabList: [
    {
      title: '神算子 GPT3.5',
      tip: '智能、广泛',
      value: 'gpt3.5',
      notoken: '登录后可用'
    },
    {
      title: '神算子 GPT4.0',
      tip: '超智能、快',
      value: 'gpt4.0',
      notoken: '登录后可用'
    },
    {
      title: '神算智言',
      tip: '文案无敌、快',
      value: 'yan',
      notoken: '文案无敌、快'
    },
    {
      title: '神算子绘图',
      tip: '',
      value: 'sszdraw-v1'
      // notoken: '登录后可用'
    },
    // {
    //   title: 'Midjourney',
    //   tip: '',
    //   value: 'mj',
    //   notoken: '登录后可用'
    // }
  ],
  localStorageTip: '',
  modelCodeVal: '',
  previousTab: '',
  firstflag: false,
})
const {
  btn,
  showText,
  ask,
  keyword,
  timer,
  promptVal,
  promptShow,
  tabList,
  localStorageTip,
  modelCodeVal,
  previousTab,
  firstflag,
} = toRefs(initData)

const chatList = computed(() => store.state.chat.chatList)
const chatKey = computed(() => store.state.chat.chatKey)
const chatListCurrent = computed(() => store.state.chat.chatListCurrent)
const clickTrue = computed(() => store.state.common.clickTrue)
const promptsId = computed(() => store.state.common.promptsIdChange)
const chatId = computed(() => store.state.chat.chatId)
const placeholder = computed(() => store.state.common.placeholder)
const userName = computed(() => store.state.common.userName)
const loading = computed(() => store.state.common.loading)
const useToken = computed(() => store.state.common.useToken)
const useTokenHeight = computed(() => store.state.common.useTokenHeight)
const tipsMask = computed(() => store.state.common.tipsMask)
const modelCode = computed(() => store.state.common.modelCode)
const remainingPaper = computed(() => store.state.common.remainingPaper)
const signMask = computed(() => store.state.common.signMask)
const loginMask = computed(() => store.state.common.loginMask)
const sureSendWayState = computed(() => store.state.common.sureSendWayState)
const chatValue = computed({
  get() {
    return store.state.chat.chatValue
  },
  set(val) {
    store.commit('chat/setChatValue', val)
  }
})


onMounted(async () => {
  await store.dispatch('chat/getListData')
  if (localStorage.getItem('useToken')) {
    store.commit('common/setUseToken', JSON.parse(localStorage.getItem('useToken')))
  }
  nextTick(() => {
    main.value.scrollTop = main.value.scrollHeight
  })
  if (localStorage.getItem('uname') && localStorage.getItem('token')) {
    store.commit('common/setUserName', localStorage.getItem('uname'))
    await getpersonalusedlist()
  }
  if (sessionStorage.getItem('sign') === 'false' && !loginMask.value) {
    if (!localStorage.getItem('guide')) {
      driverBox()
    }
  }
})

watch(sureSendWayState, newv => {
  if (!newv) {
    getpersonalusedlist()
  }
}, {deep: true})
watch(signMask, newv => {
  if (!newv && !localStorage.getItem('guide')) {
    driverBox()
  }
}, {deep: true})

watch(loginMask, newv => {
  if (!newv && !localStorage.getItem('guide')) {
    driverBox()
  }
}, {deep: true})

// watch(sureSendWayState, newv => {
//   if (newv) {
//     nextTick(() => {
//       const list = chatList.value[chatKey.value][chatListCurrent.value].list
//       sureSendWay(list[list.length-1].content, list.length - 1)
//     })
//   }
// }, {deep: true})

// 引导页
const driverBox = () => {
  const driverObj = driver({
    onNextClick: () => {
      driverObj.moveNext()
      if (driverObj.hasNextStep() === false) {
        localStorage.setItem('guide', true)
      }
    },
    nextBtnText: '知道了，谢谢',
    doneBtnText: '知道了，谢谢',
    allowClose: false,
    steps: [
      {
        element: '#driver1',
        popover: {
          title: '对话模型选择区',
          description: '选择任意一种模型开始对话',
          side: 'bottom',
          align: 'center'
        }
      },
      {
        element: '#driver2',
        popover: {
          title: '绘图模型选择区',
          description: '选择任意一种模型开始绘图',
          side: 'bottom',
          align: 'center'
        }
      },
      {
        element: '#driver3',
        popover: {
          title: '辅助提示语',
          description: '可插入提示语参考案例',
          side: 'top',
          align: 'center'
        }
      }
    ]
  })
  driverObj.drive()
}

const initleft = remainderPercent => {
  let length = 100 - remainderPercent
  return `calc(${length}% - 7.5px)`
}

// 分享
const shareClick = () => {
  store.commit('common/setShareMask', true)
}

// 点击使用提示语
const tipmsg = chatObj => {
  store.commit('common/setClickTrue', false)
  if (chatObj === '') {
    store.commit('common/setPromptsIdChange', 0)
    promptVal.value = ''
    if (chatValue.value.indexOf(localStorageTip.value) !== -1) {
      const text = chatValue.value.replace(localStorageTip.value, '')
      localStorageTip.value = ''
      store.commit('chat/setChatValue', text)
    } else {
      store.commit('chat/setChatValue', '')
    }
    return false
  }
  store.commit('common/setPromptsIdChange', chatObj.id)
  promptShow.value = !chatObj.enShow
  if (!chatObj.enShow) {
    promptVal.value = chatObj.prompt
  } else {
    promptVal.value = chatObj.prompt_en
  }
  if (promptVal.value.indexOf('[') !== -1) {
    let index = promptVal.value.indexOf('[')
    promptVal.value = promptVal.value.substr(0, index)
  }
  localStorageTip.value = promptVal.value
  store.commit('chat/setChatValue', promptVal.value + chatValue.value)
  chatValueRef.value.focus()
}

watch(clickTrue, newv => {
  if (newv) {
    store.commit('common/setPromptsIdChange', 0)
    promptVal.value = ''
  }
})

watch(useToken, nev => {
  nextTick(() => {
    store.commit('common/setUseTokenHeight', document.getElementsByClassName('tokenuse')[0].clientHeight)
    if (useTokenHeight.value > 0) {
      document.getElementsByClassName('operate')[0].style.bottom = useTokenHeight.value + 20 + 'px'
      document.getElementsByClassName('main')[0].style.bottom = useTokenHeight.value + 65 + 'px'
    }
  })
})

watch(userName, nev => {
  if (nev === '') {
    nextTick(() => {
      if (useTokenHeight.value === 0) {
        document.getElementsByClassName('operate')[0].style.bottom = '0px'
        document.getElementsByClassName('main')[0].style.bottom = '50px'
      }
    })
  }
})

// 插入提示语
const insertTips = () => {
  store.commit('common/setClickTrue', true)
  store.commit('common/setVipsMask', false)
  store.commit('common/setServiceMask', false)
  store.commit('common/setExpertMask', true)
  store.commit('common/setInsertBtn', true)
}

// 会话设置
const setting = () => {
  store.commit('common/setSessionMask', true)
}

// 开始
const sureSendWay = (str, index) => {
  showText.value = false
  ask.value = keyword.value
  let divTyping = document.getElementById(`content${index}`)
  let i = 0

  // 插入回答数据的打字效果
  function typing() {
    if (i <= str.length) {
      divTyping.innerHTML = '<span>' + str.slice(0, i++) + '</span>'
      timer.value = setTimeout(() => {
        typing()
      }, 50)
      main.value.scrollTop = main.value.scrollHeight
      if (i > str.length) {
        clearTimeout(timer.value)
        timer.value = null
        store.commit('common/setSureSendWayState', false)
      }
    }
  }

  typing()
}

// 获取tokens和会员
const getpersonalusedlist = () => {
  getpersonalused().then(({data: res}) => {
    if (res.code === 0) {
      if (res.data.orderData) {
        localStorage.setItem('useToken', JSON.stringify(res.data.orderData))
        store.commit('common/setUseToken', res.data.orderData)
      }
      store.commit('common/setRemainingPaper', res.data.remainingPaper)
      if (res.data.diamond) {
        localStorage.setItem('diamond', JSON.stringify(res.data.diamond))
        store.commit('common/setDiamond', res.data.diamond)
      }
      if (res.data.remainingQuestioningTimes) {
        store.commit('common/setPlaceholder', `会员 | 剩余提问次数：${res.data.remainingQuestioningTimes || 0}`)
      } else {
        store.commit('common/setPlaceholder', `请直接在此输入对话内容。您也可以点击左侧开启新聊天，通过我们为您提供的提示词分类辅助进行对话`)
      }
      if (res.data.diamond&&res.data.diamond.indexOf('purple') !== -1) {
        if (res.data.remainingPaper) {
          if (res.data.remainingPaper === '无限') {
            store.commit('common/setDrawPlaceholder', `请直接在此描绘你心中画面的样子，尽量详细，可以使用一些风格修饰词。您也可以点击“使用提示词”分类铺助进行绘制。`)
          } else {
            store.commit('common/setDrawPlaceholder', `会员 | 剩余绘图张数：${res.data.remainingPaper || 0}`)
          }
        } else {
          store.commit('common/setDrawPlaceholder', `请直接在此描绘你心中画面的样子，尽量详细，可以使用一些风格修饰词。您也可以点击“使用提示词”分类铺助进行绘制。`)
        }
      } else {
        store.commit('common/setDrawPlaceholder', `会员 | 剩余绘图张数：${res.data.remainingPaper || 0}`)
      }
      store.commit('common/setRemainingPaper', res.data.remainingPaper || 0)
    }
  })
}
// 模型点击
const handleClickTabItem = item => {
  if (localStorage.getItem('token') && localStorage.getItem('diamond')) {
    if ((localStorage.getItem('diamond').indexOf('purple') === -1 && item.value === 'mj') || (localStorage.getItem('diamond').indexOf('purple') === -1 && remainingPaper.value === 0)) {
      ElMessage({
        message: '抱歉，您尚未开通该模型服务，请升级您的权益后使用',
        type: 'warning',
        duration: 2000
      })
      setTimeout(() => {
        store.commit('chat/setChatValue', '')
        store.commit('common/setVipType', 'purple')
        store.commit('common/setVipsMask', true)
      }, 2100)
      return false
    }
  }
  if (loading.value) {
    return false
  }
  firstflag.value = false
  previousTab.value = modelCodeVal.value
  modelCodeVal.value = item.value
  if (localStorage.getItem('token') || item.value === 'sszdraw-v1' || item.value === 'yan') {
    store.commit('common/setModelCode', item.value)
    store.commit('common/setClickTrue', false)
    store.commit('common/setPromptsIdChange', 0)
    promptVal.value = ''
    if (item.value === 'gpt4.0') {
      store.commit('common/setVipType', 'orange')
    } else if (item.value === 'sszdraw-v1' || item.value === 'mj') {
      store.commit('common/setVipType', 'purple')
    } else {
      store.commit('common/setVipType', 'blue')
    }
    if (item.value === 'sszdraw-v1' || item.value === 'mj') {
      store.commit('common/setMjHistoryList', [])
      store.commit('chat/setModelAndSilde', true)
      store.commit('common/setHistoryLogin', [])
      store.commit('common/setHistoryLoginTotal', [])
      setTimeout(() => {
        store.commit('chat/setModelAndSilde', false)
      }, 10)
      router.push('/draw')
    }
  }
}

// 登录显示弹窗
const loginSubmit = () => {
  store.commit('common/setLoginMask', true)
}

// 按下回车发送聊天
const handleKeyupSend = e => {
  if (e.keyCode === 13 && !btn.value) {
    handleClickSend()
  }

}

// 点击发送聊天
const handleClickSend = () => {
  session()
}
// 会话发送聊天
const session = async () => {
  if (timer.value) {
    return false
  }
  if (chatValue.value.trim() !== '') {
    if (modelCode.value === 'gpt4.0' && localStorage.getItem('diamond').indexOf('orange') === -1) {
      ElMessage({
        message: '抱歉，您尚未开通该模型服务，请升级您的权益后使用',
        type: 'warning',
        duration: 2000
      })
      setTimeout(() => {
        store.commit('chat/setChatValue', '')
        store.commit('common/setVipType', 'orange')
        store.commit('common/setVipsMask', true)
      }, 2100)
      return false
    }
    let index = -1
    const datas = {
      chatName: 'New Chat',
      type: 'chat'
    }
    const keys = Object.keys(chatList.value)
    if (JSON.stringify(chatList.value) === '{}' && !keys.includes('today')) {
      chatList.value['today'] = []
      datas.chatId = 1
      store.commit('chat/setChatKey', 'today')
      store.commit('chat/setChatListCurrent', 0)
    } else {
      index = chatList.value[chatKey.value].findIndex(item => item.type === 'chat')
      if (index === -1) {
        datas.chatId = chatId.value + 1
      } else {
        datas.chatId = chatId.value
      }
    }
    store.commit('chat/setChatId', datas.chatId)
    if (index === -1) {
      chatList.value['today'].unshift({
        title: 'New Chat',
        edit: false,
        type: 'chat',
        dialogueShow: false,
        list: []
      })
      await updateChatName(datas)
        .then(async response => {
          const {data: res, status} = response
          if (status === 200) {
            store.commit('chat/setChatListCurrent', index === -1 ? 0 : index)
            chatList.value[chatKey.value][chatListCurrent.value].id = datas.chatId
            store.commit('chat/setChatList', chatList.value)
            await talk()
          }
        })
        .catch(() => {
          setTimeout(async () => {
            store.commit('chat/setChatValue', '')
          }, 1500)
        })
    } else {
      talk()
    }
  }
}

// 会话谈话
const talk = () => {
  chatList.value[chatKey.value][chatListCurrent.value].list = chatList.value[chatKey.value][chatListCurrent.value].list || []
  chatList.value[chatKey.value][chatListCurrent.value].dialogueShow = false
  chatList.value[chatKey.value][chatListCurrent.value].list.push({
    content: chatValue.value,
    role: 'Q'
  })
  nextTick(() => {
    main.value.scrollTop = main.value.scrollHeight
  })
  btn.value = true
  store.commit('common/setLoading', true)
  const data = {
    message: chatValue.value,
    chatId: chatId.value,
    promptsId: promptsId.value,
    isEn: 0,
    model: modelCode.value
  }
  if (!promptShow.value) {
    data.isEn = 1
  }
  if (chatList.value.length === 0) {
    data.promptsId = 273
  }
  const timer = setTimeout(() => {
    store.commit('common/setTimeoOut', true)
  }, 20000)
  const timer125 = setTimeout(() => {
    store.commit('common/setTimeoOut', false)
  }, 125000)
  initiatetask(data).then((response) => {
    const {data: res, status} = response
    clearTimeout(timer)
    clearTimeout(timer125)
    btn.value = false
    promptVal.value = ''
    promptShow.value = true
    store.commit('common/setLoading', false)
    store.commit('common/setTimeoOut', false)
    if (status === 200) {
      if (res.code === 0) {
        store.commit('common/setSseState', true)
        getChatSSE(res.data.msg, data.message)
        return false
      }
    }
    if (status === 202) {
      if (res.code === 1) {
        if (localStorage.getItem('token')) {
          ElMessage.warning('验证失败，请重新登录或完成注册')
          store.dispatch('chat/getListData', 0)
          clearStorage()
        } else {
          ElMessage.warning(res.msg)
          store.commit('common/setPlaceholder', `游客 | 剩余提问次数：0`)
          localStorage.setItem('placeholder', `游客 | 剩余提问次数：0`)
        }
        store.commit('chat/setChatValue', '')
        setTimeout(() => {
          loginSubmit()
        }, 3000)
      } else {
        if (res.code === 3) {
          ElMessage({
            message: res.msg,
            type: 'warning',
            duration: 2000
          })
          setTimeout(() => {
            store.commit('common/setVipType', 'blue')
            store.commit('common/setVipsMask', true)
          }, 2100)
        }
        if (res.code === 0) {
          ElMessage.warning(res.msg)
        }
        setTimeout(() => {
          store.commit('chat/setChatValue', '')
        }, 1500)
      }
    }
  })
}
</script>
<style lang="scss" scoped>
.main {
  position: absolute;
  top: $tab-height;
  left: 0;
  right: 0;
  bottom: 50px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 10px;
  z-index: 102;
}

.tabbox {
  // width: 100%;
  height: $tab-height;
  display: flex;
  justify-content: space-between;
  padding: 0 17px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  // margin-left: 30px;
  .share {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    span {
      font-size: 14px;
      color: #f87c05;
      text-decoration: underline;
    }
  }

  .tab {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    position: relative;

    .tabItem {
      height: 30px;
      line-height: 30px;
      border-radius: 2.5px;
      background: #fff;
      margin-right: 16px;
      color: #3e3f4f;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      width: 121px;
      box-shadow: 0 0 3px #3e3f4f;
    }

    .active {
      background: #3e3f4f;
      color: #ff7900;
    }

    .notoken {
      background: #f4f4f5;
      color: #c8c9cc;
      cursor: not-allowed;
      box-shadow: none;
    }

    .tabOther {
      display: flex;
      align-items: center;
      margin-left: 20px;
    }

    .tabicon {
      width: 25px;
      height: 20px;
      margin-right: 16px;
    }

    .tabicon1 {
      position: absolute;
      top: 19px;
      right: 276px;
    }
    .tabicon2 {
      right: 140px;
    }
  }
}

.drawbox {
  background: #2f2f3d;
  border-bottom: 0;
  position: relative;

  .tab {
    .tabItem {
      border: 1px solid #b7baed;
      border-radius: 3px;
      box-shadow: none;
      background: transparent;
      color: #b7baed;
    }

    .tabOther {
      margin-left: 3px;
    }

    .active {
      background: #3e3f4f;
      border: 1px solid #3e3f4f;
      color: #ff7900;
    }
  }
}

:deep(.operate) {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  // width: 60%;
  padding: 0 $main-width;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 103;

  .setting {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
  }

  .el-input,
  .el-button {
    height: 100%;
    line-height: 45px;
    border-color: #ff7a04 !important;
    position: relative;
  }

  .el-input__wrapper {
    padding-right: 30px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: 0 0 0 1px #ff7a04 inset !important;
  }

  .el-input__suffix {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;
  }

  .el-button {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: #ff7a04;
  }

  .inserttips {
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    background: #f87c05;
    color: #fff;
    position: absolute;
    right: calc(#{$main-width} - 110px);
    top: 0;
    cursor: pointer;
  }
}

.tokenuse {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 20%;
  // background: #fff;
  z-index: 104;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    .type {
      font-size: 12px;
      color: #3e3f4f;
      margin-right: 5px;
      width: 44px;
    }

    .blue {
      color: #389bfa;
    }

    .orange {
      color: #f98d29;
    }

    .purple {
      color: #9b6cff;
    }

    .token {
      font-size: 12px;
      color: #3e3f4f;
      width: 64px;
    }

    .speed {
      margin-left: 10px;
      display: flex;
      align-items: center;
      width: calc(100% - 120px);

      .el {
        width: 200px;
        height: 5px;
        background: #ff7a04;
        margin-right: 10px;
        position: relative;

        .tips {
          font-size: 12px;
          color: rgba(62, 63, 79, 0.5);
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          top: -15px;
          text-align: center;
          cursor: default;
        }

        .hot {
          width: 15px;
          height: 15px;
          position: absolute;
          top: -6px;
          left: -7.5px;
        }

        .use {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: #ccc;
        }

        .info {
          min-width: 160px;
          padding: 5px 10px;
          background: #ffffff;
          box-shadow: 0px 0px 48px 0px rgba(50, 50, 50, 0.03),
          0px 0px 16px 0px rgba(0, 0, 0, 0.08);
          border-radius: 8px;
          font-size: 12px;
          color: #3e3f4f;
          position: absolute;
          bottom: 5px;
          left: 70%;
          display: none;
          z-index: 1000;
        }

        &:hover {
          .info {
            display: block;
          }
        }
      }
    }
  }
}

:deep(.drawoperate) {
  left: #{$draw-silde + 70px};
  padding-right: 198px;
  padding-left: 0;
  z-index: 101;

  .el-input__wrapper {
    background: #282833;
  }

  .el-input,
  .el-input__inner {
    color: rgba(255, 255, 255, 0.5);
  }

  .inserttips {
    right: 88px;
  }

  .driver3 {
    right: 87px;
  }
}

.drawtokenuse {
  left: #{$draw-silde + 70px};
  padding-right: 198px;
  background: transparent;
  padding-left: 0;
  z-index: 101;

  .item {
    .token {
      color: #fff;
    }

    .speed {
      .el {
        .info {
          color: #3e3f4f;
        }

        .tips {
          color: #fff;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-popper.is-customized {
  padding: 6px 12px;
  background-color: #fff0e1;
  color: #f27809;
}

.el-popper.is-customized .el-popper__arrow::before {
  background-color: #fff0e1;
  color: #f27809;
  right: 0;
}

.driver1 {
  width: 465px;
  height: 55px;
  position: absolute;
  z-index: -1;
  background: transparent;
  top: 0;
  left: 0;
}

.driver2 {
  width: 185px;
  height: 55px;
  position: absolute;
  z-index: -1;
  background: transparent;
  top: 0;
  left: 470px;
}

.driver3 {
  width: 200px;
  height: 40px;
  position: absolute;
  z-index: -1;
  background: transparent;
  top: 0;
  right: calc(20% - 110px);
}

.driver-popover {
  background: #ffffff linear-gradient(
      180deg,
      rgba(255, 121, 0, 0.26) 0%,
      rgba(242, 120, 9, 0) 29%
  );
  color: #333;
}

.driver-popover-arrow {
  border: 5px solid #fee9d6;
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
}

#driver-popover-title {
  font-weight: normal;
}

.driver-popover-prev-btn {
  display: none !important;
}

.driver-popover-navigation-btns {
  button.driver-popover-next-btn {
    width: 110px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    background: #f27809;
    border-radius: 2px;
    opacity: 1;
    color: #fff;
    text-shadow: none;
    border: none;
    padding: 0;
    text-align: center;
  }
}

.driver-popover-arrow-side-top {
  transform: rotate(180deg);
}
</style>
