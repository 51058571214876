<template>
  <div class="chat">
    <div class="chat_left">
      <div class="logo">
        <img src="../assets/logo.png"/>
      </div>
      <!-- 侧边栏 -->
      <ChatSilder/>
      <OtherServices/>
    </div>
    <div :style="{ background: $route.path == '/draw' ? '#333341' : '' }" class="chat_right">
      <!-- 头部 -->
      <Header/>
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
    <!-- 登录框 -->
    <LoginMask v-if="loginMask"/>
    <!-- 备案信息 -->
    <Filings/>
    <!-- 绘图提示词 -->
    <DrawExpert v-if="drawExpert"/>
    <!-- 对话提示词 -->
    <Expert v-if="expertMask" @handleExpert="handleExpert"/>
    <!-- vip -->
    <VIPMask v-if="vipsMask"/>
    <!-- 联系客服 -->
    <Service v-if="serviceMask"/>
    <!-- 关于我们 -->
    <About v-if="aboutMask"/>
    <!-- 会话设置 -->
    <SessionMask v-if="sessionMask"/>
    <!-- 个人中心 -->
    <UserInfoMask v-if="userInfoMask"/>
    <!-- 分享弹窗  -->
    <ShareMask v-if="shareMask"/>
    <!-- 签到弹窗  -->
    <SignMask v-if="signMask"/>
    <!-- 使用帮助弹窗  -->
    <HelpMask v-if="helpMask"/>
    <!-- 自动登录提示 -->
    <div
      v-if="tipShow"
      class="loginAutoTips animate__animated animate__fadeInDown"
    >
      <img alt="" src="../assets/images/loading.png"/>正在为您自动登陆…
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from 'vue'
import {ElMessage} from 'element-plus'
import Filings from '@/components/Filings'
import LoginMask from '@/components/LoginMask'
import Expert from '@/components/Expert'
import DrawExpert from '@/components/DrawExpert'
import ChatSilder from '@/components/ChatSilder'
import Header from '@/components/Header'
import OtherServices from '@/components/OtherServices'
import VIPMask from '@/components/VIPMask'
import Service from '@/components/Service'
import About from '@/components/About'
import SessionMask from '@/components/SessionMask'
import UserInfoMask from '@/components/UserInfoMask'
import ShareMask from '@/components/ShareMask'
import SignMask from '@/components/SignMask'
import HelpMask from '@/components/HelpMask'

import {useRouter} from 'vue-router'
import {clearStorage} from '@/utils/common'
import {useStore} from 'vuex'
import {getcumulativesignin, loginToken, updateChatName} from '@/api/common'
import {initTime} from '@/utils/validate'

const router = useRouter()

const store = useStore()

const chatList = computed(() => store.state.chat.chatList)
const chatListCurrent = computed(() => store.state.chat.chatListCurrent)
const chatId = computed(() => store.state.chat.chatId)
const loginMask = computed(() => store.state.common.loginMask)
const expertMask = computed(() => store.state.common.expertMask)
const vipsMask = computed(() => store.state.common.vipsMask)
const serviceMask = computed(() => store.state.common.serviceMask)
const aboutMask = computed(() => store.state.common.aboutMask)
const sessionMask = computed(() => store.state.common.sessionMask)
const userInfoMask = computed(() => store.state.common.userInfoMask)
const shareMask = computed(() => store.state.common.shareMask)
const signMask = computed(() => store.state.common.signMask)
const helpMask = computed(() => store.state.common.helpMask)
const modelCode = computed(() => store.state.common.modelCode)
const drawExpert = computed(() => store.state.draw.drawExpert)

const tipShow = ref(false)
onMounted(() => {
  if (localStorage.getItem('login')) {
    if (localStorage.getItem('unm')) {
      submitForm()
      tipShow.value = true
    } else {
      store.commit('common/setLoginMask', true)
    }
    setTimeout(() => {
      localStorage.removeItem('login')
    }, 1000)
  }
  if (localStorage.getItem('tipsmask')) {
    store.commit('common/setTipsMask', false)
  }
  if (localStorage.getItem('uname') && localStorage.getItem('token')) {
    store.commit('common/setUserName', localStorage.getItem('uname'))
    signTotal()
    if (
      localStorage.getItem('diamond') &&
      localStorage.getItem('diamond').indexOf('purple') != -1
    ) {
      store.commit('common/setVipType', 'purple')
      return false
    }
  } else {
    if (localStorage.getItem('placeholder')) {
      store.commit(
        'common/setPlaceholder',
        `游客 | 剩余提问次数：${localStorage.getItem('placeholder')}`
      )
    } else {
      store.commit(
        'common/setPlaceholder',
        `请直接在此输入对话内容。您也可以点击左侧开启新聊天，通过我们为您提供的提示词分类辅助进行对话`
      )
    }
    if (localStorage.getItem('drawplaceholder')) {
      store.commit(
        'common/setDrawPlaceholder',
        `游客 | 剩余绘图张数：${localStorage.getItem('drawplaceholder')}`
      )
    } else {
      store.commit(
        'common/setDrawPlaceholder',
        `请直接在此描绘你心中画面的样子，尽量详细，可以使用一些风格修饰词。您也可以点击“使用提示词”分类铺助进行绘制`
      )
    }
    if (!sessionStorage.getItem('sign')) {
      store.commit('common/setSignMask', true)
    }
  }
})

// 获取签到信息
const signTotal = () => {
  getcumulativesignin().then(response => {
    const {data: res, status} = response
    if (status == 200) {
      if (res.code == 0) {
        store.commit('common/setSignTotal', res.data.total)
        store.commit('common/setSignTokens', res.data.tokens)
        const date =
          initTime() != res.data.signInDate[res.data.signInDate.length - 1]
        if (res.data.total == 7) {
          store.commit('common/setSignMask', false)
          return false
        }
        if (date && !sessionStorage.getItem('sign')) {
          store.commit('common/setSignMask', true)
        }
      }
    }
  })
}

// 自动登录
const submitForm = () => {
  loginToken({
    userName: localStorage.getItem('unm'),
    pwd: localStorage.getItem('pwd')
  }).then(({data: res}) => {
    if (res.code == 0) {
      if (res.data) {
        localStorage.setItem('uname', res.data.userName)
        localStorage.setItem('token', res.data.accessToken)
        store.commit('common/setUserName', res.data.userName)
        store.dispatch('chat/getListData')
        store.commit('common/setDiamond', res.data.diamond)
        localStorage.setItem('diamond', JSON.stringify(res.data.diamond))
        store.commit('common/setRemainingPaper', res.data.remainingPaper)
        signTotal()
        if (res.data.orderData) {
          localStorage.setItem('useToken', JSON.stringify(res.data.orderData))
          store.commit('common/setUseToken', res.data.orderData)
        }
        if (
          res.data.diamond.includes('blue') ||
          res.data.diamond.includes('orange')
        ) {
          store.commit(
            'common/setPlaceholder',
            '请直接在此输入对话内容。您也可以点击左侧开启新聊天，通过我们为您提供的提示词分类辅助进行对话'
          )
        } else {
          if (res.data.diamond.indexOf('purple') === -1) {
            store.commit(
              'common/setPlaceholder',
              `会员 | 剩余提问次数：${res.data.remainingQuestioningTimes || 0}`
            )
          }
        }
        if (
          res.data.diamond.indexOf('purple') !== -1 &&
          res.data.remainingPaper === '无限'
        ) {
          store.commit(
            'common/setDrawPlaceholder',
            `请直接在此描绘你心中画面的样子，尽量详细，可以使用一些风格修饰词。您也可以点击“使用提示词”分类铺助进行绘制。`
          )
        } else {
          store.commit(
            'common/setDrawPlaceholder',
            `会员 | 剩余绘图张数：${res.data.remainingPaper || 0}`
          )
        }
      } else {
        ElMessage.warning(res.msg)
      }
      localStorage.removeItem('unm')
      localStorage.removeItem('pwd')
      setTimeout(() => {
        tipShow.value = false
      }, 1500)
    }
  })
}

watch(
  () => router.currentRoute.value.path,
  (newv, oldv) => {
    if (newv != '/chat' && newv != '/draw') {
      store.commit('common/setRouteDefault', false)
    } else {
      store.commit('common/setRouteDefault', true)
    }
  },
  {deep: true}
)

const maxChatId = () => {
  let idList = []
  for (const key1 in chatList.value) {
    if (chatList.value.hasOwnProperty.call(chatList.value, key1)) {
      const element = chatList.value[key1]
      for (const key in element) {
        if (element.hasOwnProperty.call(element, key)) {
          const item = element[key]
          idList.push(item)
        }
      }
    }
  }
  let maxId = -1
  if (idList.length > 0) {
    // 遍历JSON数据，找到最大的id
    for (let i = 0; i < idList.length; i++) {
      if (idList[i].id > maxId) {
        maxId = idList[i].id
      }
    }
    store.commit('chat/setChatId', maxId)
  } else {
    store.commit('chat/setChatId', 0)
  }
}

const handleExpert = item => {
  maxChatId()
  store.commit('chat/setChatObj', item)
  store.commit('common/setExpertMask', false)
  if (!chatList.value['today']) {
    chatList.value['today'] = []
  }
  chatList.value['today'].unshift({
    title: 'New Chat',
    edit: false,
    dialogueShow: true,
    type: 'chat',
    list: []
  })
  store.commit('chat/setChatListCurrent', 0)
  const data = {
    chatId: Number(chatId.value) + 1,
    type: 'chat',
    chatName: chatList.value['today'][chatListCurrent.value].title
  }
  store.commit('chat/setChatId', data.chatId)
  updateChatName(data)
    .then(response => {
      const {data: res, status} = response
      if (status == 200) {
        if (res.code == 0) {
          store.dispatch('chat/getListData', 'expert')
        } else {
          store.dispatch('chat/getListData', 0)
        }
        store.commit('chat/setChatListCurrent', 0)
      }
      if (status == 202) {
        if (res.code == 1) {
          // ElMessage.warning('验证失败，请重新登录或完成注册')
          ElMessage.warning(res.msg)
          clearStorage()
          store.commit('common/setFreeTips', true)
          setTimeout(() => {
            store.commit('common/setLoginMask', true)
          }, 3000)
        } else {
          if (res.code == 3) {
            ElMessage.warning(res.msg)
            setTimeout(() => {
              store.commit('common/setVipsMask', true)
            }, 3000)
          }
          if (res.code == 0) {
            ElMessage.warning(res.msg)
          }
        }
        setTimeout(() => {
          store.dispatch('chat/getListData', 0)
          store.commit('chat/setChatValue', '')
          store.commit('chat/setChatListCurrent', 0)
        }, 1500)
      }
    })
    .catch(() => {
      setTimeout(() => {
        store.dispatch('chat/getListData', 0)
        store.commit('chat/setChatValue', '')
      }, 1500)
    })
}
</script>
<style lang="scss" scoped>
.chat {
  background: #fff;
  position: relative;
  height: 100%;
  width: 100%;

  .chat_left {
    width: $width;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: #272732;

    .logo {
      height: $top-height;
      overflow: hidden;

      img {
        width: 164px;
        height: 37px;
        display: block;
        margin: 16px auto 0;
      }
    }
  }

  .chat_right {
    width: calc(100% - #{$width});
    position: absolute;
    top: 0;
    left: $width;
    bottom: 0;
    // z-index: 10;
    z-index: auto;

    .container {
      position: absolute;
      top: $top-height;
      left: 0;
      right: 0;
      bottom: 30px;
      // overflow-y: auto;
    }
  }
}

.loginAutoTips {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 60px;
  font-size: 14px;
  color: #ff7900;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 0 10px #ccc;
  width: 200px;
  height: 40px;
  border-radius: 5px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    animation: rotate 1s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
