<!-- vip -->
<template>
  <div class="vip" @click="hideVipsMask">
    <div class="vipMian" @click.stop>
      <div class="vip_tab">
        <div
          v-for="(item, i) in tab"
          :key="i"
          class="item"
          @click="tabChange(item)"
        >
          <div>
            <img src="../../assets/images/diamond.png"/>{{ item.label }}
          </div>
          <img
            :class="vipType == item.value ? 'activeImg' : ''"
            :src="item.activeImg"
          />
        </div>
      </div>
      <div class="vip_main">
        <div v-if="vipType == 'blue'" class="item">
          <div class="topdesc">
            成为尊贵的<span>蓝钻VIP</span>，您将可享受到
            <span class="other">不限次数的智能聊天与咨询服务，</span>
            <br/>更有资格优先进行国内顶尖生成式模型、智能能源调度算法、智能AI图像识别等尖端产品
            <span class="other">免费试用</span>
          </div>
          <div class="buymodule">
            <div v-for="(item, i) in list" :key="i" class="item">
              <div class="top">
                <div class="productName">{{ item.productName }}</div>
                <div class="productTag">{{ item.productTag }}</div>
                <img v-if="!item.price" src="../../assets/images/img41.png"/>
              </div>
              <div class="bot">
                <template v-if="item.discounted_price">
                  <div class="price">
                    <span class="unit1">¥</span>
                    <span class="money">{{ item.discounted_price }}</span>
                    <span class="unit2">/{{ item.discounted_unit }}</span>
                  </div>
                  <div class="price priceyuan">
                    <span class="unit1">¥</span>
                    <span class="money">{{ item.price }}</span>
                    <span class="unit2">/{{ item.unit }}</span>
                    <div class="line"></div>
                  </div>
                  <div
                    v-if="
                      item.discounted_desc == '' &&
                      item.discounted_tag.length > 0
                    "
                    class="tag"
                  >
                    <span v-for="(el, i) in item.discounted_tag" :key="i">
                      {{ el }}
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div v-if="item.price" class="price">
                    <span class="unit1">¥</span>
                    <span class="money">{{ item.price }}</span>
                    <span class="unit2">/{{ item.unit }}</span>
                  </div>
                  <div v-else class="price">
                    <span class="unit1">¥</span>
                    <span class="money">0.0</span>
                    <span class="unit2">免费</span>
                  </div>
                </template>
                <div
                  v-if="item.discounted_desc && item.discounted_desc != ''"
                  class="preferential"
                >
                  {{ item.discounted_desc }}
                </div>
                <div class="desc">
                  <div
                    v-for="(el, i) in item.productDescription"
                    :key="i"
                    class=""
                  >
                    <img
                      v-if="
                        !item.price &&
                        (i == item.productDescription.length - 1 ||
                          i == item.productDescription.length - 2)
                      "
                      class="close"
                      src="../../assets/images/closeicon.png"
                    />
                    <img v-else src="../../assets/images/duiicon.png"/>
                    {{ el }}
                  </div>
                </div>
                <template v-if="userName != ''">
                  <div v-if="!item.price" class="share" @click="shareClick">
                    <img src="../../assets/images/share.png"/>
                    <span>点击分享获得免费次数</span>
                  </div>
                </template>
                <div v-if="item.price" class="btns">
                  <img alt="" src="../../assets/images/weicon.png"/>
                  <div class="buy" @click="orderCreate(item)">微信支付</div>
                </div>
                <div v-else class="btns">
                  <div
                    :class="token != '' ? 'hastoken' : ''"
                    class="buy"
                    style="width: 202px"
                    @click="login"
                  >
                    {{ roleText }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tips">
            <p>
              我们会后续会不断开发接入最新最顶尖的AI应用服务，如果您想试用这些新产品功能，请选择任意一款VIP订阅服务，我们将保证使您优先<span>免费</span>试用这些新功能。
            </p>
            <p>
              会员免费版会话记录仅<span>保留30天</span>，如果您想长期保存会话记录，请升级至订阅版。
            </p>
          </div>
        </div>
        <div v-if="vipType == 'orange'" class="item">
          <div class="topdesc topdescorange">
            成为尊贵的<span>橙钻VIP</span>，您将可享受到
            <span class="other">不限次数的智能聊天与咨询服务，</span>
            <br/>更有资格优先进行国内顶尖生成式模型、智能能源调度算法、智能AI图像识别等尖端产品
            <span class="other">免费试用</span>
          </div>
          <div class="buymoduleorange">
            <img class="title" src="../../assets/images/orangetitle.png"/>
            <div class="moneyBox">
              <div class="left">
                <div class="price">
                  <div
                    v-for="(item, i) in orangeObj.price"
                    :key="i"
                    class="item"
                  >
                    <p class="p1">{{ item.tokens }}<br/>tokens</p>
                    <p v-if="item.discounted_price != ''" class="p2">
                      ￥{{ item.discounted_price }}
                    </p>
                    <p v-else class="p2">￥{{ item.price }}</p>
                    <p class="p3">({{ item.word }})</p>
                    <p v-if="item.discounted_price != ''" class="p4">
                      ¥{{ item.price }}
                    </p>
                  </div>
                </div>
                <div class="pricetip">
                  请选择充值金额<span>（最低充值5元）</span>
                  <span v-if="moneyTipShow" class="inputtip">
                    最低充值金额5元!
                  </span>
                </div>
                <div class="moneys">
                  <div class="moneychange">
                    <div
                      v-for="(item, i) in moneyList"
                      :key="i"
                      :class="moneyIndex == item ? 'active' : ''"
                      class="item"
                      @click="moneyToken(item)"
                    >
                      {{ item }}
                    </div>
                    <el-input
                      v-model="recharge"
                      :maxlength="9"
                      class="otherItem"
                      placeholder="其他金额请输入"
                      @blur="changeBlur"
                      @keyup="number"
                    ></el-input>
                  </div>
                  <div class="moneytip">
                    <ul>
                      <li>
                        <span class="s1">¥{{ moneyObj.price }}</span>
                        <span class="s2"></span>
                      </li>
                      <li>
                        <span class="s2">=</span>
                        <span class="s2">≈</span>
                      </li>
                      <li>
                        <span class="s2">{{ moneyObj.tokens }}</span>
                        <span class="s2">{{ moneyObj.word }}</span>
                      </li>
                      <li>
                        <span class="s3">tokens</span>
                        <span class="s3">汉字</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="line"></div>
              <div class="right">
                <div
                  v-for="(item, i) in orangeObj.productDescription"
                  :key="i"
                  class="item"
                >
                  <img src="../../assets/images/duiicon.png"/>
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="paybtns">
              <img src="../../assets/images/weicon.png"/>
              <div class="buy" @click="orderCreate(orangeObj)">微信支付</div>
            </div>
          </div>
          <div class="tips">
            <p>
              我们会后续会不断开发接入最新最顶尖的AI应用服务，如果您想试用这些新产品功能，请选择任意一款VIP订阅服务，我们将保证使您优先<span>免费</span>试用这些新功能。
            </p>
            <p>
              会员免费版会话记录仅<span>保留30天</span>，如果您想长期保存会话记录，请升级至订阅版。
            </p>
          </div>
        </div>
        <div v-if="vipType == 'purple'" class="item">
          <div class="topdesc topdescpurple">
            成为尊贵的<span>紫钻VIP</span>，您将可享受到
            <span class="other">不限次数的图像绘制</span>
            <br/>等AI绘图服务，更有资格优先进行国内顶尖生成式绘图产品
            <span class="other">免费试用</span>
          </div>
          <div class="purplebox">
            <div class="buymoduleorange purple">
              <img class="title" src="../../assets/images/purpletitle.png"/>
              <div class="moneyBox">
                <div class="left">
                  <div class="price">
                    <div
                      v-for="(item, i) in purpleObj.price"
                      :key="i"
                      class="item"
                    >
                      <p class="p1">{{ item.paper }}张</p>
                      <p v-if="item.discounted_price != ''" class="p2">
                        ￥{{ item.discounted_price }}
                      </p>
                      <p v-else class="p2">￥{{ item.price }}</p>
                      <p v-if="item.discounted_price != ''" class="p4">
                        ¥{{ item.price }}
                      </p>
                      <img
                        v-if="item.discounted_tag"
                        class="cz"
                        src="../../assets/images/cz.png"
                      />
                    </div>
                    <div class="item">
                      <p class="p1">
                        {{
                          purpleObj1.productDescription &&
                          purpleObj1.productDescription[0]
                        }}
                      </p>
                      <p class="p2">￥{{ purpleObj1.price }}</p>
                      <img class="cz" src="../../assets/images/cz.png"/>
                    </div>
                  </div>
                  <div class="pricetip">
                    请选择充值金额<span>（最低充值10元）</span>
                    <span v-if="pmoneyTipShow" class="inputtip">
                      最低充值金额10元!
                    </span>
                    <span v-if="pmoneyTipShowMax" class="inputtip">
                      已到最大输入金额
                    </span>
                  </div>
                  <div class="moneys">
                    <div class="moneychange">
                      <div
                        v-for="(item, i) in pmoneyList"
                        :key="i"
                        :class="[
                          pmoneyIndex == item ? 'active' : '',
                          pmoneyIndex == 149 && item == '包月' ? 'active' : ''
                        ]"
                        class="item"
                        @click="moneyTokenP(item)"
                      >
                        {{ item }}
                      </div>
                      <el-input
                        v-model="precharge"
                        :maxlength="9"
                        class="otherItem"
                        placeholder="其他金额请输入"
                        style="width: 208px"
                        @blur="changeBlurP"
                        @keyup="numberP"
                      ></el-input>
                    </div>
                    <div class="moneytip">
                      <ul>
                        <li>
                          <span class="s1">¥{{ pmoneyObj.price }}</span>
                        </li>
                        <li>
                          <span class="s2">=</span>
                        </li>
                        <li>
                          <span class="s2">
                            {{ pmoneyObj.paper }}
                            <i
                              v-if="pmoneyObj.price < 149"
                              style="font-style: normal"
                            >
                              张
                            </i>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="line"></div>
                <div class="right">
                  <div
                    v-for="(item, i) in purpleObj.productDescription"
                    :key="i"
                    class="item"
                  >
                    <img src="../../assets/images/duiicon.png"/>
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="paybtns">
                <img src="../../assets/images/weicon.png"/>
                <div class="buy" @click="orderCreate(purpleObj)">微信支付</div>
              </div>
            </div>
            <div class="buymodule">
              <div class="item">
                <div class="top">
                  <div class="productName">{{ phy.productName }}</div>
                  <img src="../../assets/images/img41.png"/>
                </div>
                <div class="bot">
                  <div class="price">
                    <span class="unit1">¥</span>
                    <span class="money">0.0</span>
                    <span class="unit2">免费</span>
                  </div>
                  <div class="desc">
                    <div v-for="(item, i) in phy.productDescription" :key="i">
                      <img src="../../assets/images/duiicon.png"/>
                      {{ item }}
                    </div>
                  </div>
                  <div class="btns">
                    <div
                      :class="token != '' ? 'hastoken' : ''"
                      class="buy"
                      style="width: 202px"
                      @click="login"
                    >
                      {{ purpleRoleText }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tips">
            <p>
              我们会后续会不断开发接入最新最顶尖的AI应用服务，如果您想试用这些新产品功能，请选择任意一款VIP订阅服务，我们将保证使您优先<span>免费</span>试用这些新功能。
            </p>
            <p>
              会员免费版会话记录仅<span>保留30天</span>，如果您想长期保存会话记录，请升级至订阅版。
            </p>
          </div>
        </div>
      </div>
    </div>
    <OrderCode v-if="orderCode"/>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, toRefs} from 'vue'
import {getformula, getproductinfo, orangeMoney, purpleMoney} from '@/api/order'
import {ElMessage} from 'element-plus'
import OrderCode from '@/components/OrderCode'
import {useStore} from 'vuex'

const initData = reactive({
  list: [],
  orangeObj: {},
  purpleObj: {},
  purpleObj1: {},
  tab: [
    {
      label: '蓝钻VIP',
      value: 'blue',
      activeImg: require('../../assets/images/vip1.png')
    },
    {
      label: '橙钻VIP',
      value: 'orange',
      activeImg: require('../../assets/images/vip2.png')
    },
    {
      label: '紫钻VIP',
      value: 'purple',
      activeImg: require('../../assets/images/vip3.png')
    }
  ],
  token: '',
  moneyList: [10, 20, 30, 50, 100, 200],
  moneyIndex: 30,
  moneyObj: {
    price: 0,
    tokens: 0,
    word: 0
  },
  recharge: '',
  moneyTipShow: false,
  roleText: '注册登录即享',
  pmoneyList: [10, 20, 30, 50, '包月'],
  pmoneyIndex: 30,
  pmoneyObj: {
    price: 0,
    paper: 0
  },
  precharge: '',
  phy: {},
  purpleRoleText: '注册登录即享',
  pmoneyTipShow: false,
  pmoneyTipShowMax: false,
  purpleData: [],
  orangeData: []
})
const {
  list,
  tab,
  token,
  orangeObj,
  moneyList,
  moneyIndex,
  moneyObj,
  recharge,
  moneyTipShow,
  roleText,
  pmoneyList,
  pmoneyIndex,
  pmoneyObj,
  precharge,
  purpleObj,
  phy,
  purpleRoleText,
  pmoneyTipShow,
  pmoneyTipShowMax,
  purpleObj1,
  purpleData,
  orangeData
} = toRefs(initData)

const store = useStore()
const orderCode = computed(() => store.state.common.orderCode)
const vipType = computed(() => store.state.common.vipType)
const userName = computed(() => store.state.common.userName)
const hideVipsMask = () => {
  store.commit('common/setVipsMask', false)
  // store.commit('common/setVipType', 'blue')
}

onMounted(() => {
  getListsData()
  if (localStorage.getItem('token')) {
    token.value = localStorage.getItem('token')
  }
  if (localStorage.getItem('diamond')) {
    const diamond = localStorage.getItem('diamond')
    if (diamond.indexOf('member') != -1) {
      roleText.value = '您已是会员'
    }
    if (diamond.indexOf('blue') != -1) {
      roleText.value = '您已是蓝钻VIP'
    }
    if (diamond.indexOf('orange') != -1) {
      roleText.value = '您已是橙钻VIP'
    }
    if (diamond.indexOf('purple') != -1) {
      purpleRoleText.value = '您已是紫钻VIP'
    }
  }
})

const purpleInitData = type => {
  getformula({type: type}).then(response => {
    const {data: res, status} = response
    if (status == 200) {
      if (res.code == 0) {
        if (type == 'purple') {
          purpleData.value = res.data
          pmoneyObj.value = {
            price: pmoneyIndex.value,
            paper: calculatePaperPrice(pmoneyIndex.value)
          }
        }
        if (type == 'orange') {
          orangeData.value = res.data
          const {token, word} = calculateTokenAndWordPrice(moneyIndex.value)
          moneyObj.value = {
            price: moneyIndex.value,
            tokens: token,
            word: word
          }
        }
      }
    }
  })
}

// 分享
const shareClick = () => {
  store.commit('common/setShareMask', true)
}

// 获取vip数据
const getListsData = () => {
  list.value = []
  getproductinfo({type: vipType.value}).then(response => {
    const {data: res, status} = response
    if (status == 200) {
      if (res.code == 0) {
        if (vipType.value == 'blue') {
          list.value = res.data
        }
        if (vipType.value == 'orange' && res.data.length > 0) {
          orangeObj.value = res.data[0]
          moneyIndex.value = 30
          // getOrangeMoney()
          purpleInitData('orange')
        }
        if (vipType.value == 'purple' && res.data.length > 0) {
          purpleObj.value = res.data[0]
          purpleObj1.value = res.data[1]
          phy.value = res.data[2]
          pmoneyIndex.value = 30
          // getPurpleMoney()
          purpleInitData('purple')
        }
      }
    }
  })
}

// 黄钻根据金额查询token
const getOrangeMoney = () => {
  let money = moneyIndex.value
  if (money == 0 && recharge.value != '') {
    money = recharge.value
  }
  orangeMoney({money: money}).then(response => {
    const {data: res, status} = response
    if (status == 200) {
      if (res.code == 0) {
        moneyObj.value = res.data
      }
    }
    if (status == 202) {
      if (res.code == 0) {
        moneyObj.value = res.data
        ElMessage.warning(res.msg)
      }
    }
  })
}

// 紫钻根据金额查询token
const getPurpleMoney = () => {
  let money = pmoneyIndex.value
  if (money == 0 && precharge.value != '') {
    money = precharge.value
  }
  purpleMoney({money: money}).then(response => {
    const {data: res, status} = response
    if (status == 200) {
      if (res.code == 0) {
        pmoneyObj.value = res.data
      }
    }
    if (status == 202) {
      if (res.code == 0) {
        pmoneyObj.value = res.data
        ElMessage.warning(res.msg)
      }
    }
  })
}

// 会员版
const login = () => {
  if (!localStorage.getItem('token')) {
    store.commit('common/setVipsMask', false)
    store.commit('common/setLoginMask', true)
  }
}

// 橙钻限制输入 只能是数字
const number = () => {
  if (recharge.value.substring(0, 1) == 0) {
    recharge.value = recharge.value.replace(0, '')
  }
  recharge.value = recharge.value.replace(/[^\d]/g, '')
  moneyIndex.value = recharge.value == '' ? 30 : 0
  if (recharge.value == '') {
    moneyTipShow.value = false
  }
}

// 橙钻失去焦点
const changeBlur = () => {
  if (recharge.value != '') {
    moneyTipShow.value = recharge.value < 5
    if (recharge.value < 5) {
      moneyObj.value = {
        price: 0,
        tokens: 0,
        word: 0
      }
      return false
    }
    if (!moneyTipShow.value) {
      // getOrangeMoney()
      moneyIndex.value = recharge.value
      const {token, word} = calculateTokenAndWordPrice(moneyIndex.value)
      moneyObj.value = {
        price: moneyIndex.value,
        tokens: token,
        word: word
      }
    }
  }
}

// 紫钻限制输入 只能是数字
const numberP = () => {
  if (precharge.value.substring(0, 1) == 0) {
    precharge.value = precharge.value.replace(0, '')
  }
  precharge.value = precharge.value.replace(/[^\d]/g, '')
  pmoneyIndex.value = precharge.value == '' ? 30 : 0
  if (precharge.value == '') {
    pmoneyTipShow.value = false
  }
}

//  紫钻失去焦点
const changeBlurP = () => {
  if (precharge.value != '') {
    pmoneyTipShow.value = precharge.value < 10
    pmoneyTipShowMax.value = precharge.value >= 149
    if (precharge.value < 10) {
      pmoneyObj.value = {
        price: 0,
        paper: 0
      }
      return false
    }
    if (precharge.value > 149) {
      pmoneyIndex.value = 149
      precharge.value = 149
    } else {
      pmoneyIndex.value = precharge.value
    }
    if (!pmoneyTipShow.value) {
      // getPurpleMoney()
      pmoneyObj.value = {
        price: pmoneyIndex.value,
        paper: calculatePaperPrice(pmoneyIndex.value)
      }
    }
  }
}

// 橙钻选择充值金额
const moneyToken = val => {
  moneyTipShow.value = false
  if (val == moneyIndex.value) {
    moneyIndex.value = 0
    moneyObj.value = {
      price: 0,
      tokens: 0,
      word: 0
    }
  } else {
    moneyIndex.value = val
    recharge.value = ''
    // getOrangeMoney()
    const {token, word} = calculateTokenAndWordPrice(moneyIndex.value)
    moneyObj.value = {
      price: moneyIndex.value,
      tokens: token,
      word: word
    }
  }
}

// 紫钻选择充值金额
const moneyTokenP = val => {
  pmoneyTipShow.value = false
  pmoneyTipShowMax.value = false
  if (val == pmoneyIndex.value) {
    pmoneyIndex.value = 0
    pmoneyObj.value = {
      price: 0,
      paper: 0
    }
  } else {
    pmoneyIndex.value = val
    precharge.value = ''
    if (val == '包月') {
      pmoneyIndex.value = 149
    }
    // getPurpleMoney()
    // calculatePaperPrice(pmoneyIndex.value)
    pmoneyObj.value = {
      price: pmoneyIndex.value,
      paper: calculatePaperPrice(pmoneyIndex.value)
    }
  }
}

// 紫钻充值金额换算张
const calculatePaperPrice = price => {
  let paper = ''
  if (price >= purpleData.value.length) {
    paper = '绘图包月'
  } else {
    paper = purpleData.value[price]
  }
  return paper
}

// 橙钻充值金额换算
const calculateTokenAndWordPrice = price => {
  let token = 0,
    word = 0
  if (price >= orangeData.value.length) {
    token = Math.round((Number(price) / 0.8) * 400)
    word = Math.round((Number(price) / 0.8) * 200)
  } else {
    token = Math.round((price / orangeData.value[price]) * 400)
    word = Math.round((price / orangeData.value[price]) * 200)
  }
  return {token, word}
}

// tab切换
const tabChange = item => {
  store.commit('common/setVipType', item.value)
  getListsData()
  recharge.value = ''
  precharge.value = ''
  if (vipType.value == 'orange') {
    moneyIndex.value = 30
    // getOrangeMoney()
    const {token, word} = calculateTokenAndWordPrice(moneyIndex.value)
    moneyObj.value = {
      price: moneyIndex.value,
      tokens: token,
      word: word
    }
  }
  if (vipType.value == 'purple') {
    pmoneyIndex.value = 30
    // getPurpleMoney()
    pmoneyObj.value = {
      price: pmoneyIndex.value,
      paper: calculatePaperPrice(pmoneyIndex.value)
    }
  }
}

// 微信支付
const orderCreate = item => {
  if (!localStorage.getItem('token')) {
    ElMessage.warning('请登录购买VIP套餐')
    setTimeout(() => {
      store.commit('common/setVipsMask', false)
      store.commit('common/setLoginMask', true)
    }, 3000)
    return false
  }
  if (vipType.value == 'blue') {
    store.dispatch('common/orderCreate', {item})
  }
  if (vipType.value == 'orange') {
    if (moneyIndex.value > 0) {
      store.dispatch('common/orderCreate', {
        item,
        price: moneyIndex.value
      })
    }
  }
  if (vipType.value == 'purple') {
    if (pmoneyIndex.value == 149) {
      store.dispatch('common/orderCreate', {
        item: purpleObj1.value,
        price: pmoneyIndex.value
      })
      return false
    }
    if (pmoneyIndex.value > 0) {
      store.dispatch('common/orderCreate', {
        item,
        price: pmoneyIndex.value
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.vip {
  position: fixed;
  top: 0;
  left: $width;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);

  .vipMian {
    width: 1100px;
    height: 616px;
    background: #ffffff;
    box-shadow: 0px 0px 48px 0px rgba(50, 50, 50, 0.03),
    0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    margin: auto;
    position: absolute;
    top: 0;
    left: calc((-#{$width} - 60px) / 2);
    right: 0;
    bottom: 0;

    .vip_tab {
      height: 50px;
      background: #3e3f4f;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      display: flex;
      align-items: center;
      margin-top: -1px;

      .item {
        width: 366.67px;
        height: 100%;
        font-size: 16px;
        text-align: center;
        line-height: 50px;
        position: relative;
        cursor: pointer;

        & > img {
          display: none;
        }

        img {
          vertical-align: middle;
          margin-right: 8px;
        }

        .activeImg {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
        }
      }
    }

    .topdesc {
      font-size: 16px;
      color: #3e3f4f;
      line-height: 22px;
      text-align: center;
      margin: 27px auto 30px;

      span {
        color: #389bfa;
      }

      .other {
        font-weight: bold;
        color: #3e3f4f;
      }
    }

    .topdescorange {
      span {
        color: #f98d29;
      }

      .other {
        font-weight: bold;
        color: #3e3f4f;
      }
    }

    .topdescpurple {
      span {
        color: #9d50ff;
      }

      .other {
        font-weight: bold;
        color: #3e3f4f;
      }
    }

    .buymodule {
      display: flex;
      align-items: center;
      justify-content: center;

      .item {
        width: 234px;
        height: 365px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0 0 10px #bebebe;
        box-sizing: border-box;
        margin-right: 31px;
        overflow: hidden;

        .top {
          width: 100%;
          height: 87px;
          position: relative;

          img {
            width: 64px;
            height: 48px;
            position: absolute;
            bottom: 0;
            right: 4px;
            display: none;
          }
        }

        &:nth-child(1) {
          .top {
            background: url(../../assets/images/img1.png) no-repeat center center;
            background-size: 100% 100%;
          }
        }

        &:nth-child(2) {
          .top {
            background: url(../../assets/images/img2.png) no-repeat center center;
            background-size: 101% 100%;
          }
        }

        &:nth-child(3) {
          .top {
            background: url(../../assets/images/img3.png) no-repeat center center;
            background-size: 100% 100%;
          }
        }

        &:nth-child(4) {
          .top {
            background: url(../../assets/images/img4.png) no-repeat center center;
            background-size: 100% 100%;

            img {
              display: block;
            }

            .productName {
              color: #464766;
            }

            .productTag {
              color: #8a8a8a;
            }
          }
        }

        .bot {
          padding-left: 17px;
          position: relative;
          height: calc(100% - 87px);
          position: relative;
        }

        &:last-child {
          margin-right: 0;
        }

        .productName {
          font-size: 18px;
          color: #ffffff;
          line-height: 30px;
          padding-top: 16px;
          padding-left: 21px;
        }

        .productTag {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 14px;
          padding-top: 9px;
          padding-left: 21px;
        }

        .price {
          display: inline-block;
          margin-top: 10px;
          font-size: 0;

          .money {
            font-size: 32px;
            color: #3e3f4f;
            margin-right: 5px;
          }

          .unit1 {
            font-size: 32px;
            color: #3e3f4f;
            line-height: 21px;
            margin-right: 9px;
          }

          .unit2 {
            font-size: 14px;
            color: #757575;
            line-height: 14px;
          }
        }

        .tag {
          display: flex;
          align-items: center;
          position: absolute;
          top: 10px;
          right: 10px;

          span {
            padding: 1px 5px;
            border: 1px solid rgb(184, 57, 57);
            color: rgb(184, 57, 57);
            font-size: 12px;
            border-radius: 4px;
            margin-right: 4px;
          }
        }

        .preferential {
          color: #ff7a04;
          font-size: 12px;
          position: absolute;
        }

        .preferentprice {
          display: inline-block;
          color: #ff7a04;

          .money {
            color: #ff7a04;
          }

          .unit1,
          .unit2 {
            color: #ff7a04;
          }
        }

        .priceyuan {
          display: inline-block;
          margin-left: 10px;
          position: relative;

          .money {
            color: #b3b3b3;
            font-size: 12px;
          }

          .unit1 {
            margin-right: 0;
          }

          .unit1,
          .unit2 {
            color: #b3b3b3;
            font-size: 12px;
          }

          .line {
            width: 100%;
            height: 2px;
            border-radius: 8px;
            background: #b3b3b3;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
          }
        }

        .desc {
          margin-top: 20px;

          & > div {
            font-size: 12px;
            color: #7c7d8c;
            line-height: 12px;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              vertical-align: middle;
            }
          }
        }

        .share {
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          bottom: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            width: 14px;
            height: 14px;
            margin-right: 4px;
          }

          span {
            font-size: 12px;
            color: #f87c05;
            text-decoration: underline;
          }
        }

        .btns {
          display: flex;
          align-items: center;
          margin: auto;
          position: absolute;
          bottom: 14px;

          img {
            width: 36px;
            height: 35px;
            margin-right: 9px;
          }

          .buy {
            width: 136px;
            height: 35px;
            background: #464766;
            border-radius: 6px;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            line-height: 35px;
            cursor: pointer;
          }

          .hastoken {
            background: #ccc;
            cursor: not-allowed;
          }
        }
      }
    }

    .tips {
      font-size: 13px;
      font-weight: 400;
      color: #85858f;
      line-height: 13px;
      text-align: center;
      margin-top: 30px;
      line-height: 24px;
      margin-left: 28px;

      span {
        font-weight: bold;
      }
    }

    .buymoduleorange {
      width: 713px;
      height: 377px;
      margin: 0 auto;
      border-radius: 10px;
      // border: 1px solid #bebebe;
      box-sizing: border-box;
      box-shadow: 0 0 10px #bebebe;

      .title {
        width: 100%;
        height: 63px;
        vertical-align: top;
        margin-top: -1px;
      }

      .moneyBox {
        // height: calc(100% - 63px);
        display: flex;

        .left {
          width: 435px;
          margin-left: 25px;
          margin-top: 17px;

          .price {
            display: flex;
            align-items: center;

            .item {
              width: 100px;
              height: 120px;
              background: linear-gradient(
                  180deg,
                  rgba(255, 244, 227, 0.4) 0%,
                  #fff3e0 100%
              );
              border-radius: 4px;
              margin-right: 12px;
              text-align: center;
              overflow: hidden;
              position: relative;

              &:last-child {
                margin-right: 0;
              }

              .p1 {
                font-size: 12px;
                color: #3e3f4f;
                margin-top: 12px;
              }

              .p2 {
                color: #f87c05;
                font-size: 24px;
                line-height: 28px;
                margin-top: 4px;
                margin-bottom: 10px;
              }

              .p3 {
                color: #7c7d8c;
                font-size: 12px;
              }

              .p4 {
                color: #7c7d8c;
                font-size: 12px;
                text-decoration: line-through;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 30px;
              }
            }
          }

          .pricetip {
            font-size: 14px;
            color: #3e3f4f;
            margin-bottom: 9px;
            margin-top: 15px;

            span {
              color: #7c7d8c;
              font-size: 12px;
            }

            .inputtip {
              font-size: 12px;
              color: #bd3124;
            }
          }

          .moneys {
            display: flex;

            .moneychange {
              width: 307px;
              display: flex;
              flex-wrap: wrap;
              position: relative;

              .item {
                width: 60px;
                height: 30px;
                border-radius: 2px;
                border: 1px solid #bebebe;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: #3e3f4f;
                margin-right: 14px;
                margin-bottom: 8px;
                cursor: pointer;

                &:nth-child(4n) {
                  margin-right: 0;
                }
              }

              .active {
                border: 1px solid #f87c05;
                color: #f87c05;
                background: rgba(248, 124, 5, 0.07);
              }

              :deep(.otherItem) {
                width: 134px;
                height: 30px;
                border-radius: 2px;
                opacity: 1;
                border: 1px solid #bebebe;

                .el-input__wrapper {
                  box-shadow: none;
                }
              }
            }

            .moneytip {
              width: 143px;
              height: 67px;
              background: #f8f8f8;
              border-radius: 8px;

              p {
                margin-top: 11px;
                text-align: right;
                margin-right: 4px;

                &:last-child {
                  margin-top: 0;
                  margin-right: 18px;
                  color: #7c7d8c;
                }
              }

              ul {
                display: flex;
                margin-top: 11px;
                justify-content: flex-end;

                li {
                  height: 25px;
                  line-height: 25px;
                  font-size: 0;

                  span {
                    display: block;
                    color: #3e3f4f;

                    &:last-child {
                      color: #7c7d8c;
                    }
                  }
                }
              }

              .s1 {
                font-size: 18px;
              }

              .s2 {
                font-size: 14px;
              }

              .s3 {
                font-size: 12px;
              }
            }
          }
        }

        .line {
          width: 2px;
          height: 225px;
          background: #ebebeb;
          margin: 19px 15px 0;
        }

        .right {
          margin-top: 27px;

          .item {
            font-size: 12px;
            color: #7c7d8c;
            line-height: 12px;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .purplebox {
      display: flex;
      margin: 0 62px;
      justify-content: space-between;

      .buymodule {
        .item {
          width: 234px;
          height: 377px;

          .top {
            background: url(../../assets/images/img4.png) no-repeat center center;
            background-size: 100% 100%;
            height: 63px;

            .productName {
              color: #464766;
            }
          }

          .bot {
            height: calc(100% - 63px);

            .price {
              margin-top: 28px;
            }

            .btns {
              bottom: 23px;
            }
          }

          .desc {
            margin-top: 25px;
          }
        }
      }
    }

    .purple {
      margin: 0;

      .moneyBox {
        .left {
          .price {
            .item {
              background: linear-gradient(
                  180deg,
                  rgba(244, 237, 255, 0.4) 0%,
                  #eee3ff 100%
              );
              position: relative;

              .cz {
                width: 24px;
                height: 15px;
                position: absolute;
                right: 0;
                top: 0;
              }

              .p1 {
                margin-top: 20px;
              }

              .p2 {
                color: #9d50ff;
                margin-top: 8px;
              }
            }
          }

          .moneys {
            .moneychange {
              .active {
                background: rgba(141, 5, 248, 0.07);
                border: 1px solid #9c05f8;
                color: #9c05f8;
              }
            }

            .moneytip {
              ul {
                margin-top: 20px;
                justify-content: center;

                li {
                  margin: 0 2px;

                  span {
                    color: #0d0d0d !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .paybtns {
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-top: 10px;
      img {
        width: 36px;
        height: 36px;
        margin-right: 12px;
      }

      .buy {
        width: 617px;
        height: 36px;
        background: #464766;
        border-radius: 6px;
        text-align: center;
        line-height: 36px;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
