<!-- eslint-disable no-undef -->
<!-- 头部 -->
<template>
  <div class="headerMain">
    <div class="top">
      <template v-if="routeDefault">
        <span v-if="chatList[chatKey] && chatList[chatKey][chatListCurrent]">{{
          chatList[chatKey][chatListCurrent].title
        }}</span>
        <span v-else>New Chat</span>
      </template>
      <div class="userinfo">
        <div class="nologin" v-if="userName == ''">
          <span @click="loginSubmit">登录</span>
          <span @click="registerMask">注册</span>
        </div>
        <div class="haslogin" v-if="userName && userName != ''">
          <div class="identifying">
            <img v-if="blue" src="../../assets/images/blue.png" />
            <img v-if="orange" src="../../assets/images/orange.png" />
            <img v-if="purple" src="../../assets/images/purple.png" />
          </div>
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ userName }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="userInfoclick">
                  个人中心
                </el-dropdown-item>
                <el-dropdown-item @click="loginout">退出</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, ref, watch } from 'vue'
import { clearStorage } from '@/utils/common'
import { useStore } from 'vuex'
const store = useStore()
import { loginOut } from '@/api/common'
import router from '../../router'
import { ElMessage } from 'element-plus'

const chatList = computed(() => store.state.chat.chatList)
const chatKey = computed(() => store.state.chat.chatKey)
const chatListCurrent = computed(() => store.state.chat.chatListCurrent)
const userName = computed(() => store.state.common.userName)
const routeDefault = computed(() => store.state.common.routeDefault)
const diamond = computed(() => store.state.common.diamond)
const blue = ref(false)
const orange = ref(false)
const purple = ref(false)
onMounted(() => {
  if (localStorage.getItem('uname') && localStorage.getItem('token')) {
    store.commit('common/setUserName', localStorage.getItem('uname'))
  }
  if (localStorage.getItem('diamond')) {
    if (localStorage.getItem('diamond').indexOf('blue') != -1) {
      blue.value = true
    } else {
      blue.value = false
    }
    if (localStorage.getItem('diamond').indexOf('orange') != -1) {
      orange.value = true
    } else {
      orange.value = false
    }
    if (localStorage.getItem('diamond').indexOf('purple') != -1) {
      purple.value = true
    } else {
      purple.value = false
    }
  }
  if (
    router.currentRoute.value.path != '/chat' &&
    router.currentRoute.value.path != '/draw'
  ) {
    store.commit('common/setRouteDefault', false)
  }
})

watch(diamond, newv => {
  if (newv.includes('blue')) {
    blue.value = true
  } else {
    blue.value = false
  }
  if (newv.includes('orange')) {
    orange.value = true
  } else {
    orange.value = false
  }
  if (newv.includes('purple')) {
    purple.value = true
  } else {
    purple.value = false
  }
})

// 个人中心
const userInfoclick = () => {
  store.commit('common/setUserInfoMask', true)
}

// 登录显示弹窗
const loginSubmit = () => {
  store.commit('common/setFreeTips', false)
  store.commit('common/setLoginMask', true)
}

// 到注册页面
const registerMask = () => {
  router.push('/register')
}

// 退出
const loginout = () => {
  loginOut().then(res => {
    if (res.data.code == 0) {
      router.push('/')
      store.commit('chat/setReloadWeb', false)
      clearStorage()
      ElMessage.success('您已退出登录')
      store.commit(
        'common/setPlaceholder',
        '请直接在此输入对话内容。您也可以点击左侧开启新聊天，通过我们为您提供的提示词分类辅助进行对话'
      )
      store.commit(
        'common/setDrawPlaceholder',
        `请直接在此描绘你心中画面的样子，尽量详细，可以使用一些风格修饰词。您也可以点击“使用提示词”分类铺助进行绘制。`
      )
      store.dispatch('chat/getListData', 0)
    }
  })
}
</script>
<style lang="scss" scoped>
.headerMain {
  width: 100%;
  height: $top-height;
}
.top {
  width: 100%;
  text-align: center;
  background: #3d404f;
  height: 100%;
  font-size: 26px;
  line-height: $top-height;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
}

.userinfo {
  position: absolute;
  margin: auto;
  right: 20px;
  top: 0;
  bottom: 0;
  & > div {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .nologin {
    span {
      font-size: 14px;
      padding: 0 5px;
      cursor: pointer;
    }
  }
  .haslogin {
    .el-dropdown {
      color: #fff;
      cursor: pointer;
    }
  }
  .identifying {
    display: flex;
    align-items: center;
    margin-right: 10px;
    img {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      margin-right: 5px;
      &:last-child {
        width: 25px;
        height: 23px;
        margin-top: -2px;
      }
    }
  }
}
</style>
<style lang="scss">
.el-tooltip__trigger {
  outline: none !important;
}
</style>
