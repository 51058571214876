<!-- 选择会话后单独模块 -->
<template>
  <div
    class="dialogue"
    v-if="
      chatList[chatKey] &&
      chatList[chatKey].length > 0 &&
      chatList[chatKey][chatListCurrent].dialogueShow
    "
  >
    <div class="dialogue_main">
      <div class="top">
        <div class="title">
          <span>{{ chatObj.title }}</span>
        </div>
        <div class="copy" @click="copyText">复制</div>
      </div>
      <div class="desc">{{ chatObj.description }}</div>
      <div class="question" @click="changeEn" v-if="!chatObj.enShow">
        {{ chatObj.prompt }}
      </div>
      <div class="question" @click="changeEn" v-else>
        {{ chatObj.prompt_en }}
      </div>
      <div class="btns" @click="useClick" v-if="!use">
        <el-tooltip :content="useContent" effect="customized" placement="top">
          <el-button>使用</el-button>
        </el-tooltip>
      </div>

      <div class="btns use" @click="useClick" v-else>
        <el-tooltip
          :content="useContenting"
          effect="customized"
          placement="top"
        >
          <el-button>已使用</el-button>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import useClipboard from 'vue-clipboard3'
import { ElMessage } from 'element-plus'
const { toClipboard } = useClipboard()
import { reactive, toRefs, computed, onMounted, watch } from 'vue'
const initData = reactive({
  use: false,
  useContent: '点击后，您发送的对话内容会自动添加提示词',
  useContenting: '点击后，您发送的对话内容不会添加提示词'
})
const { use, useContent, useContenting } = toRefs(initData)

import { useStore } from 'vuex'
const store = useStore()
const chatObj = computed(() => store.state.chat.chatObj)
// eslint-disable-next-line no-undef
const emit = defineEmits(['tipmsg'])
const chatList = computed(() => store.state.chat.chatList)
const chatKey = computed(() => store.state.chat.chatKey)
const chatListCurrent = computed(() => store.state.chat.chatListCurrent)

watch(
  () => chatList.value,
  (newv, oldv) => {
    use.value = false
    useContent.value = '点击后，您发送的对话内容会自动添加提示词'
    useContenting.value = '点击后，您发送的对话内容不会添加提示词'
    store.commit('chat/setChatValue', '')
  },
  { deep: true }
)
// 中英文切换
const changeEn = () => {
  if (use.value) {
    return false
  }
  chatObj.value.enShow = !chatObj.value.enShow
  store.commit('chat/setChatObj', chatObj.value)
}

// 使用提示语
const useClick = () => {
  use.value = !use.value
  emit('tipmsg', use.value ? chatObj.value : '')
}

// 复制
const copyText = async () => {
  let val = ''
  if (!chatObj.value.enShow) {
    val = chatObj.value.prompt
  } else {
    val = chatObj.value.prompt_en
  }
  try {
    await toClipboard(val)
    ElMessage.success('复制成功')
  } catch (e) {
    console.error('失败')
  }
}
</script>
<style lang="scss" scoped>
.dialogue {
  width: 800px;
  height: 442px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: translateX(100px);
  .dialogue_main {
    width: 800px;
    height: 442px;
    border-radius: 10px;
    background: url(../../assets/images/everybg.svg) center center no-repeat;
    background-size: 100% 100%;
    padding: 25px 20px;
    box-sizing: border-box;
    position: relative;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        span {
          font-size: 20px;
          color: #fff;
        }
      }
      .copy {
        cursor: pointer;
        width: 54px;
        height: 28px;
        background: rgba(255, 226, 201, 0.3);
        border-radius: 2px;
        font-size: 13px;
        color: #fa7905;
        line-height: 28px;
        text-align: center;
      }
    }
    .desc {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
      line-height: 24px;
      height: 22px;
      overflow: hidden;
      position: absolute;
      z-index: 10;
      top: 62px;
      left: 20px;
      right: 20px;
      padding: 5px;
      box-sizing: content-box;
      &:hover {
        height: auto;
        max-height: 305px;
        background: url(../../assets/images/descimg.jpg) no-repeat top center;
        background-size: 100% auto;
        overflow-y: auto;
      }
    }
    .question {
      height: 250px;
      padding: 16px;
      background: rgba(237, 238, 244, 0.12);
      font-size: 15px;
      line-height: 18px;
      margin: 10px 0;
      margin-top: 61px;
      color: #ffefe7;
      overflow-y: auto;
      cursor: pointer;
      box-sizing: border-box;
    }
    .btns {
      cursor: pointer;
      :deep(.el-button) {
        display: block;
        border: none;
        color: #fff;
        line-height: 39px;
        text-align: center;
        font-size: 13px;
        margin-top: 20px;
        padding: 0;
        width: 760px;
        height: 39px;
        background: #f27809;
        border-radius: 2px;
      }
    }
    .use {
      :deep(.el-button) {
        background: #a4adb3;
        color: #fff;
      }
    }
  }
}
</style>
<style>
.el-popper.is-customized {
  padding: 6px 12px;
  background-color: #fff0e1;
  color: #f27809;
}
.el-popper.is-customized .el-popper__arrow::before {
  background-color: #fff0e1;
  color: #f27809;
  right: 0;
}
</style>
