<!-- eslint-disable no-undef -->
<!-- 聊天对话 -->
<template>
  <div>
    <template
      v-if="
        chatList[chatKey] &&
        chatList[chatKey][chatListCurrent] &&
        chatList[chatKey][chatListCurrent].type == 'chat'
      "
    >
      <div
        class="list_item"
        v-for="(item, i) in chatList[chatKey][chatListCurrent].list"
        :key="i"
        :class="item.role == 'A' ? '' : 'self'"
      >
        <div class="user" v-if="item.role == 'A'">
          <img src="../../assets/images/chat.svg" class="avatar" />神算子
        </div>
        <div class="user" v-if="item.role == 'Q'">
          <img src="../../assets/images/user.svg" class="avatar" />
          {{ userName == '' ? 'User' : userName }}
        </div>
        <div
          class="content"
          :id="`content${i}`"
          v-html="item.content"
          :style="{
            background: item.role == 'A' ? '#2F3553' : '#9396A9'
          }"
        ></div>
      </div>
      <div class="list_item other_item" v-if="loading">
        <div class="user">
          <img src="../../assets/images/chat.svg" class="avatar" />
          <div>
            <span>神算子</span>
            <span class="content cloading" v-if="!timeoOut">
              我正在整理思路和组织语言，请稍等...
            </span>
            <span class="content cloading" id="tips" v-if="timeoOut"></span>
          </div>
        </div>
        <div>
          <span class="blinkCursor">|</span>
        </div>
      </div>
      <div class="clearfix"></div>
    </template>
  </div>
</template>

<script setup>
import { computed, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

const chatList = computed(() => store.state.chat.chatList)
const chatKey = computed(() => store.state.chat.chatKey)
const chatListCurrent = computed(() => store.state.chat.chatListCurrent)
const loading = computed(() => store.state.common.loading)
const userName = computed(() => store.state.common.userName)
const timeoOut = computed(() => store.state.common.timeoOut)
let tips =
  '由于网络延迟及AI思考及回复文字数量多导致传输量大等原因，当您觉得长时间（30秒以上）没有响应或发生请求错误时，尝试在问题后限定回答字数，可大概率成功对话。例如“……。回答字数限制在200字内。”'
watch(timeoOut, newv => {
  if (newv) {
    nextTick(() => {
      sureSendWay(tips)
    })
  }
})
let timer = null
// 开始
const sureSendWay = str => {
  let divTyping = document.getElementById(`tips`)
  let i = 0
  // 插入回答数据的打字效果
  function typing() {
    if (i <= str.length) {
      divTyping.innerHTML = str.slice(0, i++)
      timer = setTimeout(() => {
        typing()
      }, 50)
      if (i == str.length) {
        clearTimeout(timer)
        timer = null
      }
    }
  }
  typing()
}
// sureSendWay(res.msg, chatList.value[chatListCurrent.value].list.length - 1)
</script>
<style lang="scss" scoped>
.list_item {
  width: 100%;
  padding: 10px $main-width;
  box-sizing: border-box;
  .user {
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    display: flex;
    align-items: center;
    margin-left: -30px;
    color: #333;
    .avatar {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
  }
  .content {
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    padding: 10px;
  }
}

.other_item {
  .user {
    align-items: flex-start;
    .avatar {
      margin-top: 2px;
    }
  }
}
.self {
  background: #fff;
  border: none;
}
.loading {
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  margin: auto;
  height: 40px;
  width: 10%;
  z-index: 11;
  background: transparent;
}
.cloading {
  background: transparent;
  color: #888 !important;
}
.blinkCursor {
  color: #888 !important;
  font-weight: bold;
  animation: Blink 1s ease-in 0s infinite;
}

@keyframes Blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
