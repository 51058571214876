// 验证码
export const draw = (show_num, true_code) => {
  let canvas_width = document.querySelector('#canvas').clientWidth
  let canvas_height = document.querySelector('#canvas').clientHeight
  let canvas = document.getElementById('canvas') //获取到canvas
  let context = canvas.getContext('2d') //获取到canvas画图
  canvas.width = canvas_width
  canvas.height = canvas_height
  let sCode =
    'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0'
  let aCode = sCode.split(',')
  let aLength = aCode.length //获取到数组的长
  //4个验证码数
  for (let i = 0; i <= 3; i++) {
    let j = Math.floor(Math.random() * aLength) //获取到随机的索引值
    let deg = (Math.random() * 30 * Math.PI) / 180 //产生0~30之间的随机弧度
    let txt = aCode[j] //得到随机的一个内容
    show_num[i] = txt.toLowerCase() // 依次把取得的内容放到数组里面
    let x = 10 + i * 20 //文字在canvas上的x坐标
    let y = 20 + Math.random() * 8 //文字在canvas上的y坐标
    context.font = 'bold 23px 微软雅黑'

    context.translate(x, y)
    context.rotate(deg)

    context.fillStyle = randomColor()
    context.fillText(txt, 0, 0)

    context.rotate(-deg)
    context.translate(-x, -y)
  }
  //验证码上显示6条线条
  for (let i = 0; i <= 5; i++) {
    context.strokeStyle = randomColor()
    context.beginPath()
    context.moveTo(Math.random() * canvas_width, Math.random() * canvas_height)
    context.lineTo(Math.random() * canvas_width, Math.random() * canvas_height)
    context.stroke()
  }
  //验证码上显示31个小点
  for (let i = 0; i <= 30; i++) {
    context.strokeStyle = randomColor()
    context.beginPath()
    let x = Math.random() * canvas_width
    let y = Math.random() * canvas_height
    context.moveTo(x, y)
    context.lineTo(x + 1, y + 1)
    context.stroke()
  }

  //最后把取得的验证码数组存起来，方式不唯一
  let num = show_num.join('')
  true_code.value = num
}

//得到随机的颜色值
const randomColor = () => {
  let r = Math.floor(Math.random() * 256)
  let g = Math.floor(Math.random() * 256)
  let b = Math.floor(Math.random() * 256)
  return 'rgb(' + r + ',' + g + ',' + b + ')'
}
