<template>
  <div class="mregister">
    <div class="banner">
      <img src="../assets/images/mregister.png" />
      <p>
        注册智聊Chat，免费体验国内顶尖AI模型智能聊天、代写、文案修改、代码编写、绘图、修图！
      </p>
    </div>
    <div class="mregistermain">
      <p>
        填写邀请码，注册成功并登录一次后您与朋友将同时额外<span>获得2000tokens</span>免费额度！
      </p>
      <div
        class="form_item"
        :style="{
          border:
            phoneTips.indexOf('该手机号已注册') != -1 ? '1px solid red' : ''
        }"
      >
        <span class="form_label">手机号</span>
        <input
          type="text"
          v-model="firstForm.phone"
          class="input"
          @input="phone"
          placeholder="请输入"
          :maxlength="11"
        />
        <img
          src="../assets/images/iosclose.png"
          @click="deletePhone"
          class="close"
        />
        <div
          class="tips"
          :style="{
            bottom:
              phoneTips.indexOf('该手机号已注册') != -1 ? '-0.42rem' : '-0.4rem'
          }"
        >
          {{ phoneTips }}
        </div>
      </div>
      <div class="form_item">
        <span class="form_label">验证码</span>
        <input
          type="text"
          class="input"
          v-model="firstForm.noteCode"
          @blur="submitForm"
          placeholder="请输入"
          :maxlength="6"
          :disabled="yzmFlag"
        />
        <el-button
          :disabled="yzmCodeLabel != '获取验证码'"
          type="primary"
          class="getCode"
          @click="getCode"
        >
          {{ yzmCodeLabel }}
        </el-button>
        <div class="tips">{{ codeTips }}</div>
      </div>
      <div
        class="form_item"
        :style="{
          border:
            nameTips.indexOf('该用户名已被使用') != -1 ? '1px solid red' : ''
        }"
      >
        <span class="form_label">用户名</span>
        <input
          type="text"
          v-model="secondForm.username"
          class="input"
          @input="username"
          placeholder="请输入"
        />
        <div
          class="tips"
          :style="{
            bottom:
              nameTips.indexOf('该用户名已被使用') != -1
                ? '-0.42rem'
                : '-0.4rem'
          }"
        >
          {{ nameTips }}
        </div>
      </div>
      <div class="form_item">
        <span class="form_label">密&#12288;码</span>
        <input
          type="password"
          class="password"
          @input="psd"
          v-model="secondForm.psd"
          placeholder="至少含英文和字母，8-16字"
        />
        <div class="tips">{{ pwdTips }}</div>
      </div>
      <div class="form_item">
        <span class="form_label">邀请码</span>
        <input
          type="text"
          v-model="invitationCode"
          class="input"
          placeholder="请输入"
        />
      </div>
    </div>
    <div class="submit" @click="submit">注册</div>
  </div>
</template>

<script setup>
import md5 from 'js-md5'
import { reactive, toRefs, onMounted } from 'vue'
import { validatePhoneNumber } from '@/utils/validate'
import { ElMessage } from 'element-plus'
import {
  sendRegisterMsgNew,
  verifyRegisterMsgNew,
  saveUserInfoNew
} from '@/api/common'
import { useRouter } from 'vue-router'
const router = useRouter()
const initData = reactive({
  yzmCodeLabel: '获取验证码',
  firstForm: {
    phone: '',
    noteCode: ''
  },
  secondForm: {
    username: '',
    psd: ''
  },
  userId: '',
  phoneTips: '',
  nameTips: '',
  codeTips: '',
  pwdTips: '',
  invitationCode: '',
  flag: false,
  yzmFlag: false,
  codeFlag: false
})

const {
  active,
  firstForm,
  secondForm,
  yzmCodeLabel,
  userId,
  phoneTips,
  nameTips,
  codeTips,
  pwdTips,
  invitationCode,
  flag,
  yzmFlag,
  codeFlag
} = toRefs(initData)

onMounted(() => {
  if (router.currentRoute.value.query.invitationCode) {
    invitationCode.value = router.currentRoute.value.query.invitationCode
  }
  const href = window.location.href.split('#')[0]
  let script = document.createElement('script')
  script.src = `${href}/static/js/flexible.js`
  document.body.appendChild(script)
  if (localStorage.getItem('forgetpsd')) {
    active.value = JSON.parse(localStorage.getItem('forgetpsd')).step
    userId.value = JSON.parse(localStorage.getItem('forgetpsd')).id
    setTimeout(() => {
      localStorage.removeItem('forgetpsd')
    }, 3000)
  }
})

let timer = null

// 手机号验证
const phone = () => {
  if (firstForm.value.phone == '') {
    phoneTips.value = '请输入手机号'
  } else if (!validatePhoneNumber(firstForm.value.phone)) {
    phoneTips.value = '请输入正确手机号'
  } else {
    phoneTips.value = ''
  }
}

const reg = /^(?=.*[a-zA-Z])(?=.*\d).{8,16}$/
// 密码验证
const psd = () => {
  if (secondForm.value.psd == '') {
    pwdTips.value = '请输入密码'
  } else if (!reg.test(secondForm.value.psd)) {
    pwdTips.value = '密码8-16位，至少需包含英文、数字'
  } else {
    pwdTips.value = ''
  }
}

const numberRegex = /^\d+$/
// 用户名验证
const username = () => {
  if (secondForm.value.username == '') {
    nameTips.value = '请输入用户名'
  } else if (numberRegex.test(secondForm.value.username)) {
    nameTips.value = '用户名不能为纯数字'
  } else {
    nameTips.value = ''
  }
}

// 清空手机号
const deletePhone = () => {
  firstForm.value.phone = ''
}

// 下一步 验证短信
const submitForm = () => {
  if (firstForm.value.noteCode != '') {
    codeTips.value = ''
  }
  if (yzmFlag.value) {
    return false
  }
  if (firstForm.value.phone == '') {
    phoneTips.value = '请输入手机号'
    return false
  }
  if (firstForm.value.noteCode == '') {
    codeTips.value = '请输入验证码'
    return false
  }
  verifyRegisterMsgNew({
    phone: firstForm.value.phone,
    code: firstForm.value.noteCode
  })
    .then(({ data: res, status }) => {
      if (status == 200) {
        if (res.code == 2) {
          userId.value = res.data.userId
          yzmFlag.value = true
        }
      }
      if (status == 202) {
        if (res.code == 0) {
          codeTips.value = '验证码校验失败，请重新获取'
        }
        if (res.code == 1) {
          ElMessage.warning(res.msg)
        }
      }
    })
    .catch(() => {})
}

// 注册
const submit = () => {
  if (phoneTips.value.indexOf('该手机号已注册') != -1) {
    return false
  }
  if (userId.value == '') {
    return false
  }
  if (firstForm.value.phone == '') {
    phoneTips.value = '请输入手机号'
    return false
  }
  if (firstForm.value.noteCode == '') {
    codeTips.value = '请输入验证码'
    return false
  }
  if (secondForm.value.username == '') {
    nameTips.value = '请输入用户名'
    return false
  }
  if (secondForm.value.psd == '') {
    pwdTips.value = '请输入密码'
    return false
  }
  if (flag.value) {
    return false
  }
  flag.value = true
  const data = {
    userName: secondForm.value.username,
    pwd: md5(secondForm.value.psd),
    userId: userId.value
  }
  if (invitationCode.value != '') {
    data.invitationCode = invitationCode.value
  }
  saveUserInfoNew(data)
    .then(({ data: res, status }) => {
      flag.value = false
      if (status == 200) {
        if (res.code == 1) {
          yzmFlag.value = false
          ElMessage.success(res.msg)
          setTimeout(() => {
            router.push('/success')
          }, 1000)
        }
      }
      if (status == 202) {
        if (res.code == 0) {
          if (res.msg.indexOf('该用户名已被使用') != -1) {
            nameTips.value = res.msg
          } else {
            ElMessage.warning(res.msg)
          }
        }
      }
    })
    .catch(() => {})
}

// 获取短信验证码
const getCode = () => {
  if (codeFlag.value) {
    return false
  }
  countDown()
  codeFlag.value = true
  if (yzmFlag.value) {
    return false
  }
  if (yzmCodeLabel.value == '获取验证码') {
    if (firstForm.value.phone == '') {
      phoneTips.value = '请输入手机号'
      return false
    }
    sendRegisterMsgNew({
      phone: firstForm.value.phone
    })
      .then(({ data: res, status }) => {
        codeFlag.value = false
        if (status == 200) {
          if (res.code == 0) {
            ElMessage.success(res.msg)
          } else {
            ElMessage.warning(res.msg)
          }
        }
        if (status == 202) {
          if (res.code == 0) {
            ElMessage.warning(res.msg)
          } else if (res.code == 1) {
            phoneTips.value = res.msg
            yzmFlag.value = true
          } else if (res.code == 2) {
            userId.value = res.data.userId
            ElMessage.warning(res.msg)
          }
          clearInterval(timer)
          yzmCodeLabel.value = '获取验证码'
        }
      })
      .catch(() => {
        clearInterval(timer)
        yzmCodeLabel.value = '获取验证码'
      })
  }
}

// 验证码倒计时
const countDown = () => {
  let second = 60
  const timer = setInterval(() => {
    second--
    if (second > 0) {
      yzmCodeLabel.value = `${second}s后重新发送`
    } else {
      clearInterval(timer)
      yzmCodeLabel.value = '获取验证码'
    }
  }, 1000)
}
</script>
<style lang="scss" scoped>
.mregister {
  max-width: 750px;
  min-width: 325px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 1.7333rem;
  .banner {
    width: 100%;
    height: 6.08rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    p {
      font-size: 12px;
      color: #e3e3e3;
      position: absolute;
      bottom: 7px;
      left: 15px;
      right: 15px;
    }
  }
  .mregistermain {
    p {
      font-size: 18px;
      color: #404040;
      margin: 0.5867rem 0.2267rem 0.3467rem;
      span {
        color: #ff7900;
      }
    }
    .form_item {
      width: 9.2rem;
      height: 1.2267rem;
      background: #f7f8fa;
      border-radius: 23px;
      margin: 0.4rem;
      display: flex;
      align-items: center;
      position: relative;
      .form_label {
        font-size: 0.3733rem;
        color: #333333;
        padding-left: 0.6933rem;
        padding-right: 0.48rem;
      }
      input {
        width: 60%;
        height: 100%;
        border: none;
        background: transparent;
        outline: none;
        font-size: 0.3733rem;
      }
      .close {
        width: 0.5067rem;
        height: 0.5067rem;
        position: absolute;
        right: 0.4rem;
        z-index: 10;
      }
    }
  }
  :deep(.getCode) {
    width: auto;
    padding: 0;
    background: transparent;
    border: none;
    color: #ff7a04;
    position: absolute;
    right: 21px;
  }
  .submit {
    width: 9.2rem;
    height: 1.2267rem;
    background: #464766;
    border-radius: 0.6133rem;
    margin: 2.24rem auto 0;
    text-align: center;
    line-height: 1.2267rem;
    font-size: 0.3733rem;
  }
  .tips {
    font-size: 0.2133rem;
    color: red;
    position: absolute;
    left: 0.4rem;
    bottom: -0.4rem;
  }
}
</style>
