<!-- 联系客服 -->
<template>
  <div class="service" @click="hideServiceMask">
    <div class="serviceMain" @click.stop>
      <img
        src="../../assets/images/serviceclose.png"
        class="close"
        @click="hideServiceMask"
      />
      <h6>联系我们</h6>
      <div class="desc" v-if="serviceContent">
        当您在使用过程中遇到问题，请按以下方式与我们联系，我们将及时为您解决问题
      </div>
      <div class="desc" v-if="!serviceContent">
        如果您想解锁全部聊天功能，请与我们公司联系，您将有机会成为我们的VIP用户，即可享受无限次的智能聊天与咨询服务，更有资格优先进行国内顶尖生成式模型、智能A画图、智能A图像识别等尖端产品免费试用。
      </div>
      <div class="unit">北京智言物语科技有限公司 客服部</div>
      <div class="info">
        <div class="item">
          <img src="../../assets/images/servicephone.png" />
          <span>18513810151</span>
        </div>
        <!-- <div class="item">
          <img src="../../assets/images/serviceemail.png" />
          <span>service@bpae.com</span>
        </div> -->
        <div class="item">
          <img src="../../assets/images/servicewx.png" />
          <img src="../../assets/images/servicecode.png" class="code" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const serviceContent = computed(() => store.state.common.serviceContent)
const hideServiceMask = () => {
  store.commit('common/setServiceMask', false)
}
</script>
<style lang="scss" scoped>
.service {
  position: fixed;
  top: 0;
  left: $width;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background: transparent;
  .serviceMain {
    width: 850px;
    height: 492px;
    border-radius: 10px;
    background: url(../../assets/images/servicebg.png) center center no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 25px;
    padding-left: 72px;
    padding-top: 55px;
    margin: auto;
    position: absolute;
    top: 0;
    left: calc((-#{$width} - 60px) / 2);
    right: 0;
    bottom: 0;
    z-index: 9999999;
    .close {
      position: absolute;
      top: 55px;
      right: 55px;
      cursor: pointer;
    }
    h6 {
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
      line-height: 33px;
      margin-bottom: 20px;
    }
    .desc {
      width: 717px;
      font-size: 16px;
      font-weight: 400;
      color: #ffefe7;
      line-height: 19px;
      margin-bottom: 54px;
    }
    .unit {
      font-size: 16px;
      font-weight: 400;
      color: #ffefe7;
      line-height: 19px;
      margin-bottom: 17px;
    }
    .info {
      .item {
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        span {
          font-size: 16px;
          font-weight: 400;
          color: #ffefe7;
          line-height: 19px;
        }
        .code {
          width: 106px;
          height: 106px;
        }
        &:last-child {
          align-items: flex-start;
        }
      }
    }
  }
}
</style>
