<!-- 提示弹窗 -->
<template>
  <div class="expert">
    <div class="expert_main">
      <img
        src="../../assets/images/close.svg"
        class="close"
        @click="expertMaskChange"
      />
      <div class="expert_label">
        <div class="tips">
          提示词类别筛选
          <span>可同时选择多个类目，并用交集或并集进行筛选</span>
          <el-switch
            v-model="labelSwitchval"
            class="ml-2"
            inline-prompt
            style="
              --el-switch-on-color: #ff7900;
              --el-switch-off-color: #ff7900;
              margin-right: 10px;
            "
            active-text="单选"
            inactive-text="多选"
            @change="changeSwitchVal"
          />
          <el-switch
            v-model="labelSwitch"
            :disabled="labelSwitchval"
            class="ml-2"
            inline-prompt
            :style="{
              '--el-switch-on-color': labelSwitchval ? '#ccc' : '#ff7900',
              '--el-switch-off-color': labelSwitchval ? '#ccc' : '#ff7900'
            }"
            active-text="或"
            inactive-text="和"
            @change="changeSwitch"
          />
        </div>
        <Label />
      </div>
      <div class="expert_swiper">
        <Swiper
          @startTalk="startTalk"
          :swiperLists="swiperLists"
          v-loading="loading"
          @collectSuccess="collectSuccess"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Swiper from '@/components/Swiper'
import Label from '@/components/Label'
import { computed, onMounted, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const initData = reactive({
  swiperLists: [],
  swiperHeight: 0,
  loading: false
})
const { swiperLists, swiperHeight, loading } = toRefs(initData)
// eslint-disable-next-line no-undef
const emit = defineEmits(['handleExpert'])

const labelSwitch = computed(() => store.state.label.labelSwitch)
const labelSwitchval = computed(() => store.state.label.labelSwitchval)
const insertBtn = computed(() => store.state.common.insertBtn)
const chatList = computed(() => store.state.chat.chatList)
const chatListCurrent = computed(() => store.state.chat.chatListCurrent)
const chatKey = computed(() => store.state.chat.chatKey)

onMounted(async () => {
  await store.dispatch('label/getLabelData')
  swiperHeights()
})

const swiperHeights = () => {
  swiperHeight.value =
    document.getElementsByClassName('expert_main')[0].clientHeight -
    document.getElementsByClassName('expert_label')[0].clientHeight
}
// 收藏后更新数据
const collectSuccess = () => {
  store.dispatch('swiper/getSwiperLists')
}

//点击切换类别
const changeSwitch = val => {
  store.commit('label/setLabelSwitch', val)
  store.dispatch('swiper/getSwiperLists')
}

//点击多选切换
const changeSwitchVal = val => {
  store.commit('label/setLabelSwitchval', val)
}

// 开始会话
const startTalk = item => {
  for (const key1 in chatList.value) {
    if (chatList.value.hasOwnProperty.call(chatList.value, key1)) {
      const element = chatList.value[key1]
      for (const key in element) {
        if (element.hasOwnProperty.call(element, key)) {
          const item = element[key]
          item.dialogueShow = false
        }
      }
    }
  }
  if (insertBtn.value && JSON.stringify(chatList.value) != '{}') {
    store.commit('chat/setChatObj', item)
    store.commit('common/setExpertMask', false)
    chatList.value[chatKey.value][chatListCurrent.value].dialogueShow = true
    store.commit('chat/setChatList', chatList.value)
  } else {
    emit('handleExpert', item)
  }
}

// 关闭弹窗
const expertMaskChange = () => {
  store.commit('common/setExpertMask', false)
}
</script>
<style lang="scss" scoped>
$height: 100px;
.expert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .expert_main {
    width: 1108px;
    height: 680px;
    background: #454253;
    box-shadow: 0px 0px 20px 5px rgba(14, 38, 100, 0.22);
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
    .expert_label {
      width: 100%;
      height: 120px;
      padding: 31px 54px 0 54px;
      box-sizing: border-box;
      position: relative;
      z-index: 99;
      .tips {
        font-size: 28px;
        color: #fff;
        // margin-bottom: 19px;
        display: flex;
        align-items: center;
        span {
          font-size: 16px;
          margin: 0 10px;
          color: #bababa;
        }
      }
    }
    .expert_swiper {
      width: 100%;
      height: 560px;
      padding: 32px 54px 0 54px;
      box-sizing: border-box;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 33px;
    right: 52px;
    cursor: pointer;
    z-index: 999;
  }
}
</style>
