<template>
  <div class="agreement">
    <h6>用户协议及免责声明</h6>
    <p>在使用本产品及服务(以下简称“本产品及服务")之前，您(以下简称“您'或用户)应当阅读并遵守《用户协议》(以下简称“ 本协议"),请您务必仔细阅读，充分理解各条款内容，特别是免除或者限制责任的条款。</p>
    <p class="strong">当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他本产品允许的方式实际使用本产品及服务时，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得我们对您问询的解答等理由，主张本协议无效，或要求撤销本协议。</p>
    <p class="strong">请您注意，我们有权根据有关法律法规的变化、公司经营状况、经营策略调整、产品和服务升级等原因修订本协议，且无需另行通知用户。更新后的协议条款公布后即有效代替原来的协议条款，您可随时使用本官方网站查阅最新版协议条款。如果您不同意协议改动内容，请您立即停止使用本软件及服务。如果您继续使用本产品和服务，即视为您已充分理解并同意我们对用户协议的更新修改。</p>
    <p>如果您未满18周岁，请在法定监护人的陪同下阅读本服务条款。并在征得您的监护人同意的前提下使用我们的产品和服务或向我们提供信息。</p>
    <p>一、总则</p>
    <p>1.1.使用本产品及服务时请您确保您所输入的内容未侵害他人权益，未涉及不良信息及情色内容，同时未输入与政治相关的内容，且所有输入内容均合法合规。</p>
    <p class="strong">1.2 若平台发现您故意输入1.1中所含的内容，引导AI生成不良内容并进行传播造谣，对公司造成影响，我们有权对您的账号进行永久封禁并追究相关法律责任。</p>
    <p class="strong">1.3您确认并知悉当前体验服务生成的所有内容均由人工智能模型生成，本公司对其生成内容的准确性、完整性和功能性不做任何保证，并且其生成的内容均不代表我们的态度或观点。AI模型发出的信息是由计算机生成，可能是虚构的，也请您自行辨别是非。您在使用本应用咨询医疗、法律等相关问题时，本应用仅提供医疗、法律等建议，不代表医疗服务、法律服务或治疗等。您在使用本应用前必须咨询医生和相关法律等专业人士，对医疗和法律等决定负最终责任。本应用的信息及服务应仅供参考，不构成医疗建议、法律建议和治疗方式等，我们不承担由使用本应用而产生的任何责任。</p>
    <p>1.4我们的服务来自于法律法规允许的范围，包括但不限于公开互联网等信息积累，并已经过不断的自动及人工敏感数据过滤，但仍不排除其中部分信息具有瑕疵、不合理或引发不快的问题存在。如遇相关情形，欢迎您随时通过本平台反馈建议功能或人工客服向我们进行反馈，我们将对您的意见进行高度重视并采取及时有效的处理措施，同时对您表示十分感谢。</p>
    <p>1.5您在同意接受本协议之前，具体的使用规范请严格按照《用户协议》的内容进行输入。</p>
    <p class="strong">1.6无论您是否已实际阅读本协议，当您通过网络页面点击确认同意本协议或实际使用本平台（“我方”）提供的本产品及服务时，均表示您与我方已就本协议达成一致并同意接受本协议的全部约定内容。如果您不同意本协议的任意内容，或者无法准确理解我方对本协议条款的解释，请立即停止使用本产品及服务。否则即表示您已接受本协议所述的所有条款及其适用条件，同意受本协议约束。</p>
    <p>1.7我们尊重并保护您及所有使用本产品及服务用户的个人隐私，在此提醒您：请您确认使用本产品及服务时输入的内容将不包含您本人及他人的个人信息。</p>
    <p>二、协议内容和效力</p>
    <p>2.1本协议内容包括本协议正文及所有本产品已经发布或将来可能发布的隐私权政策、各项政策、规则声明、通知、警示、提示、说明(以下简称“规则”)。前述规则为本协议不可分割的组成部分，与本协议具有同等法律效力。</p>
    <p>三、帐号注册及使用</p>
    <p>3.1您确认，在您完成注册程序或以其他本产品允许的方式实际使用服务时，您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且我公司有权注销(永久冻结)您的账户，并向您及您的监护人索偿。如您代表一家企业 .组织机构或其他法律主体进行注册或以其本公司允许的方式实际使用本服务，则您声明和保证，您已经获得充分授权并有权代表该公司、组织机构或法律主体注册使用本产品，并受本协议约束。</p>
    <p class="strong">3.2您可以使用您提供或确认的微信号或手机号或者本产品允许的其它方式作为帐号进行注册，您注册后可提供简单的能够反映您身份的信息，同时系统将为您自动分配一个帐号,此帐号是您帐号的唯一识别标记，注册后无法修改。您理解并同意，您承诺注册的帐号名称、头像和昵称等信息中不得出现违法和不良信息，不得冒充他人，不得未经许可为他人注册，不得以可能导致其他用户误认的方式注册帐号或设置帐号，不得使用可能侵犯他人权益的用户名(包括但不限于涉嫌商标权、名誉权侵权等)，否则本公司有权不予注册或停止服务并收回帐号，因此产生的损失由您自行承担。</p>
    <p class="strong">3.3您了解并同意,注册帐号所有权归属于本公司，注册完成后,您仅获得帐号使用权。帐号使用权仅归属于初始申请注册人，不得以任何方式转让或被提供予他人使用，否则，我公司有权立即不经通知收回该帐号，由此带来的因您使用本服务产生的全部数据、信息等被清空、丢失等的损失，您应自行承担。</p>
    <p>3.4在您成功注册后，本产品将根据您的身份要素识别您的身份和授权登录。“身份要素"包括但不限于您的账户名称、密码、短信校验码、手机号码、微信号码。您同意基于不同的终端以及您的使用习惯本产品可能采取不同的验证措施识别您的身份。例如您的账户在新设备首次登录时，我们可能通过密码加短信校验码的方式确认您的身份。请您务必妥善保管您的终端及账户、密码/身份要素信息，并对利用该账户和密码/身份要素信息所进行的一切活动(包括但不限于网上点击同意或提交各类规则协议或购买服务、分享资讯或图片、电话回拨等)负全部责任。您承诺，在密码或账户遭到未获授权的使用，或者发生其他任何安全问题时，将立即通知我们，且您同意并确认，除法律另有规定外，我公司不对上述情形产生的任何直接或间接的遗失或损害承担责任。除非有法律规定或司法裁定，且征得我公司的同意，否则，您的账户、密码不得以任何方式转让、赠予或继承(与账户相关的财产权益除外)。</p>
    <p class="strong">3.5您了解并同意，您不得通过以下任何方式为自已或他人开通本产品:</p>
    <p class="strong">3.5.1以营利、经营等非个人使用的目的为自己或他人开通本产品;</p>
    <p class="strong">3.5.2通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通本产品;</p>
    <p class="strong">3.5.3通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本产品;</p>
    <p class="strong">3.5.4通过非正常渠道指定的方式为自己或他人开通本产品; .</p>
    <p class="strong">3.5.5通过侵犯或他人合法权益的方式为自己或他人开通本产品;</p>
    <p class="strong">3.5.6通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本产品。</p>
    <p class="strong">3.6您了解并同意，如您注册帐号后连续超过12个月未登录，我们为优化管理等目的，有权回收该帐号。</p>
    <p class="strong">3.7如您注销本产品账户或停止使用服务时，本公司有权自行对帐号相关内容及信息以包括但不限于删除等方式进行处理，且无需就此向用户承担任何责任。</p>
    <p class="strong">3.8您应维持密码及帐号的机密安全，不得将帐号转让、出借、出租或售卖。如果用户未保管好自己的帐号和密码而对用户、本公司或第三方造成伤害，用户将负全部责任。用户同意若发现任何非法适用用户帐号或安全漏洞的情况，有义务立即通知本公司。</p>
    <p class="strong">3.9若用户登录、使用帐号头像、个人简介等帐号信息资料存在和不良信息，本公司有权采取通知限期改正、暂停使用等措施。对冒用关联机构或社会名人登录、使用、填写帐号名称、头像、个人简介的,本公司有权取消该帐号在智聊Chat上使用，并向政府主管部进行报告。</p>
    <p class="strong">3.10在符合法律法规的规定，或经国家机关要求的前提下，我们有权对您的帐号进行限制或冻结，在该等情况下，您可能无法继续登录或使用您的帐号，如相关规定同时要求或建议我们开展进一步帐号认证工作，请您配合。为配合监管机关、执法机构、司法部门要求，或为处理诉讼案件等与您有关的争议所需，我们可能会向监管机关、执法机构或司法部门提供与您帐号有关的信息，包括但不限于您的注册信息、登录信息、实名认证信息、绑定信息及可以反映您帐号归属、使用情况的其他有关信息。</p>
    <p class="strong">3.11您应当合理注册并使用智聊Chat帐号，不得恶意注册或违规使用智聊Chat帐号（常见违规行为包括但不限于频繁注册、批量注册等可以合理认为是通过机器进行帐号操作的行为、恶意滥用多个帐号、恶意辅助验证/找回帐号、使用智聊Chat帐号进行违法、违反本协议或本公司（及关联公司）其他产品协议/规则的行为或将智聊Chat帐号用于其他不正当用途）。您需要通过智聊Chat官方渠道注册智聊Chat帐号，不得通过其他非官方渠道注册或获取智聊Chat帐号或其登录凭证，不得通过实际控制多个帐号等方式实施违反法律法规、本协议及本公司（及关联公司）其他产品协议的行为，不得利用任何手段绕开、对抗或破坏平台注册、登录及使用规则。我们有权对上述恶意注册、违规使用行为进行独立判断并在合理判断存在上述行为时，单方决定采取处置措施，处置措施包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、禁止登录，禁止换绑，禁止申诉、回收智聊Chat帐号、冻结/封禁智聊Chat帐号、追究法律责任等本公司判断可用以合理处置作弊行为、保护平台及用户帐号安全的措施。如用户有证据证明未采取以上作弊行为，可以通过本协议公示的联系渠道向我们进行申诉。如本公司（及关联公司）其他产品协议对智聊Chat帐号使用进行了具体规定，相关规定将作为本协议的补充同时适用。</p>
    <p>四、使用规则</p>
    <p class="strong">4.1用户在使用本公司的服务时，必须遵守《网络安全法》、《计算机信息网络国际联网安全保护管理办法》、《互联网信息服务管理办法》、《互联网电子公告服务管理规定》、《维护互联网安全的决定》、《互联网新闻信息服务管理规定》、《互联网宗教信息服务管理办法》等中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为:</p>
    <p class="strong">4.1.1用户同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于发布、上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：</p>
    <p class="strong">1）反对宪法所确定的基本原则的；</p>
    <p class="strong">2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p class="strong">3）损害国家荣誉和利益的；</p>
    <p class="strong">4）煽动民族仇恨、民族歧视、破坏民族团结的；</p>
    <p class="strong">5）破坏国家宗教政策，传教, 开展宗教教育培训、发布讲经讲道内容或者转发、链接相关内容, 组织开展宗教活动,以直播或者录播宗教仪式,成立宗教组织、设立宗教院校和宗教活动场所、发展教徒,以宗教名义开展募捐,宣扬邪教和封建迷信的；</p>
    <p class="strong">6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
    <p class="strong">7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
    <p class="strong">8）侮辱或者诽谤他人，侵害他人合法权利的；</p>
    <p class="strong">9）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</p>
    <p class="strong">10）与电鱼、毒鱼、炸鱼等破坏渔业资源和生态环境的捕捞行为相关的违法违规内容（包括但不限于文字、图片、动态、视频等）；</p>
    <p class="strong">11）含有可能涉及侵害国家主权和领土完整、违反国家关于地图绘制、发布相关规定的内容；</p>
    <p class="strong">12）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</p>
    <p class="strong">4.1.2不得为任何非法目的而使用网络服务系统；</p>
    <p class="strong">4.1.3不利用本公司的服务从事以下活动：</p>
    <p class="strong">1) 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</p>
    <p class="strong">2) 未经允许，对计算机信息网络功能进行删除、修改或者增加的；</p>
    <p class="strong">3) 未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</p>
    <p class="strong">4) 故意制作、传播计算机病毒等破坏性程序的；</p>
    <p class="strong">5) 其他危害计算机信息网络安全的行为。</p>
    <p class="strong">4.2用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿本公司与合作公司、关联公司，并使之免受损害。对此，本公司有权视用户的行为性质，采取包括但不限于删除用户信息内容、暂停使用许可、终止服务、限制使用、回收帐号、追究法律责任等措施。</p>
    <p class="strong">4.3.用户不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。</p>
    <p class="strong">4.4 用户须对自己在使用本公司服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在本公司首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予本公司等额的赔偿。</p>
    <p class="strong">4.5.用户在使用本公司服务时遵守以下互联网底线：</p>
    <p class="strong">1）法律法规底线</p>
    <p class="strong">2）社会主义制度底线</p>
    <p class="strong">3）国家利益底线</p>
    <p class="strong">4）公民合法权益底线</p>
    <p class="strong">5）社会公共秩序底线</p>
    <p class="strong">6）道德风尚底线</p>
    <p class="strong">7）信息真实性底线</p>
    <p>其是您在进行帐号解绑、注销等敏感操作时，我们会将您提交的个人身份信息发送至银行、运营商等机构进行验证查询，以核实您身份的真实性。</p>
    <p>4.6当用户使用一键登录功能时，运营商会将用户的微信号相关信息传输给小本公司，用以判断您的微信号是否已注册智聊Chat帐号。如您的微信号已注册智聊Chat帐号，并通过安全验证，则您将无需填写手机号、验证码、帐户名、密码等，可直接完成帐号登录。如您不满足一键登录的要求，或者选取了其他登录方式，您可使用其他登录方式进行帐号登录。</p>
    <p>五.服务内容</p>
    <p>5.1本公司网络服务的具体内容由本公司根据实际情况提供。</p>
    <p>5.2除非本服务协议另有其它明示规定，本公司所推出的新产品、新功能、新服务，均受到本服务协议之规范。</p>
    <p class="strong">5.3鉴于网络服务的特殊性，用户同意本公司有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。本公司不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</p>
    <p class="strong">5.4免责声明：</p>
    <p class="strong">5.4.1平台所包含的由智聊Chat或任何另行明示的第三方所提供的信息资料，无意且并不构成法律意见或替代恰当的法律咨询，不存在律师当事人关系。除非您另行特别指明外，向平台提交的任何材料亦不会作为保密材料对待。</p>
    <p class="strong">5.4.2我们不做任何形式的下列保证：（1）向用户提供的服务是连续的、及时的、准确的；（2）缺陷一定会被及时修复；（3）所提供的服务或服务器不受病毒或黑客侵袭或受其它故障影响；（4）服务会达到您的要求或符合某一特殊目的。</p>
    <p class="strong">5.4.3您确认并知悉当前体验服务生成的所有内容均由人工智能模型生成，本公司对其生成内容的准确性、完整性和功能性不做任何保证，并且其生成的内容均不代表我们的态度或观点。AI模型发出的信息是由计算机生成，可能是虚构的，也请您自行辨别是非。您在使用本应用咨询医疗、法律等相关问题时，本应用仅提供医疗、法律等建议，不代表医疗服务、法律服务或治疗等。您在使用本应用前必须咨询医生和相关法律等专业人士，对医疗和法律等决定负最终责任。本应用的信息及服务应仅供参考，不构成医疗建议、法律建议和治疗方式等，我们不承担由使用本应用而产生的任何责任。</p>
    <p class="strong">5.4.4本平台仅为用户进行AI写作所使用的工具，用户使用本平台的任何服务不应当视为用户与本公司之间就AI生成成果成立任何委托、承揽、加工等民事法律关系。</p>
    <p class="strong">5.4.5.对于用户使用本服务生成的文字，本公司不限制用户的使用方式或范围。但是，用户为其生成的文字负责，应合法、合理使用。在任何情况下，本公司或任何明示的第三方服务提供者或其董事、职员、雇员、分包商、代理商、继承人、受让人、关联方或下属机构，均不就任何因使用该等文字而产生的任何直接、特殊、间接、附带、后果性、惩罚性或其他损失、成本、费用或损害对您或任何其他第三方承担责任。若因用户使用该文字而产生任何违法、破坏公共秩序或侵犯他人合法权益等后果，均由用户自己负责。</p>
    <p class="strong">5.4.6因以下情况造成网络服务中断，本公司无需为此承担任何责任；</p>
    <p class="strong">（1）本公司需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，本公司保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。</p>
    <p class="strong">（2）因台风、地震、洪水、雷电、恐怖袭击或司法行政命令等不可抗力原因；</p>
    <p class="strong">（3）用户的电脑软硬件和通信线路、供电线路出现故障的；</p>
    <p class="strong">（4）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。</p>
    <p class="strong">5.4.7在任何情况下，对于使用或未能使用平台或平台内容而导致的间接性的、特殊的、附带的、惩罚性的、后果性的损失，或者由此造成的利润损失、数据丢失或业务中断等损失，无论以何种形式提起诉讼，即无论基于民法典、侵权法（包括但不限于过失）还是其他任何法律理论，亦无论是否已被告知该等损失的可能性，本公司或任何明示的第三方服务提供者或其董事、职员、 雇员、分包商、代理商、继承人、受让人、关联方或下属机构，均不承担责任。</p>
    <p class="strong">5.4.8本公司有权于任何时间暂时或永久修改或终止本服务（或其任何部分），而无论其通知与否，本公司对用户和任何第三人均无需承担任何责任。</p>
    <p>六、知识产权</p>
    <p>6.1您了解及同意，除非本公司另行声明，本协议项下服务包含的所有产品、技术、软件、程序、数据及其他信息(包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档)的所有知识产权(包括但不限于版权、商标权、专利权、商业秘密等)及相关权利均归本公司或其关联公司或第三方权利人所有。除本公司或第三方权利人明示同意外，您无权复制、传播、转让、许可或提供他人使用上述资源，否则应承担相应的责任。</p>
    <p>6.2您应保证，您不得(并不得允许任何第三人)实施包括但不限于出租、出借、出售、散布、复制、修改、转载、汇编、发表、出版、还原工程、反向汇编、反向编译，或以其它方式发现原始码等的行为。</p>
    <p>6.3本产品服务涉及的Logo、“智聊Chat”等文字图形及其组成，以及本产品其他标识、徽记、产品和服务名称均为本公司及其关联公司在中国和其它国家的商标，用户未经本公司书面授权不得以任何方式展</p>
    <p>示、使用或作其他处理，也不得向他人表明您有权展示、使用、或作其他处理。</p>
    <p>6.4您理解并同意授权本公司在宣传和推广中使用您的名称、商标、标识，但仅限在宣传和推广中使用您的名称、商标、标识，但仅限于表明您属于我们的客户。</p>
    <p>七、服务期限中止与终止</p>
    <p class="strong">7.1本公司提供的产品及服务的有效期限自您开通服务之日起计算。本公司产品或服务属于虚拟产品和服务，因您支付的费用是智聊ChatAI写作产品及服务的销售金额，不具备非预付款或者存款、定金、</p>
    <p class="strong">储蓄卡等法律属性，又因智聊ChatAI写作产品及服务的退订会影响二次销售，故您支付费用的行为即意味着开通并使用本公司的智聊ChatAI写作产品及服务，一经开通后不可转让或退款。</p>
    <p class="strong">7.2鉴于互联网服务的特殊性，本公司有权对服务内容进行变更，也可能会中断、中止或终止全部或者任一部分的服务，包括免费服务和收费服务，并在本产品官网上以公示的形式通知您。您了解并同意，本产品可能定期或不定期地对提供网络服务的平台设备、设施和软硬件进行维护或检修，如因此类情况而造成收费服务在合理时间内中止，本公司无需承担责任。</p>
    <p class="strong">7.3如存在下列违约情形之一,本公司可立即对用户中止或终止服务,并要求用户赔偿损失:</p>
    <p class="strong">1）用户违反第三条之注册义务;</p>
    <p class="strong">2）用户使用收费服务时未按规定支付相应服务费;</p>
    <p class="strong">3）用户违反本协议服务使用规则之规定。</p>
    <p class="strong">7.4您同意本公司得基于其自行之考虑，因任何理由，包含但不限于长时间（超过一年）未使用，或本公司认为您已经违反本服务协议的文字及精神，终止您的密码、帐号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。您同意依本服务协议任何规定提供之本服务，无需进行事先通知即可中断或终止，您承认并同意，本公司可立即关闭或删除您的帐号及您帐号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的帐号及相关信息和文件被关闭或删除，本公司对您或任何第三人均不承担任何责任。</p>
    <p>八、隐私政策</p>
    <p>详见本公司的《隐私协议》</p>
    <p>九、有限责任</p>
    <p>9.1服务将按“现状”和按“可得到”的状态提供。本公司在此明确声明对服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途之类的保证、声明或承诺。</p>
    <p>9.2本公司对服务所涉的技术和信息的有效性、准确性、正确性、可靠性、质量、稳定、完整和及时性均不作承诺和保证。</p>
    <p>9.3不论在何种情况下，本公司均不对由于internet连接故障、电脑、通讯或其他系统的故障、电力故障、罢工、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、不可抗力、战争、政府行为、国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。</p>
    <p>9.4不论是否可以预见，不论是源于何种形式的行为，本公司不对由以下原因造成的任何特别的、直接的、间接的、惩罚性的、突发性的或有因果关系的损害或其他任何损害(包括但不限于利润或利息的损失,营业中止，资料灭失)承担责任。</p>
    <p>9.4.1使用或不能使用服务;</p>
    <p>9.4.2通过服务购买或获取任何产品，样品，数据，信息或进行交易等，或其他可替代上述行为的行为而产生的费用;</p>
    <p>9.4.3未经授权的存取或修改数据或数据的传输;</p>
    <p>9.4.4第三方通过服务所作的陈述或行为;</p>
    <p>9.4.5其它与服务相关事件，包括疏忽等，所造成的损害。</p>
    <p>9.5您充分了解并同意，鉴于互联网体制及环境的特殊性，您在服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途;您在此已充分意识此类风险的存在，并确认此等风险应完全由您自行承担，本公司对此不承担任何责任。</p>
    <p>9.6您了解并同意， 在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯(包括知识产权)及匿名或冒名的信息的风险，基于服务使用规范所述，该等风险应由您自行承担，本公司对此不承担任何责任。</p>
    <p>十、违约责任及赔偿</p>
    <p>10.1本服务协议任何一方违约均须依法承担违约责任。</p>
    <p>10.2您理解，鉴于计算机、互联网的特殊性，下述情况不属于本公司违约:</p>
    <p>10.2.1本公司在进行服务器配置、维护、系统升级时，需要短时间中断服务;</p>
    <p>10.2.2由于Internet上的通路阻塞造成您访问速度下降。</p>
    <p>10.3在任何情况下，本公司均不对任何间接性、后果性、惩戒性、偶然性、特殊性的损害，包括您使用本公司服务而遭受的利润损失承担责任( 即使您已被告知该等损失的可能性)。</p>
    <p>10.4如果本公司发现或收到他人举报您有违反本协议约定的，本公司有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于限制、暂停、终止您使用帐号及服务，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。</p>
    <p>10.5您同意，由于您通过服务上载、传送或分享之信息、使用本服务其他功能、违反本协议、或您侵害他人任何权利因而衍生或导致任何第三人向本公司及其关联公司提出任何索赔或请求，或本公司及其关联公司因此而发生任何损失，您同意将足额进行赔偿(包括但不限于合理律师费)。</p>
    <p>10.6在任何情况下，本公司对本服务协议所承担的违约赔偿责任总额不超过违约服务对应之服务费总额。</p>
    <p>十一、争议解决及其他</p>
    <p>11.1本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理，并以本公司所在地法院管辖。</p>
    <p>11.2本协议之解释与适用，以及与本协议有关的争议，应尽量友好协商解决;协商不成时，则争议各方一致同意将发生的争议提交协商不成时，应依照中华人民共和国法律予以处理，任何一方均可向本公司所在地法院提起诉讼。</p>
    <p>11.3本协议应取代双方此前就本协议任何事项达成的全部口头和书面协议、安排、谅解和通信。</p>
    <p>11.4您理解并同意，本公司有权自主决定经营策略并根据业务调整情况将本协议项下的全部权利义务并转移给其关联公司，转让将以本协议规定的方式通知，用户承诺对此不持有异议。</p>
    <p>十二、其他</p>
    <p>如您对本协议或使用我们的服务相关的事宜有任何问题(包括问题咨询、投诉等)，请通过功能页面的反馈建议入口或人工客服联系，我们会在收到您的意见及建议后尽快向您回复。</p>
    <p class="strong">十三、免责声明</p>
    <p class="strong">互联网是一个开放平台，用户将照片等个人资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识此类风险的存在。用户明确同意其使用本服务所存在的风险将完全由其自己承担；因其使用本服务而产生的一切后果也由其自己承担，我们对用户不承担任何责任。</p>
    <p class="strong">对于用户上传的照片、资料、证件等，已采用相关措施并已尽合理努力进行审核，但不保证其内容的正确性、合法性或可靠性，相关责任由上传上述内容的会员负责。</p>
    <p class="strong">尽管已采取相应的技术保障措施 ，但用户仍有可能收到各类的广告信或其他不以招聘/应聘为目的邮件或其它方式传送的任何内容，本平台不承担责任。</p>
    <p class="strong">对于各种广告信息、链接、资讯等，不保证其内容的正确性、合法性或可靠性，相关责任由广告商承担；用户通过本服务与广告商进行任何形式的通讯或商业往来，或参与促销活动，包含相关商品或服务之付款及交付，以及达成的其它任何相关条款、条件、保证或声明，完全为用户与广告商之间之行为，与本平台无关。用户因前述任何交易或前述广告商而遭受的任何性质的损失或损害，本平台不承担任何责任。</p>
    <p class="strong">本平台不保证其提供的服务一定能满足用户的要求和期望，也不保证服务不会中断，对服务的及时性、安全性、准确性也都不作保证。对于因不可抗力或无法控制的原因造成的网络服务中断或其他缺陷，不承担任何责任。我们不对用户所发布信息的删除或储存失败承担责任。我们有权判断用户的行为是否符合本网站使用协议条款之规定，如果我们认为用户违背了协议条款的规定，我们有终止向其提供服务的权利。</p>
    <p class="strong">本平台保留变更、中断或终止部分网络服务的权利。保留根据实际情况随时调整平台提供的服务种类、形式的权利。本平台不承担因业务调整给用户造成的损失。本平台仅提供相关服务，除此之外与本服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由用户自行负担。</p>
  </div>
</template>

<script setup></script>
<style lang="scss" scoped>
.agreement {
  margin: 0 20px;
  padding-bottom: 20px;
  h6 {
    text-align: center;
    font-size: 20px;
    color: #000;
    margin: 20px 0;
  }
  p {
    color: #333;
    text-indent: 2em;
    line-height: 30px;
    font-size: 14px;
  }
  .strong {
    font-weight: bold;
  }
}
</style>
