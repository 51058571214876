<!-- 备案信息 -->
<template>
  <div class="filings">
    <p>
      <span @click="agreement">用户协议</span>｜
      <span @click="privacy">隐私政策</span>
      Copyright © 2023-2023 北京智言物语科技有限公司 版权所有
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802042445"
        target="_blank"
      >
        京公网安备 11010802042445号
      </a>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
        京ICP备2023010317号-2
      </a>
      公司注册地址:北京市海淀区翠微中里14号楼三层A003
      <a href="https://www.aishort.top/" target="_blank" title="AiShort (ChatGPT Shortcut) - 简单易用的 ChatGPT 快捷指令表，让生产力倍增！标签筛选、关键词搜索和一键复制 Prompts | AiShort(ChatGPT Shortcut)-Tag filtering, keyword search, and one-click copy prompts">
      友情链接:AiShort
      </a>
    </p>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const routerJump = url => {
  // resolve新窗口打开
  const newpage = router.resolve({
    path: url // 跳转的页面路由
  })
  window.open(newpage.href, '_blank') // 打开新的窗口(跳转路径，跳转类型)
}
// 用户协议
const agreement = () => {
  routerJump('agreement')
}
// 隐私政策
const privacy = () => {
  routerJump('privacy')
}
</script>
<style lang="scss" scoped>
.filings {
  position: absolute;
  bottom: 5px;
  left: $width;
  right: 0;
  margin: auto;
  z-index: 11;
  p {
    font-size: 12px;
    color: #bbb;
    text-align: center;
    a {
      color: #bbb;
      text-decoration: none;
      &:hover {
        color: #222;
      }
    }
    span {
      cursor: pointer;
    }
  }
}
</style>
